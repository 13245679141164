import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Alert,
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Checkbox,
	FormControlLabel,
	LinearProgress,
	Skeleton,
	Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Trans } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { createWallet } from "../../store/actions/walletActions";
import { MessageBox } from "../Animation";
import { MuiTelInput } from "mui-tel-input";

import "./SignUp.scss";
import { FormField } from "../Form/FormField";
import { updateError } from "../../store/slices/walletSlice";
import { getImageAsBlob, isEmail, urlToDataUrl } from "../../utils/helpers";
import { useTPKSignup } from "../../hooks/userHooks";
import { manualLogin } from "../../store/slices/authSlice";
import config from "../../configs/config";
import { useGetUsernameByEmailQuery } from "../../service/api/meveoApi";
import { createSignature } from "../../service/crypto";
import Logo from "../Logo";
import { UnikbaseSvg } from "../Logo/Logo";

const LinearBuffer = () => {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
}

const TPKSignUp = (props) => {
	const { login, resetError, isOperator = false } = props;
	const [errors, setErrors] = useState({});
	const [agreement, setAgreement] = useState(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [createTPKUser, { status, step, error: createTPKError }] = useTPKSignup({
		login: login,
		operatorCode: config.TPK_OPERATOR_CODE 
	});

	//prepare tpkData, if searchParams is empty, get from cookie
	const tpkData = useMemo(() => {
		let data = {
			email: searchParams.get('email') || '',
			tokenName: searchParams.get('name') || '', 
			tokenDescription: searchParams.get('description') || '',
			tokenCoverImage: searchParams.get('image') || '', 
			tpkID: searchParams.get('tpk_id') || '',
			tpkToken: searchParams.get('tpk_token') || '',
			value: searchParams.get('value') || '', 
			commercialOfferCode: searchParams.get('commercialOfferCode') || ''
		};
		if (!data.tokenName && data.tokenDescription) {
			data.tokenName = data.tokenDescription.substring(0, 25);
		}
		if ( !data.email ) {
			let cookieData = JSON.parse(localStorage.getItem('-unkb-tpk-data'));
			if ( !!cookieData && !!cookieData.email ) {
				data = {
					...data,
					...cookieData
				}
			}
		} else {
			//Store data into cookie
			localStorage.setItem('-unkb-tpk-data', JSON.stringify(data));
		}
		return data
	}, [searchParams]);
	
	const { data: existingUser, isFetching: checkingExistedUser } = useGetUsernameByEmailQuery(tpkData.email);

	const [formData, setFormData] = useState({
		username: tpkData.email.replace(/(@.*$)/,''),
		password: "",
		confirmPassword: "",
		email: tpkData.email
	});

	const validationRules = {
		username: [[/(?=.{6,})/, t("pages:login.signup_username_invalid")]],
		password: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
		confirmPassword: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
	};

	const validation = () => {
		let errors = {};
		let isValid = true;
		for (let fieldId in formData) {
			errors[fieldId] = validFormField(fieldId);
			isValid = isValid && errors[fieldId].length <= 0;
		}
		setErrors(errors);
		return isValid;
	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		
		if ( !fieldId ) return;

		fieldValue = fieldValue.replace(/\s/g, "");
		let fieldErrors = validFormField(fieldId, fieldValue);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const submitHandle = async (event) => {
		event.preventDefault();
		if (!agreement) return;
		let isValid = validation();
		
		if (!isValid) return;

		// createAccount(formData);
		createTPKUser({
			...formData,
			...tpkData
		});
	};

	const validFormField = (id, value) => {
		let fieldErrors = [];
		
		value = value || formData[id];
		if( id === 'confirmPassword' && value !== formData.password ) {
			fieldErrors.push(t('pages:login.password_confirmation_message'))
		}
		
		if (typeof formData[id] === "undefined") return fieldErrors;
		const rules = validationRules[id];
		if (!rules || rules.length <= 0) return [];


		for (let idx in rules) {
			let rule = rules[idx];
			if (!!rule[1] && !rule[0].test(value)) {
				fieldErrors.push(rule[1]);
			}
		}

		return fieldErrors;
	};

	useEffect(() => {
		return () => resetError();
	}, [resetError])

	useEffect(() => {
		if ( searchParams.get('email') ) {
			navigate('/tpk-signup', { replace: true });
		}
	}, [searchParams, navigate]);

	// Google Analytics
	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = "https://www.googletagmanager.com/gtag/js?id=G-EJ1DYZ0BV5";
		document.head.appendChild(script);

		const script2 = document.createElement('script');
		script2.text = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
	
		gtag('config', 'G-EJ1DYZ0BV5');
		`;
		document.head.appendChild(script2);
	}, []);
	// End Google Analytics

	if( !isEmail(tpkData.email) || !tpkData.tpkID ) {
		return null; 
	}
	const username = tpkData.email.replace(/(@.*$)/, '');
	const isExistUser = !!existingUser && existingUser.status === 'success' && existingUser.result;
	const isPreparingDocument = !!step && step.startsWith('cover-image');

	return (
		<Box
			key="tpk-signup-form"
			component="form"
			noValidate={false}
			autoComplete="off"
			className="flex flex--vertical form__signup form__signup--tpk"
			onSubmit={submitHandle}
			sx={{ p: 2, width: "100%", maxWidth: "600px" }}
		>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 2 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t(isOperator ? "pages:login.signup_operator_user_form_label" : "pages:login.signup_form_label")}
			</Typography>

			<Card 
				sx={{ 
					display: 'flex', 
					backgroundColor: "var(--unikbase-gray)",
					mb: 6
				}}
				className="token__details"
			>
				<CardMedia
					component="div"
					sx={{ 
						width: "150px",
						flexShrink: 0,
						backgroundSize: "contain"
					}}
					image={ tpkData.tokenCoverImage }
					alt={tpkData.tokenName}
					className="token__coverImage"
				/>
				<Box className="token__description" sx={{ display: 'flex', flexDirection: 'column' }}>
					<CardContent sx={{ flex: '1 0 auto' }}>
						<Typography variant="h6" component="h3">{tpkData.tokenName}</Typography>
						<Typography variant="body" component="div">
							{tpkData.tokenDescription}
						</Typography>
					</CardContent>
				</Box>
			</Card>
			{"loading" !== status && !!createTPKError && (
				<MessageBox type="error">
					{createTPKError}
				</MessageBox>
			)}

			<fieldset 
				className={`flex flex--vertical ${isExistUser ? 'form--disabled' : ''}`}
				disabled={status === 'loading' || !!isExistUser || !!isPreparingDocument}
				sx={{
					opacity: status === 'loading' ? 0.6: 1
				}}
			>
				<Box className="form__fields flex flex--vertical" sx={{ mb: 2 }}>
				{!!checkingExistedUser ? (
					<>
						<Skeleton width={"100%"} height={70}></Skeleton>
						<Skeleton sx={{ mt: 3 }} width={"100%"} height={70}></Skeleton>
						<Skeleton width={"100%"} height={70}></Skeleton>
					</>
				) : (
					<>
						{!!isExistUser && (
							<Alert severity="error">
								User exists with same email ( {tpkData.email} ). Please contact support to get more information.
							</Alert>
						)}
						<FormField
							fieldId="username"
							value={formData.username}
							onChange={handleChange}
							label={t("pages:login.label_username")}
							errors={errors.username}
						/>

						{!!isOperator && (
							<FormField
								fieldId="operatorPrivateKey"
								value={formData.operatorPrivateKey}
								onChange={handleChange}
								label={t("pages:login.label_operator_private_key")}
								errors={errors.operatorPrivateKey}
							/>
						)}

						<Typography
							color={!!errors.password && errors.password.length > 0 ? "error" : ""}
							variant="subtitle2"
							sx={{ mb: 2 }}
						>
							<div dangerouslySetInnerHTML={{ __html: t("pages:login.password_field_valid_message") }}></div>
						</Typography>
						<FormField
							type="password"
							fieldId="password"
							value={formData.password}
							onChange={handleChange}
							label={t("pages:login.label_password")}
							errors={errors.password}
							hideErrors={true}
						/>

						<FormField
							type="password"
							fieldId="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
							label={t("pages:login.label_confirm_password")}
							errors={errors.confirmPassword}
						/>

						<FormControlLabel
							value="agre"
							control={
								<Checkbox
									onChange={(event) => {
										setAgreement(event.target.checked);
									}}
								/>
							}
							label={
								<Trans
									i18nKey="pages:login.signup_agreement_label"
									components={{
										privacy: <Link to="/privacy-policy" target="_blank" rel="noreferer" />,
										terms: <Link to="/terms-conditions" target="_blank" rel="noreferer" />,
									}}
								></Trans>
							}
							labelPlacement="end"
						/>
					</>
				)}
				</Box>

				<LoadingButton
					disabled={!agreement || (!!existingUser && existingUser.status === 'success' && existingUser.result)}
					loading={status === 'loading'}
					sx={{ mb: 2, borderRadius: 6 }}
					variant="contained"
					type="submit"
				>
					{t("pages:login.signup_account_button")}
				</LoadingButton>
				<button id="gtm-account-created-button" type="button" style={{ display: 'none' }}></button>
			</fieldset>

			{!!isPreparingDocument && 
				<Box className="creation-loading" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<UnikbaseSvg 
						id="loading-logo"
						className="logo logo--short logo--tpk-loading"
						style={{
							backgroundColor: 'var(--unikbase-red)',
						}}
					/>
					<Box sx={{ width: '100%' }}>
						<LinearProgress 
							color="secondary"
							className="loading-progress"
						/>
					</Box>
				</Box> 
			}
		</Box>
	);
};

export default connect(
	(state) => {
		const walletState = state?.wallet;
		const authState = state?.auth;
		return {
			error: walletState.error,
			logginStatus: authState.status,
		};
	},
	(dispatch) => {
		return {
			createAccount: (formData) => {
				dispatch(createWallet(formData));
			},
			resetError: () => {
				dispatch(updateError({}));
			},
			login: (authentication, password = false) => {
				dispatch(manualLogin({ 
					data: authentication,
					password
				}))
			}
		};
	}
)(TPKSignUp);
