import { createAsyncThunk } from "@reduxjs/toolkit";
import * as XLSX from "xlsx";
import * as zip from "@zip.js/zip.js";

import { default as apiConfigs } from "../../configs/api";
import { ZIP_MIME_TYPES, UPLOAD_DELAY } from "../../configs/constant";

import {
	blobToBase64,
	csvToArray,
	normalize,
	readText,
	readWorkbook,
	delayedFetch,
} from "../../utils/helpers";

const buildTokensFromCsv = (fileContent, keymap) => {
	const { lines, headers } = csvToArray(fileContent);
	const tokens = [];
	for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
		const line = lines[lineNumber];

		const token = {
			uuid: normalize(line[keymap["uuid"]]),
			name: normalize(line[keymap["name"]] || ""),
			markReference: normalize(line[keymap["markReference"]] || ""),
			description: normalize(line[keymap["description"]] || ""),
			value: parseFloat(line[keymap["value"]] || 0), // price
			currency: "EUR", // USD, EUR
			lengthInMeter: parseFloat(line[keymap["lengthInMeter"]] || 0.0),
			widthInMeter: parseFloat(line[keymap["widthInMeter"]] || 0.0),
			depthInMeter: parseFloat(line[keymap["depthInMeter"]] || 0.0),
			weightInKilo: parseFloat(line[keymap["weightInKilo"]] || 0.0),
		};

		// const client = {
		//     email: normalize(email),
		//     phone: normalize(phone),
		//     firstname: normalize(firstname),
		//     lastname: normalize(lastname)
		// };
		const addedDocuments = [];
		// if (documents?.length >= 5) {
		//     for (let index = 0; index < documents.length; index += 5) {
		//         const documentInfo = documents.slice(index, index + 5);
		//         const [path, name, url, mimeType, filename] = documentInfo;
		//         addedDocuments.push({
		//             path: normalize(path),
		//             name: normalize(name),
		//             url: normalize(url),
		//             mimeType: normalize(mimeType),
		//             filename: normalize(filename)
		//         });
		//     }
		// }
		tokens.push({ token, addedDocuments });
	}
	return tokens;
};

const buildTokensFromZip = async (file, keymap) => {
	let tokens = [];
	const zipContents = await new zip.ZipReader(new zip.BlobReader(file)).getEntries();
	if (zipContents && zipContents.length) {
		let csvFile;
		const images = {};
		for (let i = 0; i < zipContents.length; i++) {
			const content = zipContents[i];
			if ("tokens.csv" === content.filename) {
				csvFile = await content.getData(new zip.TextWriter());
			} else {
				const [tokenId, filename] = content.filename.split("/");
				const blob = await content.getData(new zip.BlobWriter());
				const base64Content = await blobToBase64(blob);
				images[tokenId] = { ...(images.tokenId || {}), [filename]: base64Content };
			}
		}
		const tokenDetails = buildTokensFromCsv(csvFile, keymap);
		tokens = tokenDetails.map((tokenDetail) => {
			const { token, addedDocuments } = tokenDetail;
			const { uuid } = token;
			const tokenImages = images[uuid];
			tokenDetail.addedDocuments = addedDocuments.map((document) => ({
				...document,
				base64content: tokenImages[document.filename],
			}));
			return tokenDetail;
		});
	}
	return tokens;
};

const loadFile = async (file, keymap = false) => {
	if (!!file) {
		let fileContent;
		if ("text/csv" === file.type) {
			fileContent = await readText(file);
			return buildTokensFromCsv(fileContent, keymap);
		} else if (ZIP_MIME_TYPES.includes(file.type)) {
			return await buildTokensFromZip(file, keymap);
		} else {
			const workbook = await readWorkbook(file);
			fileContent = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
			return buildTokensFromCsv(fileContent, keymap);
		}
	}
};

export const processFile = createAsyncThunk(
	"token/processFile",
	async (payload, { dispatch, getState, rejectWithValue }) => {
		const { file, keymap } = payload;
		try {
			const fileTokens = await loadFile(file, keymap);
			for (let i = 0; i < fileTokens.length; i++) {
				const tokenDetails = fileTokens[i];
				await dispatch(createToken(tokenDetails));
			}

			const { tokens } = getState()?.token;

			const hasError = Object.keys(tokens).some((key) => {
				return tokens[key].status === "error";
			});
			if (hasError) {
				return rejectWithValue({ file, message: "pages:wallet.token_error" });
			} else {
				return { file };
			}
		} catch (error) {
			console.error("Error encountered while processing file: ", file, error);
			return rejectWithValue({ file, message: error });
		}
	}
);

export const createToken = createAsyncThunk("token/create", async (newToken, { getState, rejectWithValue }) => {
	const auth = getState()?.auth;
	const { token } = newToken;

	if (!auth) {
		return rejectWithValue({ token, message: "Permission denied." });
	}

	const body = JSON.stringify(newToken);
	try {
		const endpointResponse = await delayedFetch(apiConfigs.wallet.token, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${auth.accessToken}`,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body,
			delay: UPLOAD_DELAY,
		});
		const response = await endpointResponse.json();

		if (!response?.status || response?.status !== "success" || !response?.result) {
			return rejectWithValue({ token, message: response?.result });
		}
		return { token };
	} catch ({ message }) {
		return rejectWithValue({ token, message });
	}
});
