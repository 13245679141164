import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { meoveoRestApi } from "../service/api/meveoApi";
import { authenticationChecker } from "./midleware/apiMidleware";
import { cryptoKeyringCheckMidleware, initialKeyringController } from "./midleware/cryptoMidleware";

import authReducer, { logout, resetError } from "./slices/authSlice";
import walletReducer from "./slices/walletSlice";
import cryptoReducer from "./slices/cryptoSlice";
import tokenReducer from "./slices/tokenSlice";
import { crappyApi } from "../service/api/crappyApi";
import { uploadApi } from "../service/api/uploadApi";
import { logoutChecker, userCreated } from "./midleware/authMidleware";
import generalSettingsReducer, { setUserWalletView } from "./slices/generalSettingsSlice";
import { pdfGeneratorApi } from "../service/api/pdfGeneratorApi";
import { useNavigate } from "react-router-dom";

const persistConfig = {
	key: "unikbase-web-app",
	storage: storage,
	whitelist: ["auth", "generalSettings"],
};

const rootReducer = combineReducers({
	auth: authReducer,
	generalSettings: generalSettingsReducer,
	wallet: walletReducer,
	crypto: cryptoReducer,
	token: tokenReducer,
	[meoveoRestApi.reducerPath]: meoveoRestApi.reducer,
	[crappyApi.reducerPath]: crappyApi.reducer,
	[uploadApi.reducerPath]: uploadApi.reducer,
	[pdfGeneratorApi.reducerPath]: pdfGeneratorApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: [
		thunk,
		logoutChecker,
		userCreated,
		pdfGeneratorApi.middleware,
		meoveoRestApi.middleware,
		crappyApi.middleware,
		uploadApi.middleware,
		authenticationChecker,
		cryptoKeyringCheckMidleware,
		initialKeyringController,
	],
});

export const persistor = persistStore(store, null, () => {
	store.dispatch(resetError());
});

export default store;
