import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CardMedia, IconButton, Typography, useMediaQuery } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { meoveoRestApi, useCreateVerifiablePresentationMutation, useUpdateTokenByIdMutation } from '../../../service/api/meveoApi';
import config from "../../../configs/config";
import { signMessage } from "../../../store/actions/walletActions";
import { decryptOperatorKey, signMessage as signMessageWithPrivateKey } from "../../../service/crypto";
import { generateSalts, generateVerifiablePresentation, randomSalt } from "@unikbase/react-token-hash";

import "./ShareToken.scss";
import ShareOptionTable from "./ShareOptionTable";
import { TokenPrivacyProvider } from "../ShareTokenPrivacy/TokenPrivacyContext";
import ShareTokenSubmit from "./ShareTokenSubmit";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { connect } from "react-redux";
import { standPost } from "../../../pages/Standalone/Standalone";
import { MobileScrollbar } from '../../CustomScrollbar';
import { acquireAndClearPassword, requirePassword } from "../../../store/slices/cryptoSlice";
import { useAddDocumentHashWithSmartContract } from "../../../hooks/smartContractHooks";
import api from "../../../configs/api";
import Loading from "../../Loading";
import LoadingModal from "../../LoadingModal";
import ShareTokenHeadInfo from "./ShareTokenHeadInfo";

export const ShareTokenRetainedFields = [
	'uuid',
	'name',
	'lengthInMeter',
	'widthInMeter',
	'depthInMeter',
	'weightInKilo',
	'value',
	'creationDate',
	'tokenMarkReference',
	'mintDate',
	'description',
	'blockChainID',
	'ownerId',
	'ownerWallet',
	'ownerUsername',
	'ownerEmail'
];

const ShareToken = (props: any) => {
	const { token, username, isOperatorUser, password: _password, crypto, wallet, currentUserEmail, signMessage, onConfirm, standalone, variant, toggleRequirePassword, requirePassword, keyringController, acquireAndClearPassword } = props;
	const [_token, setToken] = useState(token);
	const [sharedURL, setSharedURL] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [options, setOptions] = useState<any>({});
	const _options = useRef({});
	const [modified, setModified] = useState(false);
	const [shareKey, setShareKey] = useState(0);
	const [password, setPassword] = useState('');
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState('');
	const theme: any = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [createVerifiablePresentation] = useCreateVerifiablePresentationMutation();
	const [addDocumentHash] = useAddDocumentHashWithSmartContract({ token });
	const [updatePrivacySettings] = useUpdateTokenByIdMutation();

	const [getTokenById] = meoveoRestApi.endpoints.getTokenById.useLazyQuery();

	const handleClose = () => {
		setOpenModal(false);
		setPassword('');
		setSharedURL('');
		setModified(false);
		if (standalone) {
			standPost({ action: 'cancel' });
		}
	};

	useEffect(() => {
		if (requirePassword === false && !!keyringController && !!crypto.password) {
			acquireAndClearPassword(setPassword);
		}
	}, [requirePassword, crypto, keyringController, acquireAndClearPassword])


	const _onConfirm = async (__options: any) => {
		setError('');
		_options.current = __options;
		setOptions(_options);
		if (sharedURL && !modified) {
			setShareKey(new Date().getTime());
			return;
		}
		if (!password) {
			if (_password) {
				setPassword(_password);
			} else {
				toggleRequirePassword();
			}
		} else {
			setTimeout(onShare, 300);
		}
	}

	const getPrivateKey = async () => {
		const operatorPK = wallet?.private?.operatorPK;
		const creationDate = wallet?.operatorCreationDate;
		const partnerCode = wallet?.partnerCode;

		if (operatorPK && creationDate && partnerCode) {
			const privateKey = await decryptOperatorKey(operatorPK, `${partnerCode}unikbase${creationDate}`, wallet.address);
			return privateKey;
		}

		return null;
	}

	const _signMessage = (message: any) => {
		return new Promise<string>(async (resolve) => {
			if (isOperatorUser) {
				const privateKey = await getPrivateKey();
				const signature = signMessageWithPrivateKey(message, privateKey)
				resolve(signature.includes('0x') ? signature : `0x${signature}`);
			} else {
				const result = await signMessage(message, password);
				resolve(`${result.payload}`);
			}
		});
	}

	const onCreateVP = async (token: any, selectedProps: Array<string>, latestVerifiableCredential: any) => {

		const vp = await generateVerifiablePresentation(latestVerifiableCredential, token, selectedProps, `${config.CHAIN_ID}`, token.token.ownerWallet, _signMessage)
		const resp: any = await createVerifiablePresentation({ data: vp });

		if (resp?.data?.status === 'success') {
			setSharedURL(api.token.sharedTokenUrl(resp.data.result))

			onConfirm && onConfirm(options);
			// if (standalone) {
			// 	standPost({ action: 'success', data: options });
			// }
		} else {
			setError(`${resp?.error?.error || resp?.data?.result}`)
		}
	}

	const onShare = async () => {
		try {
			setProcessing(true);
			const options: any = _options.current;
			let selectedProps = Object.keys(options).filter(e => options[e] === true);
			if (selectedProps.includes('token.tokenPrivacy')) {
				selectedProps.push('token.uuid');
				selectedProps.push('token.name');
			}
			const documents = _token.documents.filter((doc: any) => !doc.uuid.includes('('))

			Object.keys(options).forEach((prop: string) => options[prop] = selectedProps.includes(prop));

			// remove fields that are not supposed to be shared
			let token = Object.assign({ ..._token }, { token: { ..._token.token }, documents });
			token.salts = Array.isArray(token.privacySettings?.token?.hashPrefix) ? token.privacySettings.token.hashPrefix : [];
			const fieldsToOmit = Object.keys(token.token).filter((field: string) => !ShareTokenRetainedFields.includes(field));
			fieldsToOmit.forEach((field: string) => delete token.token[field]);

			let needsUpdate = false;
			token.salts = [...token.salts]; // make it mutable
			Object.keys(token.token).forEach((name: string) => {
				if (!token.salts.find((e: any) => e.name === name)) {
					token.salts.push({ name, prefix: randomSalt() });
					needsUpdate = true;
				}
			})
			if (needsUpdate) {
				const tokenSettings = { ...token.privacySettings.token };
				tokenSettings.hashPrefix = token.salts;

				await updatePrivacySettings({
					invalidate: false,
					tokenID: token.token.uuid,
					body: {
						privacySettings: {
							...token.privacySettings,
							token: tokenSettings,
						}
					},
				})
			}

			const { latestVerifiableCredential } = token.token;
			if (latestVerifiableCredential) {
				await onCreateVP(token, selectedProps, latestVerifiableCredential);
			} else {
				await addDocumentHash(token, true)
					.then((res: any) => {
						if (res && typeof res === 'string') {
							if (res.includes('zipHash is the same') || res.startsWith('0x')) {
								// do nothing
							} else {
								setError(res);
								return;
							}
						}
					})

				const { data } = await getTokenById({ id: token.token.uuid, mode: 'default', timestamp: new Date() });
				if (data?.result?.token) {
					const { latestVerifiableCredential } = data?.result?.token;
					if (latestVerifiableCredential) {
						await onCreateVP(token, selectedProps, latestVerifiableCredential);
					} else {
						setError(t('pages:token.no_latest_verifiable_credential'));
					}
				} else {
					setError(t('pages:token.no_latest_verifiable_credential'));
				}
			}
			setProcessing(false);
		} catch (e) {
			console.warn(e)
			setSharedURL('');
			setError(e.message);
			setProcessing(false);
		}
	}

	useEffect(() => {
		if (!!password && _options.current) {
			onShare();
		}
	}, [password]);

	useEffect(() => {
		if (standalone) {
			setOpenModal(true);
		}
		window.addEventListener("message", messageHandler);
		document.addEventListener("message", messageHandler);
	}, [standalone]);

	const messageHandler = (message: any) => {
		try {
			const data = JSON.parse(message?.data);
			if (data.action === 'token.ShareToken') {
				setOpenModal(true);
			} else if (data.action === 'token.GenerateCertificate'
				|| data.action === 'token.EditToken'
				|| data.action === 'token.TransferToken'
				|| data.action === 'token.PrivacySettings') {
				setOpenModal(false);
			}
		} catch (e) {
			console.log(e);
		}
	}

	const getTokenUpdate = async () => {
		const { data } = await getTokenById({ id: token.token.uuid, mode: 'default', openModal });
		const documents = data?.result?.documents || token.documents || [];
		let _token = Object.assign({}, token, { documents: [...documents] }, { privacySettings: data?.result?.privacySettings });
		setToken(_token);
	}

	useEffect(() => {
		if (openModal) {
			getTokenUpdate();
		}
	}, [openModal]);


	const isTransferred = !!currentUserEmail && token?.client && !!token?.client?.email && currentUserEmail !== token?.client?.email;
	if (!!isTransferred) {
		return null;
	}
	return (
		<>
			{!standalone && <Button
				color="secondary"
				variant={variant || "text"}
				onClick={() => setOpenModal(!openModal)}
				aria-label="share"
				title="share token"
			>
				<span className="icon icon__action icon--share2">_</span>
				<Typography component="span" variant="body2">
					{t("common:share")}
				</Typography>
			</Button>
			}
			<Dialog className="token__share dialog" open={openModal} onClose={handleClose} fullScreen={fullScreen}>
				<DialogTitle>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h5" fontWeight="500">{t("pages:token.privacy_settings.sharing_configs")}</Typography>
						<IconButton className="confirm-dialog__close" aria-label="close" onClick={handleClose}>
							<span className="icon icon--close"></span>
						</IconButton>
					</Box>
				</DialogTitle>

				<TokenPrivacyProvider>
					<MobileScrollbar>
						<DialogContent className="token__share--main">
							<ShareTokenHeadInfo token={_token} />
							<ShareOptionTable token={_token} onChange={() => setModified(true)} />
						</DialogContent>
					</MobileScrollbar>
					<DialogActions
						sx={{ m: "0 auto" }}
						className="flex flex--vertical align--center flex--justify-center"
					>
						{!!error && <Typography className="token__share-error">Error: {error}</Typography>}
						{processing
							? <Loading />
							: <ShareTokenSubmit
								key={shareKey}
								token={_token}
								publicShare={true}
								sharedURL={sharedURL}
								onConfirm={_onConfirm}
								onClose={() => {
									handleClose();
								}}
							/>
						}
					</DialogActions>
					{processing && (
						<LoadingModal
							visible={processing}
							// message={t("pages:token.generating_link")}
							onClose={() => null} />
					)}
				</TokenPrivacyProvider>
			</Dialog>
		</>
	);
};
export default connect((state: any) => {
	const wallet = state?.wallet;
	const crypto = state?.crypto;
	return {
		crypto,
		wallet,
		username: wallet?.private?.username,
		walletAddress: wallet?.address,
		currentUserEmail: wallet?.private?.email?.address,
		requirePassword: crypto?.requirePassword,
		keyringController: crypto?.keyringController,
		isOperatorUser: wallet?.isOperatorUser,
	}
},
	(dispatch: any) => ({
		signMessage: (data: any, password: string) => dispatch((signMessage as any)({ data, password })),
		toggleRequirePassword: () => {
			dispatch(requirePassword(null));
		},
		acquireAndClearPassword: (callback: Function) => {
			dispatch(acquireAndClearPassword(callback));
		},
	})
)(ShareToken);
