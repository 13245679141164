// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bar_loader.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-bar{position:relative;width:50px;height:50px;overflow:hidden}.loader-bar-inner{position:absolute;top:50%;left:50%;width:90%;height:90%;transform:translate(-50%, -20%);display:flex;align-items:center;justify-content:center}.loader-bar-inner .icon--loader_bar{width:100%;height:100%;background-size:contain;background-repeat:no-repeat;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}`, "",{"version":3,"sources":["webpack://./src/components/LoaderBar/style.scss"],"names":[],"mappings":"AAAA,YACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAEA,kBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,+BAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,oCACE,UAAA,CACA,WAAA,CAEA,uBAAA,CACA,2BAAA,CACA,wDAAA","sourcesContent":[".loader-bar {\r\n  position: relative;\r\n  width: 50px;\r\n  height: 50px;\r\n  overflow: hidden;\r\n\r\n  &-inner {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    width: 90%;\r\n    height: 90%;\r\n    transform: translate(-50%, -20%);\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    .icon--loader_bar {\r\n      width: 100%;\r\n      height: 100%;\r\n\r\n      background-size: contain;\r\n      background-repeat: no-repeat;\r\n      background-image: url(\"../../assets/images/bar_loader.svg\");\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
