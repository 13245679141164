import { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
	Autocomplete,
	List,
	ListItem,
	Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { 
	DOCUMENT_UPLOAD_FILE_SIZE, 
} from "../../../configs/constant";
import { DragDropUpload } from "../../Form";
import { useUpdateTokenMutation } from "../../../service/api/meveoApi";
import { t } from "i18next";
import { useUploadDocumentsMutation } from "../../../service/api/uploadApi";
import config from "../../../configs/config";
import { toast } from "react-toastify";
import UploadDocumentItem from "./UploadDocumentItem";
import { areFilesEqual } from "../../../utils/helpers";
import { useTokenEdit, useTokenEditDispatch } from "../EditToken/EditTokenContext";
import "./UploadDocuments.scss";


const UploadDocuments = (props) => {
	const { token, documents: tokenDocuments, deferred } = props;
	const [openUploadDialog, toggleUploadDialog] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [error, setError] = useState("");
	const [updateToken, { isLoading: updateTokenStatus, data: updateTokenResponse }] = useUpdateTokenMutation();
	const [uploadFiles, uploadResults] = useUploadDocumentsMutation();
	const [uploadingFile, setUploadingFiles] = useState([]);
	const [isUploadReady, toggleReady] = useState(false);

	const tokenUpdate = useTokenEdit();
	const dispatch = useTokenEditDispatch();
	const removeItemHandle = (fileName) => {
		let updateDocuments = [];
		for (let i = 0; i < documents.length; i++) {
			if (documents[i].name !== fileName) {
				updateDocuments.push(documents[i]);
			}
		}
		setDocuments(updateDocuments);
		setError("");
	};

	const fileChangeHandle = (fileName, updateFile) => {
		if(!openUploadDialog) return;
		const hash = updateFile.hash || updateFile.uuid;
		const exists = tokenUpdate?.addedDocuments?.find((e) => e.uuid === hash || e.hash === hash)
			|| documents.filter((e) => e.hash === hash).length >= 2
			|| tokenDocuments?.find((e) => e.hash === hash);
		if (exists) {
			toast.error(t('pages:token.document_details.file_already_uploaded'));
			return;
		}

		let updateDocuments = documents;
		let index = updateDocuments.findIndex((item) => item.name === fileName);
		!!updateDocuments[index] && (updateDocuments[index] = updateFile);

		setError("");
		toggleReady(( updateDocuments.length > 0 && updateDocuments.filter(item=>!!item.isReady).length === updateDocuments.length ));
		setDocuments(updateDocuments);
	};

	const onClose = () => {
		toggleUploadDialog(false)
	}

	const onNewFiles = (files) => {
		const updateDocuments = [];

		files.forEach((item) => {
			if (item.size / (1024 * 1024) >= DOCUMENT_UPLOAD_FILE_SIZE) {
				setError(`File size must be smaller than ${DOCUMENT_UPLOAD_FILE_SIZE}MB`);
				return;
			}

			// Check duplicate
			let isDuplicate = false;
			if ( documents.length > 0 ) {
				for( let i in documents ) {
					if ( areFilesEqual(documents[i].file, item) ) {
						toast.error(t('pages:token.document_details.file_already_uploaded'));
						isDuplicate = true;
						break;
					}
				}
			}
			if ( isDuplicate ) return;
			
			updateDocuments.push({
				name: item.name,
				type: "",
				file: item,
			});
		});
		setDocuments([...documents, ...updateDocuments]);
	};

	const uploadDocuments = async () => {
		const validFiles = documents
			.map((item) => {
				return !!item.name && (!!item.type || !!item.hash) && !!item.file;
			})
			.reduce((prev, current) => current && prev, true);

		if (!validFiles) {
			setError("Invalid upload document, please re-check the uploaded files");
			return;
		}
		setError("");

		// Start uploading
		let file_key = `file_${new Date().getTime()}_`;
		let fileData = [];
		let files = [];
		for (let i in documents) {
			let currentFileKey = `${file_key}${i}`;
			let item = documents[i];
			fileData.push({
				status: "pending",
				name: item.name,
				filename: item.file.name,
				path: item.type.path,
				key: currentFileKey,
			});
			files.push(item.hash)
		}
		setUploadingFiles(fileData);
		if (deferred) {
			documents.forEach((item => {
				dispatch({
					type: 'add_document',
					data: item,
				})
			}))
			toggleUploadDialog(false);
			toast.success(t('pages:token.document_details.documents_added_save_changes'));
		} else {
			uploadFiles({
				data: { 
					file: files,
					folder: token.uuid
				},
				pushCloud: true
			});
		}
	};

	useEffect(() => {
		const { isLoading , data: uploadResponse } = uploadResults;
		if( uploadingFile.length > 0 && !isLoading && uploadResponse && uploadResponse.status === 'success' ) {
			const requestData = {
				token: token,
				addedDocuments: [],
			};	
			for( let i in documents ) {
				let item = documents[i];
				let s3Result = uploadResponse.result.filter(j => j.hash === item.hash);
				if ( s3Result.length === 0 ) continue;
				let file = s3Result[0].file;
				let s3_src = `${file.storageUrl}/${file.key}`;
				let url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${file.key}`;

				let documentData = {
					documentId: item.hash, // this is the document hash
					path: item.type.path, // should contain the category of the document (coverImage, invoice, 2d-gallery, 3d-gallery, /doc/certificate/, markProofs, others)
					name: item.name,
					filename: file.name,
					mimeType: file.mimetype,
					size: file.size, // file size in bytes
					fileUrl: url, // this should be the imgproxy URL of the file
				};

				requestData.addedDocuments.push(documentData);
			}
			
			if (requestData.addedDocuments.length > 0) {
				requestData.isUploadDocument = true;
				updateToken(requestData);
			}
			setUploadingFiles([]);
		}
	}, [uploadResults, uploadingFile, token, updateToken, documents]);

	useEffect(() => {
		if (openUploadDialog === false) {
			setError("");
			setDocuments([]);
			toggleReady(false);
		}
	}, [openUploadDialog]);

	useEffect(() => {
		if (!updateTokenResponse) return;
		"success" === updateTokenResponse?.status && toggleUploadDialog(false);
		"fail" === updateTokenResponse?.status && setError(updateTokenResponse.result);
	}, [updateTokenResponse]);

	// Clean error
	useEffect(() => () => setError(""), []);

	useEffect(() => {
		if (documents?.length === 0) {
			toggleReady(false);
		}
	}, [documents]);

	return (
		<Box>
			<Button
				className="token__upload_button"
				variant="outlined"
				color="primary"
				onClick={() => toggleUploadDialog(true)}
				size="small"
				sx={{
					width: "100%",
					p: 1,
					mb: 2,
				}}
			>
				<span className="icon icon--add-token" />
				<span>{t('pages:token.document_details.add_document')}</span>
			</Button>
			<Dialog
				onClose={() => toggleUploadDialog(false)}
				open={openUploadDialog}
				className="token__document-upload"
				sx={{
					"& .MuiDialog-paper": {
						width: "600px",
						maxWidth: "100%",
						margin: 0,
					},
				}}
			>
				<DialogTitle>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						{t("pages:token.document_details.add_documents")}
						<IconButton aria-label="close" onClick={onClose}>
							<span className="icon icon--close"></span>
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Box className="upload" sx={{ mb: 2 }}>
						<DragDropUpload buttonText={`Choose files`} onChange={onNewFiles} />
					</Box>
					{documents.length > 0 && (
						<Box mt={2} mb={1}>
							<Typography color={error ? "error" : "primary"} variant="body" fontWeight="500">
								{t("pages:token.document_details.upload_document_title")}
							</Typography>
						</Box>
					)}
					{documents.length > 0 && (
						<List>
							{documents.map((item, idx) => (
								<UploadDocumentItem
									key={`upload-document-${item.name}`}
									file={item}
									onChange={fileChangeHandle}
									onRemove={removeItemHandle}
									disabled={updateTokenStatus || uploadResults.isLoading}
									folder={token.uuid}
								/>
							))}
						</List>
					)}

					{error && (
						<Typography variant="caption" color="error">
							* {error}
						</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Grid container spacing={1} px={2} mb={1}>
						<Grid item xs={6} sm={6}>
							<Button className="w--100" variant="outlined" color="secondary" onClick={onClose}>
								{t('common:cancel')}
							</Button>
						</Grid>
						<Grid item xs={6} sm={6}>
							<LoadingButton
								color="secondary"
								disabled={!isUploadReady}
								loading={updateTokenStatus || uploadResults.isLoading}
								sx={{
									minWidth: "15ch",
									lineHeight: "1em",
									padding: "0.618em 0",
									height: 35,
								}}
								className="flex flex--justify-end w--100"
								variant="contained"
								onClick={uploadDocuments}
							>
								{t('common:continue')}
							</LoadingButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default UploadDocuments;
