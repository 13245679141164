import { useDispatch, useSelector } from "react-redux";
import { meoveoRestApi, useUpdateTPKOpertorMutation, useGetOperatorDetailQuery, useGetOperatorPricingPlanQuery, useGetPartnerCodeQuery, useGetUserOTPStatusQuery, useGetWalletQuery, useUpdateTokenMutation, useUpdateWalletMutation, useUpdateOnboardingSaleMutation } from "../service/api/meveoApi";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTraceUpdate } from "../utils/hooks";
import { t } from "i18next";
import { encrypeSeedPhrase, generateSeedphrase } from "../service/crypto";
import { KeyringController } from "@metamask/eth-keyring-controller";
import { dataURIToBlob, fileToBase64, getImageAsBlob, keccak_256, urlToDataUrl } from "../utils/helpers";
import { fetchAuthToken } from "../store/actions/authActions";
import { useUploadDocumentsMutation } from "../service/api/uploadApi";
import { documentPathTypes } from "../configs/constant";
import config from "../configs/config";
import { canonicalize } from 'json-canonicalize';

function useGetUserWallet() {
	const activeRequests = useRef(0);
	const isLoggedIn = useSelector(state => {
		const auth = state?.auth;
		return !!auth.accessToken 
			&& !!auth.expiredAt 
			&& auth.expiredAt > new Date().getTime()
	})
	const preWalletData = useRef({});

	const { data: partnerResponse, isFetching: partnerFetching, isLoading: partnerLoading, isError: partnerError } = useGetPartnerCodeQuery({}, {
		skip: !isLoggedIn
	});
	const partnerCode = useMemo(() => {
		if ( !!partnerResponse && partnerResponse.status === 'success' ) {
			return partnerResponse.result;
		}
		return false;
	}, [partnerResponse])
	
	const { data: pricingPlan, isFetching: pricingPlanFetching, isLoading: pricingPlanLoading, isError: pricingPlanError } = useGetOperatorPricingPlanQuery(partnerCode, {
		skip: !isLoggedIn || !partnerCode
	})
	// Get operator data
	const { data: operatorResult, isFetching: operatorFetching, isLoading: operatorLoading, isError: operatorError } = useGetOperatorDetailQuery({}, {
		skip: !isLoggedIn || !partnerCode
	});
	// Get Wallet
	const { data: walletResult, isFetching: walletFetching, isLoading: walletLoading, isError: walletError } = useGetWalletQuery( {}, {
		skip: !isLoggedIn,
	});

	const prepareWallet = (origin, isOperatorUser = false) => {
		let oldData = preWalletData.current;
		return  {
			public: origin?.publicInfo || oldData?.public,
			private: {
				phone: {
					number: origin.privateInfo?.phoneNumber?.phoneNumber || oldData?.private?.phone?.number,
					dialCode: origin.privateInfo?.dialCode?.dialCode || oldData?.private?.phone?.dialCode,
					verified: !!oldData?.private?.phone?.verified || !!isOperatorUser || origin.privateInfo?.phoneNumber?.verified,
				},
				email: {
					address: origin.privateInfo?.email?.emailAddress || oldData?.private?.email?.address,
					verified: !!oldData?.private?.email?.verified || !!isOperatorUser || origin.privateInfo?.email?.verified,
				},
				username: origin.privateInfo?.username || oldData?.private?.username,
				seedPhrase: origin.privateInfo?.seedPhrase || oldData?.private?.seedPhrase,
				operatorPK: origin.privateInfo?.operatorPK || oldData?.private?.operatorPK,
			},
			address: origin.address,
		};
	}

	const wallet = useMemo(() => {
		let data = {}
		if (!!walletResult && walletResult.status && walletResult.status.toLowerCase() === 'success' && walletResult.result ) {
			data = prepareWallet(walletResult?.result);
		}
		if ( !!operatorResult && operatorResult.status === 'success' && operatorResult?.result?.wallet ) {
			const operatorWallet = operatorResult?.result?.wallet;
			if ( !data.address && operatorWallet.address !== data.address ) {
				data = prepareWallet(operatorWallet, true);
				data.creationDate = operatorResult?.result?.creationDate || false;
				data.isOperatorUser = true;
			} 
			data.isOperator = true;
		}
		preWalletData.current = data;
		return data
	}, [walletResult, operatorResult])

	return {
		isFetching: !!walletFetching,
		operatorFetching: !!partnerFetching || !!operatorFetching,
		data: {
			wallet: JSON.stringify(wallet),
			pricingPlan: !!pricingPlan && pricingPlan.status === 'success' ? pricingPlan.result : {},
			partnerCode: partnerCode
		}
	}
}

function useGetUserOTP(args) {
	const { type, email, phone } = args;
	const queryLogs = useRef([]);
	const limitAttempTime = 2 * 60 * 60 * 1000; // 2 hours

	const { isFetching: statusFetching, isLoading: statusLoading, data: otpStatus, error: checkStatusError, isError: isCheckStatusError, refetch: reCheckStatus } = useGetUserOTPStatusQuery(
		{
			type: type,
			verifyId: type === 'email' ? email : phone
		},
		{
			skip: !email || !phone
		}
	);

	const [requestOTP, { isFetching: requestOTPFetching, isLoading: requestOTPLoading, data: requestOTPData, isUninitialized: requestIsUninitialized }] = meoveoRestApi.endpoints.requestUserOTP.useLazyQuery();
	const initalState = {
		status: 'idle',
		error: null,
		isFetching: false,
		isTooManyAttempts: false,
		nextAttempt: null,
	}
	const [response, updateResponse] = useState(initalState)
	const reset = () => updateResponse(initalState);


	const sendRequest = useCallback(() => {
		requestOTP({ type, verifyId: type === 'email' ? email : phone });
		queryLogs.current.push({
			time: new Date().getTime(),
			type: type
		});
	}, [requestOTP, email, phone, type]);

	useEffect(() => {
		// Do with 
		if ( !!statusFetching ) {
			updateResponse({
				...response,
				status: 'loading'
			})
		}

		if ( !statusFetching && response.status === 'loading' && requestOTPData && !requestOTPFetching ) {
			const limitAttempTime = 2 * 60 * 60 * 1000; // 2 hours
			const limitResendTime = 60 * 1000; // 60 seconds
			
			let isTooManyAttempts = queryLogs.current.length >= 5;

			const lastQueryTime = queryLogs.current[queryLogs.current.length - 1] ? queryLogs.current[queryLogs.current.length - 1].time: false;
			let nextAttempt = isTooManyAttempts ? (lastQueryTime ? lastQueryTime + limitAttempTime : new Date().getTime() + limitAttempTime) : lastQueryTime + limitResendTime;

			let requestStatus = 'fail';
			let error = null;
			if ( requestOTPData?.status ) {
				requestStatus = requestOTPData.status;	
				if ( requestOTPData.status === 'fail' ) {
					error = new Error(requestOTPData?.result || t("Error while sending OTP"))
				}
			} else if ( requestOTPData === 'success' ){
				requestStatus = 'success';
			} else {
				requestStatus = 'fail';
				switch(requestOTPData) {
					case 'too_many_attempts':
						error = new Error(t("Too many attempts. Please try again later"));
						isTooManyAttempts = true;
						nextAttempt = otpStatus.status === 'success' && otpStatus?.result?.creationDate ? parseInt(otpStatus.result.creationDate) + limitAttempTime : lastQueryTime + limitAttempTime; 
						break;
					case 'too_many_requests':
						error = new Error(t("Too many requests. Please try again later"));
						break;
					case 'retry':
					default:
						error = new Error(t("Error while sending OTP. Please try again later"))
						break;
				}
			}
			
			updateResponse({
				...response,
				status: requestStatus,
				error: error,
				isTooManyAttempts,
				nextAttempt
			})
		}
	}, [requestIsUninitialized, updateResponse, response, statusFetching, requestOTPFetching, requestOTPData, otpStatus]);

	// useEffect AUTOMATE trigger once requestOTP when OTP Status checked
	useEffect(() => {
		if( !statusFetching && email && phone ) {
			sendRequest()	
		}
	}, [statusFetching, email, type, phone, sendRequest])

	return {
		...response,
		resend: () => {
			reset();
			reCheckStatus();	
		} 
	}
}

function useTPKSignup(configs) {
	const [userTPKUpdate, { data: userTPKResponse, isLoading: userCreateLoading,  }]	 = useUpdateTPKOpertorMutation();
	const [uploadFiles, { isLoading: uploadDocumentLoading }] = useUploadDocumentsMutation();
	const [imageLoading, setImageLoading] = useState(false);
	const [storeSaleInformation, { isLoading: storeSaleInformationLoading }] = useUpdateOnboardingSaleMutation();

	const [status, setStatus] = useState('idle');
	const [error, setError] = useState(false);
	const [step, setStep] = useState('idle');
	const [auth, setAuth] = useState(false);
	const [password, setPassword] = useState(false);

	const userPublicInfo = JSON.stringify({
		firstName: "", 
		lastName: "", 
		unit: "METRIC", 
		language: "en", 
		avatarKey: ""
	});
	const operator_code = configs.operatorCode || 'TPK';

	const creatUser = async (data) => {
		setStatus('loading');
		try {
      const seedPhrase = generateSeedphrase();
      const keyringController = new KeyringController({});
      await keyringController.createNewVaultAndRestore(data.password, seedPhrase);
      const accounts = await keyringController.getAccounts();
      const selectedAddress = accounts[0];
			const encryptedSeedPhrase = encrypeSeedPhrase(
				seedPhrase,
				data.password,
				selectedAddress
			)

			let privateInfo = {
				username: data.username, 
				emailAddress: data.email, 
				password: data.password,
				tpkToken: data.tpkToken,
				seedPhrase: encryptedSeedPhrase
			}
			if ( data.phone ) {
				privateInfo.phone = data.phone.trim();
			}

			let userRequestData = {
				walletId: selectedAddress,
				name: data.username,
				privateInfo: JSON.stringify(privateInfo),
				publicInfo: userPublicInfo,
				operatorCode: operator_code,
				commercialOfferCode: data?.commercialOfferCode || ''
			}

			if (data.tokenUUID) {
        userRequestData.tokenId = data.tokenUUID;
        userRequestData.createToken = false;
      } else {
        userRequestData.tokenId =
          data.tpkID && data.tpkID.startsWith(operator_code + '_') ? data.tpkID : `${operator_code}_${data.tpkID}`;
        userRequestData.tokenName = data.tokenName;
        userRequestData.tokenDescription = data.tokenDescription;
				userRequestData.value = data.value;
				userRequestData.createToken = true;
      }

			let hashPrivate = keccak_256(JSON.stringify(privateInfo));
      const signature = await keyringController.signMessage({
        from: selectedAddress,
        data: hashPrivate,
      });
			userRequestData.signature = signature;
			// Send with sign from client
			userRequestData.sign = data.sign; 
			userRequestData.message = data.message; 

			const response = await userTPKUpdate({ 
				data: userRequestData,
				operator_code,
				username: data.username,
				password: data.password
			}).unwrap();

			if ( response?.status === 'success' ) {
					// Do user login to get accessToken after Update User credentials
					const authentication = await fetchAuthToken(data.username, data.password);
					if ( !!authentication.access_token ) {
            // Do upload image
            setAuth(authentication);
            // saveSaleInformation(data, authentication.access_token);

            if (!!data.password) {
              setPassword(data.password);
            }
            updateDocument(data, authentication.access_token, {
              operator_code,
              userRequestData,
            });
					} else {
						setStatus('fail');
					}
				return;
			} else {
				setStatus('fail');
			}
		} catch ($e) {
			setStatus('fail');
		}
	}

	const saveSaleInformation = async (data) => {
		let file = null
		let filename = '';

		if ( data.document && data.document.length > 0 ) {
			// get file extension and name
			filename = data.document[0].name;
			file = await fileToBase64(data.document[0]);
			file = file.split(',')[1];
		}
		
		let payload = {
			uuid: data.tokenUUID,
			email: data.email,
			commercialOfferCode: data.commercialOfferCode,
			paymentId: data.session_id,
			document: file,
			filename
		};

		storeSaleInformation({
			data: payload,
			access_token: auth.access_token
		});
	}

	const updateDocument = async (data, token, args) => {
		setImageLoading(true);
		setStep('cover-image-preparing')
		
		let documentData = {};
		if ( data.tokenCoverImage ) {
			const file = await getImageAsBlob(data.tokenCoverImage);
			setImageLoading(false);
			// const file = dataURIToBlob(imgBase64)
			let file_key = `file_${new Date().getTime()}_`;
			const formData = new FormData();
			formData.append(file_key, file);
			const tokenId = data.tpkID && data.tpkID.startsWith(operator_code + '_')
				? data.tpkID
				: `${operator_code}_${data.tpkID}`;
			try {
				const response = await uploadFiles({ 
					token,
					folder: tokenId,
					data: formData 
				}).unwrap();
				if( response.status === 'success' && !!response?.result?.files ) {
					const item = response.result.files[0];
					let url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${item.file.key}`;
					documentData = {
						documentId: `${item.hash}-${new Date().getTime()}`, // this is the document hash
						fileSize: item.file.size, // file size in bytes
						fileName: item.file.mimetype.startsWith('image') ? file_key + '.png' : item.file.name,
						mimeType: item.file.mimetype,
						fileUrl: url, // this should be the imgproxy URL of the file
					};	
				}
			} catch (e) {
				setStatus('fail');
			}
		}
		let updateData = {
			walletId: args.userRequestData.walletId,
			signature: args.userRequestData.signature,
			tokenId: args.userRequestData.tokenId,
		}
		if ( !!args.userRequestData.createToken && documentData?.documentId) {
			updateData = {
				...updateData,
				...documentData
			}
		}
		const response = await userTPKUpdate({
			data: updateData,
			operator_code: args.operator_code,
			// token,
			is_update: true
		}).unwrap();

		setStep('cover-image-document-update');
	}

	useEffect(() => {
		if( status === 'loading') {
			// Consider cover-image-document-update is laststep, set step to cover-image-document-update will finish process
			if ( (step === 'cover-image-document-update' || step === 'complete') && !!userTPKResponse ) {
				if ( userTPKResponse?.status === 'success' && auth && password ) {
					setStatus('success');
					document.getElementById('gtm-account-created-button').click();
					// Do login
					configs.login(auth, password);
				} else {
					setStatus('fail');
					setStep('done');
				}
			}
		}
	}, [step, status, userTPKResponse, configs, auth, password]);

	const userTPKError = userTPKResponse?.status === 'fail' && typeof userTPKResponse?.result === 'string' ? userTPKResponse.result : '';
	return [
		creatUser,
		{
			status,
			isLoading: imageLoading || uploadDocumentLoading || userCreateLoading,
			error: userTPKError || error,
			step: step
		}
	]
}

export {
	useGetUserWallet,
	useGetUserOTP,
	useTPKSignup
}