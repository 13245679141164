import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useGeneratePdfCertificate } from '../../../utils/hooks';
import Alert from './Components/Alert';
import ButtonReport from './Components/ButtonReport';
import ModalConfirm from './Components/ModalConfirm';
import './GenerateTokenCertificate.scss';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { meoveoRestApi } from '../../../service/api/meveoApi';
import { standPost } from '../../../pages/Standalone/Standalone';
import { getTokenBlockchainExplorerURL } from '../../../utils/helpers';
import { t } from 'i18next';
import { acquireAndClearPassword, requirePassword } from '../../../store/slices/cryptoSlice';
import LoadingModal from '../../LoadingModal';

function GenerateTokenCertificate({ token, currentUserEmail, currentUserWallet, isOperatorUser, standalone = false, variant, requirePassword, toggleRequirePassword, acquireAndClearPassword, keyringController, crypto }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [visible, toggleModal] = useState(false);
  const [_token, setToken] = useState(token);
  const [successMessage, setSuccessMessage] = useState(false);
  const { generatePdfCertificate, isLoading } = useGeneratePdfCertificate(token);
  const [getUsernameByEmail] = meoveoRestApi.endpoints.getUsernameByEmail.useLazyQuery();


  const selectedOptions = React.useRef([]);
  const [generateCertTrigger, setGenerateCertTrigger] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [getTokenById] = meoveoRestApi.endpoints.getTokenById.useLazyQuery();
  const [getOperatorInfor, { isLoading: gettingOperator }] = meoveoRestApi.endpoints.getOperatorDetail.useLazyQuery();

  const getTokenUpdate = async () => {
    const { data } = await getTokenById({ id: token.token.uuid, mode: 'default', visible });
    const _token = Object.assign({}, token, { documents: data?.result?.documents || token.documents }, { privacySettings: data?.result?.privacySettings });
    setToken(_token);
  }

  const _onCloseModal = () => {
    toggleModal(false)
    if (standalone) {
      standPost({ action: 'cancel' });
    }
  }


  useEffect(() => {
    if (generateCertTrigger > 0) {
      handleConfirmGenerate()
    }
  }, [generateCertTrigger]);

  useEffect(() => {
    if (requirePassword === false && (!!keyringController || isOperatorUser) && !!crypto.password && selectedOptions.current?.length > 0) {
      acquireAndClearPassword(async (password) => {
        if (password) setGenerateCertTrigger(new Date().getTime())
      });
    }
  }, [requirePassword, crypto, keyringController, acquireAndClearPassword])

  useEffect(() => {
    if (visible) {
      getTokenUpdate();
    }
  }, [visible]);

  useEffect(() => {
    if (standalone) {
      toggleModal(true);
    }
    window.addEventListener("message", messageHandler);
    document.addEventListener("message", messageHandler);
  }, [standalone]);

  const messageHandler = message => {
    try {
      const data = JSON.parse(message.data);
      if (data.action === 'token.GenerateCertificate') {
        toggleModal(true);
      } else if (data.action === 'token.ShareToken'
        || data.action === 'token.EditToken'
        || data.action === 'token.TransferToken'
        || data.action === 'token.PrivacySettings') {
        toggleModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const isTransferred = useMemo(
    () =>
      !!currentUserEmail &&
      token.client &&
      !!token.client.email &&
      currentUserEmail !== token.client.email,
    [currentUserEmail, token.client]
  );

  const handleConfirmGenerate = useCallback(async (options) => {
    if (options) {
      selectedOptions.current = Object.keys(options).filter(e => options[e] === true);
    }

    if (!keyringController && !isOperatorUser) {
      return toggleRequirePassword();
    }

    setProcessing(true);
    const response = await generatePdfCertificate({ blockchainID: getTokenBlockchainExplorerURL(token.token.uuid) }, selectedOptions.current);
    toggleModal(false);
    setProcessing(false);
    if (!!response.error) {
      toast.error(response.error)
      standPost({ action: 'error', data: response.error });
      return;
    } else if (response.status === 'fail') {
      toast.error(response.result)
      standPost({ action: 'error', data: response.result });
      return;
    }
    toast.success(t('pages:token.certificate_successfully_created'));
    if (standalone) {
      standPost({ action: 'success', data: response.data });
      return;
    }
    // Change tab
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSearchParams({
      ...params,
      activeTab: 'documents'
    })
    console.log(response)
  }, [generatePdfCertificate, searchParams, setSearchParams, currentUserEmail, currentUserWallet, standalone]);

  const handleCloseAlert = useCallback(() => {
    setSuccessMessage(false);
  }, []);

  if (!!isTransferred) {
    return null;
  }

  return (
    <>
      {!standalone && <ButtonReport onClick={() => toggleModal(true)} variant={variant} />}
      <ModalConfirm
        visible={visible}
        onClose={_onCloseModal}
        token={_token}
        onConfirm={handleConfirmGenerate}
        loading={isLoading}
      />
      {processing && (
        <LoadingModal
          visible={processing}
          // message={t("pages:token.generating_certificate")}
          onClose={() => null} />
      )}
      <Alert visible={successMessage} onClose={handleCloseAlert} />
    </>
  );
}

export default connect((state) => {
  const wallet = state?.wallet;
  const crypto = state?.crypto;
  return {
    crypto,
    currentUserWallet: wallet,
    currentUserEmail: wallet?.private?.email?.address,
    requirePassword: crypto?.requirePassword,
    keyringController: crypto?.keyringController,
    isOperatorUser: wallet?.isOperatorUser,
  };
},
  (dispatch) => ({
    toggleRequirePassword: () => {
      dispatch(requirePassword(null));
    },
    acquireAndClearPassword: (callback) => {
      dispatch(acquireAndClearPassword(callback));
    },
  })
)(GenerateTokenCertificate);
