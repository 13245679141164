import React, { useEffect, useState } from "react";
import Logo from "../../components/Logo";
import { default as LoginForm, ForgotPassword, LoginBySeedPhrase } from "../../components/Login";

import { Button, Divider, Link, SwipeableDrawer, Typography } from "@mui/material";

import "./Login.scss";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { default as SignupForm } from "../../components/SignUp";
import PestelSignup from "../../components/SignUp/PestelSignup";
import OnboardingSignup from "../../components/SignUp/OnboardingSignup";
import {persistor} from "../../store/rootReducer";
import { logout } from "../../store/slices/authSlice";

const Login = (props) => {
	const { initializeAuthStore, isLoggedIn, operator = false, form = false } = props;
	const { t } = useTranslation();
	const prevLocation = useLocation();
	const [open, openForm] = useState(form ? form : false);

	useEffect(() => {
		initializeAuthStore();
	}, [])

	if (isLoggedIn) {
		let redirect = prevLocation.state?.from || "/";
		return <Navigate to={redirect} />;
	}

	const toggleDrawer = (newState) => () => {
    initializeAuthStore();
		openForm(!!newState);
	};

	const container = document.getElementById("root");
	return (
		<div className="site login flex flex--vertical flex--align-center">
			<div className="site__header layer--top">
				<div className="container flex align--center">
					<Logo className="logo--short" style={{ height: "100px" }} />
				</div>
			</div>

			<div className="description container align--center">
				<Typography variant="h5">{t("common:welcome", { appName: "Unikbase" })}</Typography>
				<Typography variant="caption">
					{t("common:copyright")} |{" "}
					<a href="https://unikbase.com" target="_blank" rel="noreferrer" title="Unikbase">
						unikbase.com
					</a>
				</Typography>
			</div>

			<section className="login-actions">
				<div className="container align--center flex flex--vertical">
					{!operator && (
						<Button sx={{ mb: 1 }} color="secondary" onClick={() => openForm("signup")} variant="contained">
							{t("pages:login:create_new_account")}
						</Button>
					)}

					{!!operator && (
						<Button
							sx={{ mb: 1 }}
							color="secondary"
							onClick={() => openForm("operator-signup")}
							variant="contained"
						>
							{t("pages:login:create_new_account")}
						</Button>
					)}
					<Divider
						sx={{
							mb: 1,
							"&::before, &::after": {
								borderColor: "var(--unikbase-red)",
							},
						}}
					>
						{t("common:or")}
					</Divider>
					<Button sx={{ mb: 1 }} color="gray" variant="contained" onClick={() => openForm("login")}>
						{t("pages:login:login_account")}
					</Button>

					{!operator && (
						<Button
							sx={{ mb: 1 }}
							color="gray"
							variant="contained"
							onClick={() => openForm("login-by-seedphrase")}
						>
							{t("pages:login:login_account_by_seedphrase")}
						</Button>
					)}
				</div>
			</section>

			<SwipeableDrawer
				container={container}
				anchor="bottom"
				open={!!open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer("login")}
				disableSwipeToOpen={false}
				ModalProps={{ keepMounted: false }}
				className={`login__modal login__modal--${open}`}
				PaperProps={{
					sx: {
						backgroundColor: "transparent",
					},
				}}
			>
				<Box className="container flex flex--vertical flex--align-center">
					<Typography color="#fff" sx={{ mb: 1 }}>
						{"signup" === open
							? t("pages:login:sign_up")
							: "login-by-seedphrase" === open
							? t("pages:login.label_account_login_by_seedphrase")
							: "login" === open
							? t("pages:login:login_with_username_label")
							: ""}
					</Typography>
					<Box
						className="container flex flex--vertical flex--align-center"
						sx={{
							backgroundColor: "#fff",
							p: 2,
							borderRadius: "8px 8px 0 0",
							minHeight: "50vh",
						}}
					>
						{"login" === open ? (
							<>
								<LoginForm />

								{false && ( //temp remove this becuz can't solve issue related to user's password
									<Typography variant="button" align="center" sx={{ mb: 1 }}>
										<Link
											underline="hover"
											onClick={(event) => {
												event.preventDefault();
												openForm("forgot-password");
											}}
										>
											{t("pages:login:forgot_password")}
										</Link>
									</Typography>
								)}
								<Typography variant="subtitle1" align="center">
									{t("pages:login:dont_have_account")}
								</Typography>
								<Link
									underline="hover"
									color="secondary"
									onClick={(event) => {
										event.preventDefault();
										openForm("signup");
									}}
									align="center"
								>
									{t("pages:login:sign_up")}
								</Link>
							</>
						) : "login-by-seedphrase" === open ? (
							<LoginBySeedPhrase />
						) : "forgot-password" === open ? (
							<ForgotPassword />
						) : "tpk-signup" === open ? (
							<OnboardingSignup />
						) : "pestel-signup" === open ? (
							<PestelSignup /> 
						) : (
							<SignupForm isOperator={open === "operator-signup"} />
						)}
					</Box>
				</Box>
			</SwipeableDrawer>
		</div>
	);
};

export default connect((state) => {
	let auth = state.auth;
	return {
		isLoggedIn: !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime(),
	};
}, dispatch => {
	return {
		initializeAuthStore: () => {
			persistor.purge();
			dispatch(logout());
		}
	}
})(Login);
