import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Hidden,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {
	meoveoRestApi,
	useDeleteTokenTransactionByIdMutation,
	useGetTokenByIdQuery,
	useGetTokenTransactionsQuery,
} from "../../service/api/meveoApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { connect } from "react-redux";

import "./ListTransactions.scss";
import Placeholder from "./Placeholder";
import Empty from "./EmptyTransaction";
import Transaction from "./Transaction";
import { MessageBox } from "../Animation";
import { list_sorting } from "../../utils/helpers";
import { TRANSACTION_TYPES } from "../../configs/constant";
import { toast } from "react-toastify";
import CircularSpinner from "../CircularSpinner";

const columns = [
	{
		label: t("$t(common:digital_items)"),
		order: "tokenName",
	},
	{
		label: "Uuid",
		order: "tokenId",
	},
	{
		label: t("common:author"),
		order: "authorUsername",
	},
	{
		label: t("common:date"),
		order: "transactionDate",
	},
	{
		label: t("common:event"),
		order: "type",
	},
	{
		label: "",
	},
];

const DeleteConfirmationDialog = connect((state) => {
	const wallet = state?.wallet;
	return {
		walletAddress: wallet?.address,
	};
})(({ defaultStatus, onClose, walletAddress }) => {
	const [open, toggleDialog] = useState(false);

	const [deleteHistory, { isLoading, data, isError, isSuccess }] = useDeleteTokenTransactionByIdMutation();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClose = () => {
		!!onClose && typeof onClose === "function" && onClose();
		toggleDialog(false);
	};

	const handleRemoveHistory = () => {
		if (typeof defaultStatus !== "undefined" && !!defaultStatus.id) {
			deleteHistory({
				id: defaultStatus.id,
			});
		}
	};
	useEffect(() => {
		toggleDialog(defaultStatus);
	}, [defaultStatus]);

	useEffect(() => {
		if (!!isSuccess && data.status === "success") {
			toggleDialog(false);
		}
	}, [isSuccess, data]);
	return (
		<Dialog
			fullScreen={fullScreen}
			open={!!open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			className="transaction__delete-confirmation"
		>
			<DialogTitle id="responsive-dialog-title">{t("pages:transactions.delete_token_history")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Table className="transactions">
						<TableBody>
							<Transaction
								key={`transaction-${defaultStatus.id}`}
								transaction={defaultStatus}
								userWalletAddress={walletAddress}
								isOpen={true}
								showRemove={false}
								headers={columns}
							/>
						</TableBody>
					</Table>
				</DialogContentText>
				{!isLoading && !!data && data?.status === "fail" && (
					<MessageBox type="error">{data?.result}</MessageBox>
				)}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose}>
					{t("common:cancel")}
				</Button>
				<Button variant="contained" color="secondary" onClick={() => handleRemoveHistory()} autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const ListHeader = ({ sortHandle }) => {
	const [sortDirection, toggleSortDirection] = useState({});
	const [sortProperty, setSortProperty] = useState("");

	const createSortHandler = (property) => (event) => {
		let dir = !!sortDirection[property] && sortDirection[property] === "asc" ? "desc" : "asc";
		setSortProperty(property);
		toggleSortDirection({
			...sortDirection,
			[property]: dir,
		});
		!!sortHandle && typeof sortHandle === "function" && sortHandle(property, dir);
	};
	return (
		<TableHead>
			<TableRow>
				{columns.map((item, idx) => (
					<TableCell key={`token-lists-header-${idx}`} sx={{ p: 0 }}>
						{!!item.order && (
							<TableSortLabel
								active={sortProperty === item.order}
								onClick={createSortHandler(item.order)}
								direction={
									!!sortDirection[item.order] && sortDirection[item.order] === "asc" ? "desc" : "asc"
								}
							>
								{item.label}
							</TableSortLabel>
						)}
						{!item.order && item.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const TransactionsView = (props) => {
	const { data, walletAddress, deleteHandle } = props;
	const [order, updateOrder] = useState(['transactionDate', 1]);
	// const [viewData, updateData] = useState(data);
	const [currentPageIdx, setPage] = useState(1);

	const sortHandle = (order, dir) => {
		
		updateOrder([order, dir === "asc" ? -1 : 1])
	};

	let viewData = JSON.parse(JSON.stringify(data));
	const items_per_page = 20;

	if (viewData && Array.isArray(viewData) && viewData.length > 0) {
		viewData = viewData
								.filter(i => i.type !== TRANSACTION_TYPES.PAYMENT.key && i.type !== TRANSACTION_TYPES.TOP_UP.key)
		viewData.map(i => {
			let transactionType = "";
			if (i.type === "transferFrom") {
				transactionType = t("pages:transactions.type.smart_constract");
			} else {
				transactionType = t(TRANSACTION_TYPES[i.type]?.label || i.type);
			}
			i.type = transactionType;
			return i;
		})
		
		// sort
		viewData.sort(list_sorting(`${order[0]}`, order[1]));
		let start = (currentPageIdx - 1) * items_per_page;
		viewData = viewData.slice(start, start + items_per_page);
	}
	let pages = Math.ceil(data.length / items_per_page);

	return (
		<Box>
			<Box className="table--virtualized-rows">
				<Table stickyHeader className="transactions">
					<ListHeader sortHandle={sortHandle} />
					<TableBody>
						<Placeholder
							className="row--sizer"
							sx={{
								visibility: "hidden",
								position: "absolute!important",
								zIndex: -1,
								width: "100%",
							}}
						/>
						{viewData && Array.isArray(viewData) && viewData.length > 0 &&
							viewData.map((transaction, idx) => (
								<Transaction
									key={`transaction-${transaction.id}`}
									transaction={transaction}
									index={idx}
									userWalletAddress={walletAddress}
									onDelete={deleteHandle}
									headers={columns}
								/>
						))}
					</TableBody>
				</Table>
			</Box>
			{pages > 1 && (
				<Pagination
					sx={{ 
						mt: 2,
						display: 'flex',
						justifyContent: 'flex-end'
					}}
					count={pages}
					page={currentPageIdx}
					onChange={(event, value) => setPage(value)}
				/>
			)}
		</Box>
	);
};
const TransactionsDataTable = connect((state) => {
	const wallet = state?.wallet;
	return {
		walletAddress: wallet?.address,
	};
})((props) => {
	const { walletAddress, filters = {}, onDeleteTransaction = false, onFetching, onFiltered } = props;
	const { data, isFetching, status } = useGetTokenTransactionsQuery(
		{
			filters,
		},
		{
			skip: !walletAddress,
		}
	);
	const transactions = useMemo(() => {
		if (data && data.status === 'success') {
			let transactions = data.result || [];
			if (filters && Object.keys(filters).length > 0) {
				const { type = false, startDate = false, endDate = false } = filters;

				if (type && Object.keys(type).length > 0) {
					const types = Object.keys(type).filter((e) => !!type[e]);
					transactions = transactions.filter((e) => types.includes(e.type));
				}

				if (startDate && endDate) {
					transactions = transactions.filter((e) => {
						const date = new Date();
						date.setTime(e.transactionDate);
						return date >= new Date(startDate) && date <= new Date(endDate);
					});
				}
				onFiltered(transactions);
				return transactions;
			}
		} else if (data?.status === 'fail') {
			toast.error(data.result);
		}
		return data?.result || [];
	}, [data, filters]);

	useEffect(() => {
		onFetching(isFetching)
	}, [isFetching])

	return status === "uninitialized" || !!isFetching ? (
		<>
			<Hidden smUp>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					marginLeft="auto"
					marginRight="auto"
				>
					<CircularSpinner variant="arc" size={88} />
				</Box>
			</Hidden>
			<Hidden smDown>
				<Table className="transactions">
					<ListHeader columns={columns} />
					<TableBody>
						<Placeholder />
						<Placeholder />
						<Placeholder />
						<Placeholder />
					</TableBody>
				</Table>
			</Hidden>
		</>
	) : transactions && Array.isArray(transactions) && transactions.length > 0 ? (
		<TransactionsView
			data={transactions}
			walletAddress={walletAddress}
			deleteHandle={onDeleteTransaction}
			columns={columns}
		/>
	) : (
		<Empty />
	);
});

const ListTransactions = (props) => {
	const { filters, onFetching, onFiltered } = props;
	const [deleteConfirm, toggleDeleteConfirmationDialog] = useState(false);
	const handleDeleteTransaction = (transaction) => {
		toggleDeleteConfirmationDialog(transaction);
	};
	
	return (
		<>
			<TransactionsDataTable onDeleteTransaction={handleDeleteTransaction} filters={filters} onFetching={onFetching} onFiltered={onFiltered} />
			<DeleteConfirmationDialog
				defaultStatus={deleteConfirm}
				onClose={() => toggleDeleteConfirmationDialog(false)}
			></DeleteConfirmationDialog>
		</>
	);
};

export default ListTransactions;
