import { createSlice } from "@reduxjs/toolkit";
import { revealPrivateKey } from "../actions/cryptoAction";

const initialState = {
  status: 'idle',
  error: null,
  keyringController: null,
  requirePassword: false,
  password: null,
}

const cryptoSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setVerifiedPassword: (state, action) => {
      state.password = action.payload;
    },
    acquireAndClearPassword: (state, action) => {
      const password = state.password;
      state.password = null;
      state.requirePassword = false;
      action.payload(password);
    },
    updateKeyringController: (state, action) => {
      if ( action.payload?.controller ) {
        state.keyringController = action.payload.controller;
        state.requirePassword = false;
      } else {
        state.keyringController = null;
        state.requirePassword = true;
			}
			state.currentPassword =  false;
    },
    requirePassword: (state, action) => {
      state.requirePassword = true;
			state.currentPassword =  action.payload || false;
    },
    unsetRequirePassword: (state) => {
      state.requirePassword = false;
    },
    reset: (state) => {
      state.status = 'idle';
      state.keyringController = null;
      state.requirePassword = false;
			state.currentPassword =  false;
    }
  },
  extraReducers: builder => {
		
		builder
			.addCase(revealPrivateKey.pending, (state, action) => {
				state.requirePassword = true;
			})
			.addCase(revealPrivateKey.fulfilled, (state, action) => {
				state.requirePassword = false;
			})
			.addCase(revealPrivateKey.rejected, (state, action) => {
				state.requirePassword = true;
			});
	}
});

export const { updateKeyringController, requirePassword, unsetRequirePassword, setVerifiedPassword, acquireAndClearPassword, reset } = cryptoSlice.actions;
export default cryptoSlice.reducer;