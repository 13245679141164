// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.platform__menu--group .MuiListItemText-root .MuiTypography-root{color:#fff}.platform__menu--group .toggle .MuiAvatar-root{margin-left:15px}.platform__menu--group .toggle::after{content:"";width:24px;height:24px;position:absolute;right:20px;top:0;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}@media screen and (max-width: 560px){.platform__menu--group .toggle::after{top:15px}}.platform__menu--group .expand::after{transform:rotate(180deg)}`, "",{"version":3,"sources":["webpack://./src/layout/Platform/sidebar.scss"],"names":[],"mappings":"AAGM,iEACE,UAAA,CAIF,+CACE,gBAAA,CAEF,sCACE,UAAA,CACA,UAAA,CACA,WAAA,CAEA,iBAAA,CACA,UAAA,CACA,KAAA,CACA,wDAAA,CAEA,qCAVF,sCAWI,QAAA,CAAA,CAKJ,sCACE,wBAAA","sourcesContent":[".platform__menu {\r\n  &--group {\r\n    .MuiListItemText-root {\r\n      .MuiTypography-root {\r\n        color: #fff;\r\n      }\r\n    }\r\n    .toggle {\r\n      .MuiAvatar-root {\r\n        margin-left: 15px;\r\n      }\r\n      &::after {\r\n        content: \"\";\r\n        width: 24px;\r\n        height: 24px;\r\n\r\n        position: absolute;\r\n        right: 20px;\r\n        top: 0;\r\n        background-image: url(../../assets/icons/down.svg);\r\n\r\n        @media screen and (max-width: 560px) {\r\n          top: 15px;\r\n        }\r\n      }\r\n    }\r\n    .expand {\r\n      &::after {\r\n        transform: rotate(180deg);\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
