if (
  !process.env.REACT_APP_BASIC_AUTH ||
  !process.env.REACT_APP_KEYCLOAK_URL ||
  !process.env.REACT_APP_SERVER_ADDRESS ||
  !process.env.REACT_APP_CLIENT_ID ||
  !process.env.REACT_APP_CLIENT_SECRET
) {
  throw new Error('Please provide all required environment variables');
}

const config = {
  BASIC_AUTH: process.env.REACT_APP_BASIC_AUTH,
  JSONRPC_ADDRESS: process.env.REACT_APP_JSONRPC_ADDRESS,
  KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
  REST_ADDRESS: process.env.REACT_APP_REST_ADDRESS,
  SERVER_ADDRESS: process.env.REACT_APP_SERVER_ADDRESS,
  WALLET_ADDRESS: process.env.REACT_APP_WALLET_ADDRESS,
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  CRAPPY_SERVER_ADDRESS: process.env.REACT_APP_CRAPPY_SERVER_ADDRESS,
  IMGPROXY_SERVER_ADDRESS: process.env.REACT_APP_IMGPROXY_SERVER_ADDRESS,
  UPLOAD_SERVER_ADDRESS: process.env.REACT_APP_UPLOAD_SERVER_ADDRESS,
  SCW_REGION_NAME: process.env.REACT_APP_SCW_REGION_NAME,
  SCW_ENDPOINT_URL: process.env.REACT_APP_SCW_ENDPOINT_URL,
  SCW_BUCKET_NAME: process.env.REACT_APP_SCW_BUCKET_NAME,
  BILLING_CONTRACT_ADDRESS: process.env.REACT_APP_BILLING_CONTRACT_ADDRESS,
  NFT_CONTRACT_ADDRESS: process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
  PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	BLOCK_EXPLORE_URL: process.env.REACT_APP_BLOCK_EXPLORE_URL,
	TPK_OPERATOR_CODE: process.env.REACT_APP_TPK_OPERATOR_CODE
};

export default config;