import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TokenDetail, TokenDetailLoading } from "../../components/Token";
import TokenDetailNotFound from "../../components/Token/TokenDetailNotFound";
import { useGetTokenByIdQuery } from "../../service/api/meveoApi";

import "./Token.scss";
import { PlatformLayout as Layout } from "../../layout";
import { connect } from "react-redux";
import UpdateToken from "../../components/Token/UpdateToken";
import TokenSharePublic from "./TokenSharePublic";
import { t } from "i18next";


const TokenDetailPage = connect((state) => {
	const auth = state?.auth;
	return {
		isLoggedIn:
			!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime() ? auth.accessToken : false,
	};
})(({ isLoggedIn }) => {
	const { id, mode = "default" } = useParams();
	const { data, isFetching, isLoading, isError, refetch } = useGetTokenByIdQuery({ id, mode });

	const token = useMemo(() => {
		return data?.result;
	}, [data?.result])
	const [open, toggleUpdate] = useState(false);
	
	useEffect(() => {	
		if ( !isFetching && data && data.status === 'success' && data.result ) {
			toggleUpdate(false);
		}
	}, [isFetching, data]);

	const pageTitle = t("pages:wallet.title");

	if (!!isError || (!!data && data.status !== "success")) {
		return (
			<Layout
				hideSidebar={true}
				pageTitle={pageTitle}
				className="site single token-single"
			>
				<TokenDetailNotFound />
			</Layout>
		);
	}
	return (
		<Layout
			pageTitle={pageTitle}
			className="site single token-single"
		>
			{!!isFetching ? (
				<TokenDetailLoading />
			) : (
				<TokenDetail
					token={token}
					isFetching={isFetching}
					isLoading={isLoading}
					refetchFunc={refetch}
					mode={mode || "default"}
					updateTokenModalHandle={(status) => toggleUpdate(status)}
				/>
			)}
			{!isFetching && !!token && <UpdateToken data={token} open={open} onClose={() => toggleUpdate(false)} />}
		</Layout>
	);
});


const Token = () => {
	const { mode = "default" } = useParams();

	return mode === 'public' ? <TokenSharePublic /> : <TokenDetailPage />
}

export default Token;
