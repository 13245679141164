import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Refresh as RefreshIcon } from '@mui/icons-material';

import ListTransactions from '../../../components/Transactions/ListTransactions';
import TransactionFilter from '../../../components/Transactions/TransactionFilter';

const TransactionsWebApp = (props: any) => {
  const [filters, setFilters] = useState({});

  const handleRefresh = () => {
    return new Promise((resolve) => {
      setFilters(prev => ({ ...prev, refresh: new Date().getTime() }))
      resolve(true)
    })
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 5 }}
    >
      <TransactionFilter onChange={(update: any) => {
        setFilters({
          ...filters,
          ...update
        })
      }}
        standalone={true}
      />
      <PullToRefresh
        pullingContent={(
          <Box display="flex" alignItems="center" justifyContent="center">
            <RefreshIcon />
          </Box>
        )}
        onRefresh={handleRefresh}>
        <ListTransactions filters={filters} />
      </PullToRefresh>
    </Box>
  )
}

export default
  connect(
    (state: any) => {

      return {
        store: state,
      };
    }
  )(TransactionsWebApp);