import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import "./MarkProofVerification.scss";

import ImageCompare from "./ImageCompare";
import { useEffect, useState } from "react";
import {
	MARKPROOF_VERIFICATION_REJECT_REASONS,
	MARKPROOF_VERIFICATION_STATUS,
	documentPathTypes,
} from "../../configs/constant";
import { useNavigate, useParams } from "react-router-dom";
import {
	meoveoRestApi,
	useGetMarkproofsVerificationsQuery,
	useGetTokenByIdQuery,
	useUpdateMarkproofVerificationMutation,
} from "../../service/api/meveoApi";
import { t } from "i18next";
import ImageProxy, { proxySrcReplace } from "../Gallery/ImageProxy";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Singularities from "./Singularities/Singularities";
import { ImageCompareProvider, useImageCompare, useImageCompareDispatch } from "./ImageCompare/ImageCompareContext";
import { MarkproofVerificationProvider, useMarkproofVerificationContext, useMarkproofVerificationDispatch } from "./MarkProofVerificationContext";

const sampleData = require("./sample-api-response.json");

const ConfirmDialog = (props) => {
	const { data, verificationImages, selected, open, onClose } = props;
	const [reason, setReason] = useState("");
	const reasons = t("pages:mark_proof.verification.list_reason", { returnObjects: true });

	const closeHandle = () => {
		onClose();
	};

	const onReject = () => {
		if (typeof open.callback === "function") {
			open.callback(reason);
		}
		onClose();
	};
	const onConfirm = () => {
		if (typeof open.callback === "function") {
			open.callback();
		}
		onClose();
	};
	useEffect(() => {
		return () => setReason("");
	}, []);


	if (!data || !verificationImages || verificationImages.length <= 0) return null;
	return (
		<Dialog className="dialog" open={!!open} onClose={closeHandle}>
			<DialogTitle>{t("pages:mark_proof.verification.confirmation")}</DialogTitle>
			<DialogContent>
				<Typography sx={{ mb: 2 }}>Token ID: {data?.tokenId}</Typography>
				<Box sx={{ mb: 2 }} className="flex flex--justify-around">
					<Box className="flex flex--vertical flex--align-center">
						<ImageProxy url={verificationImages[selected].referenceImage} width="70" height="70" />
						<Typography component="span" variant="caption">
							Origin
						</Typography>
					</Box>
					<Box className="flex flex--vertical flex--align-center">
						<ImageProxy url={verificationImages[selected].verifiedImage} width="70" height="70" />
						<Typography component="span" variant="caption">
							Verified
						</Typography>
					</Box>
				</Box>
				{open.type === "approve" && <Typography>Manual verification is OK</Typography>}
				{open.type === "reject" && (
					<>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								{t("pages:mark_proof.verification.reason")}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={reason}
								label={t("pages:mark_proof.verification.reason")}
								onChange={(event) => setReason(event.target.value)}
							>
								<MenuItem value=""></MenuItem>
								{MARKPROOF_VERIFICATION_REJECT_REASONS.map((item, idx) => (
									<MenuItem key={`reject-verification-reason-${item}`} value={item}>
										{t(`${reasons[item] || item}`)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button size="small" variant="outlined" onClick={onClose}>
					{t("common:cancel")}
				</Button>
				<Button
					disabled={open.type === "reject" && !reason}
					color="secondary"
					size="small"
					variant="contained"
					onClick={() => (open.type === "reject" ? onReject() : onConfirm())}
				>
					{t("common:ok")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const FinishDialog = ({ data }) => {
	const navigate = useNavigate();
	const fails = data.filter((item) => item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO);
	const reasons = t("pages:mark_proof.verification.list_reason", { returnObjects: true });

	return (
		<Dialog className="dialog" open={true} onClose={() => false}>
			<DialogTitle>{t("pages:mark_proof.verification.page_title")}</DialogTitle>
			<DialogContent>
				{fails.length > 0
					? t("pages:mark_proof.verification.fail_message", { reason: reasons[fails[0].reason] })
					: t("pages:mark_proof.verification.done_message")}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => navigate("/mark-proof/verification", { replace: true })}
					variant="contained"
					color="primary"
					size="small"
				>
					{t("pages:mark_proof.verification.go_token_list_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const MarkProofVerificationBody = () => {
	const params = useParams();
	const viewer = useMarkproofVerificationContext();
	const dispatch = useMarkproofVerificationDispatch();

	const [open, toggleConfirmDialog] = useState(false);
	const [update, updateAction] = useUpdateMarkproofVerificationMutation();
	const [verifications, updateVerifications] = useState([]);
	const [isDone, markDone] = useState(false);
	const selectedVerification = params?.uuid || 0;
	const isFullscreen = !!viewer?.fullscreen;

	const { data, isFetching, error } = useGetMarkproofsVerificationsQuery({
		tokenId: params.token,
		markproofPrefix: params.markproofPrefix,
	});

	const { data: token } = useGetTokenByIdQuery(
		{ id: params?.token },
		{
			skip: !params.token,
		}
	);

	const [selected, updatePOI] = useState(0);

	const onApprove = () => {
		toggleConfirmDialog({
			type: "approve",
			callback: async () => {
				// Update current status
				const newData = JSON.parse(JSON.stringify(verifications));
				newData[selected].status = MARKPROOF_VERIFICATION_STATUS.MANUAL_OK;

				let response = await update({
					tokenID: params.token,
					body: {
						tokenId: params.token,
						markproofPrefix: params.markproofPrefix,
						verificationImages: newData,
					},
				}).unwrap();

				if (response.status === "fail") {
					toast.error(response.result);
					return;
				}
				moveNext(newData);
			},
		});
	};

	const onReject = () => {
		toggleConfirmDialog({
			type: "reject",
			callback: async (reason) => {
				const newData = JSON.parse(JSON.stringify(verifications));

				newData.map((item) => {
					item.status = MARKPROOF_VERIFICATION_STATUS.MANUAL_KO;
					item.reason = reason;
					return item;
				});

				let response = await update({
					tokenID: params.token,
					body: {
						tokenId: params.token,
						markproofPrefix: params.markproofPrefix,
						verificationImages: newData,
					},
				}).unwrap();

				if (response.status === "fail") {
					toast.error(response.result);
					return;
				}

				finishVerification(newData);
			},
		});
	};

	const moveNext = (newData) => {
		updateVerifications(newData);
		if (newData.length > selected + 1) {
			updatePOI(selected + 1);
		}
	};

	const finishVerification = (newData) => {
		updateVerifications(newData);
		updatePOI(newData.length - 1);
		markDone(true);
	};

	const toggleFullsreenHandle = () => {
		dispatch({
			type: 'toggle_fullscreen',
			data: {
				fullscreen: !isFullscreen
			}
		})
		setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
	}

	useEffect(() => {
		if (!!data && !!data.result && data.result.length && data.result[0].verificationImages) {
			let verificationImages = data.result[0]?.verificationImages;
			if (selectedVerification) {
				let filter = data.result.filter((item) => item.uuid === selectedVerification);
				if (filter.length > 0) verificationImages = filter[0].verificationImages;
			}
			updateVerifications(verificationImages);
			for (let idx in verificationImages) {
				let item = verificationImages[idx];
				if (
					item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_OK &&
					item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
				) {
					updatePOI(parseInt(idx));
					break;
				}
			}
			markDone(
				!verificationImages ||
					verificationImages.length <= 0 ||
					verificationImages.filter(
						(item) =>
							item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_OK &&
							item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
					).length <= 0
			);
		}
	}, [data, selectedVerification]);

	// Get singularities data from token
	let singularities = false;
	if ( selected === verifications.length - 1 ) {
		if (token && token.status === "success" && token.result && token.result.documents) {
			let markproofs = token.result.documents.filter(
				(item) => item.path === documentPathTypes.MARK_PROOFS && item.name.startsWith(params?.markproofPrefix)
			);

			if ( markproofs.length > 0 ) {
				for(let i in markproofs ) {
					let item = markproofs[i]
					if ( item?.metadata?.singularities ) {
						singularities = item.metadata.singularities;
					}
				}
			}
		}
	}

	return (
		<>
			<Box
				className={`markproof__detail prevent-select ${isFullscreen ? "fullscreen" : ""} ${
					updateAction.isLoading ? "loading" : ""
				}`}
			>
				<Box className="markproof__verificator flex flex--horizontal">
					<Box className="markproof__pois flex flex--vertical">
						{!!verifications && verifications.length > 0 &&
							verifications.map((item, idx) => {
								const isDisabled =
									item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_OK ||
									item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO;

								return (
									<Button
										disabled={isDisabled}
										key={`poi-switch-${idx}`}
										variant="outlined"
										className={`poi poin--status-${item.status.toLowerCase()} ${
											selected === idx && !isDone ? "selected" : ""
										}`}
										onClick={() => updatePOI(idx)}
										color="secondary"
										title={`${isDisabled ? item.status : ""}`}
									>
										<img
											src={proxySrcReplace(
												`${item.referenceImage || "https://placehold.it/70x70"}`,
												"70",
												"70"
											)}
											alt={item.markProofId}
										/>
									</Button>
								);
							})}
					</Box>
					{!!verifications[selected] && (
						<Box className="image-compare__tool">
							<ImageCompareProvider>
								<Box className="image-compare__wrapper">
									<Box className="labels flex flex--horizontal flex--justify-center">
										<Typography component="div" variant="h6">
											{t("pages:mark_proof.verification.reference")}
										</Typography>
										<Typography component="div" variant="h6">
											{t("pages:mark_proof.verification.collector")}
										</Typography>

										<IconButton
											title="Edit in fullscreen mode"
											onClick={toggleFullsreenHandle}
											className="fullscreen-switcher"
										>
											<FontAwesomeIcon
												icon={isFullscreen ? solid("minimize") : solid("maximize")}
											/>
										</IconButton>
									</Box>

									<ImageCompare data={verifications[selected]} />
									<Typography
										className="tool-guide"
										sx={{ color: "#fff", fontStyle: "italic" }}
										variant="caption"
									>
										{t("pages:mark_proof.verification.tool_guide")}
									</Typography>
								</Box>
								{!!singularities && (
									<Singularities
										singularities={singularities}
										sourceImage={verifications[selected].referenceImage}
									/>
								)}
							</ImageCompareProvider>

							<Box className="image-compare__actions flex flex--horizontal">
								<Box>
									<Button
										size={`${isFullscreen ? "large" : "small"}`}
										onClick={() => onApprove()}
										color="success"
										variant={`${isFullscreen ? "contained" : "outlined"}`}
									>
										{t("pages:mark_proof.verification.approve")}
									</Button>
								</Box>
								<Box>
									<Button
										size={`${isFullscreen ? "large" : "small"}`}
										onClick={() => onReject()}
										color="secondary"
										variant={`${isFullscreen ? "contained" : "outlined"}`}
									>
										{t("pages:mark_proof.verification.reject")}
									</Button>
								</Box>
							</Box>
						</Box>
					)}

					<Box className="markproof__pois markproof__pois--verified flex flex--vertical">
						{!!verifications &&
							verifications.map((item, idx) => (
								<Button
									disabled={
										item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_OK ||
										item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
									}
									key={`poi-switch-${idx}`}
									variant="outlined"
									className={`poi poin--status-${item.status.toLowerCase()} ${
										selected === idx ? "selected" : ""
									}`}
									onClick={() => updatePOI(idx)}
									sx={{ opacity: `${item.similarity}%` }}
									color="secondary"
								>
									<img
										src={proxySrcReplace(
											`${item.verifiedImage || "https://placehold.it/70x70"}`,
											"70",
											"70"
										)}
										alt={item.markProofId}
									/>
									{item.similarity && <span className="similarity">{item.similarity}%</span>}
								</Button>
							))}
					</Box>
				</Box>
			</Box>

			<ConfirmDialog
				data={!!data && !!data.result && data.result[0]}
				verificationImages={verifications}
				open={open}
				onClose={() => toggleConfirmDialog(false)}
				selected={selected}
			/>
			{isDone && <FinishDialog data={verifications}></FinishDialog>}
		</>
	);
};

const MarkProofVerification = () => {
	return (
		<MarkproofVerificationProvider>
			<MarkProofVerificationBody />
		</MarkproofVerificationProvider>
	)
}

export default MarkProofVerification;
