import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	TextareaAutosize,
	Select,
	Box,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import DragDropUpload from "./DragDropUpload/DragDropUpload";
import { fileToBase64 } from "../../utils/helpers";
import store from "../../store/rootReducer";

export const validFormField = (id, value, formData) => {
	const origin = JSON.parse(JSON.stringify(formData));
	if (typeof origin[id] === "undefined") return [];
	let rules = origin[id]?.valid;

	if (!rules || rules.length <= 0) return [];

	value = value || origin[id]?.value;

	const fieldErrors = [];

	for (let idx in rules) {
		let rule = rules[idx];
		if (formData[id].type === "file") {
			if ( rule[0] === 'required' && value.length <= 0 ) {
				fieldErrors.push(rule[1]);
				continue;
			}
			if ( !!value.length ) {
				let regex = rule[0].split("|");
				if (regex[0] === "mimetype") {
					for(let idx in value) {
						let file = value[idx];
						if ( !file.type.startsWith(regex[1]) && fieldErrors.indexOf(regex[1]) < 0 ) {
							fieldErrors.push(rule[1]);
						}
					}
				}
			}
		} else {
			try {
				let regex = typeof rule[0] === "string" ? new RegExp(rule[0]) : rule[0];
				if (!!rule[1] && !regex.test(value)) {
					fieldErrors.push(rule[1]);
				}
			} catch (error) {
				console.log(rule, error);
			}
		}
	}

	return fieldErrors;
};
const FileImage = ({ file }) => {
	const [image, setImage] = useState(false);

	useEffect(() => {
		const loadImage = async (blob) => {
			let base64CoverImage = await fileToBase64(blob);
			setImage(base64CoverImage);
		} 

		loadImage(file)
	}, [file])

	return (
		<>
			{!!image &&  <span className="upload_preview"><img src={image} alt="preview" /></span>	}
		</>
	)
}
const PhotoPreview = ({ file, preview}) => {
	if ( file.length <= 0 ) return null;

	return (
		<>
			{file.map((item, idx) => 
				<Box key={`photo__preview--${idx}`} className="flex flex--horizontal flex--align-center preview">
					{preview && <FileImage file={item} />}
					<span className="upload_file_name">{item.name}</span>
				</Box>
			)}
		</>
	)
}


export const FormField = (props) => {
	const {
		value = "",
		defaultValue = "",
		placeholder,
		type,
		fieldId,
		onChange,
		label,
		errors,
		hideErrors,
		className = "",
		fullWidth = true,
		disabled = false,
		prefix = false,
		buttonText = "Choose files",
		multiple = undefined,
		preview = true,
		options = false,
		maxLength = 2000,
		numeric = false,
	} = props;

	const publicInfo = store.getState()?.wallet?.public;
	const locale = publicInfo?.language || 'en';
	const floatingPoint = locale === 'fr' ? ',' : '.';

	const [_value, setValue] = useState(`${value || defaultValue || ''}`);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const _onChange = (event, value, id) => {
		let fieldValue = event.target?.value || value || '';
		if (numeric) {
			fieldValue = fieldValue.replace(/[^\d.,]/g, '');

			const parts = fieldValue.split(floatingPoint)
			if (parts.length > 2) {
				fieldValue = [
					parts.slice(0, -1).join(''),
					parts.slice(-1)
				].join(floatingPoint);
			}
		}
		setValue(fieldValue);
		onChange(event, value, id);
	}

	const _onBlur = (event, value, id) => {
		let fieldValue = event.target?.value || value || '';
		if (numeric) {
			if (fieldValue.length === 0 || fieldValue === '.') {
				fieldValue = '0';
			} else if (fieldValue.startsWith('.')) {
				fieldValue = `0${fieldValue}`;
			} else if (fieldValue.endsWith('.')) {
				fieldValue = `${fieldValue}0`;
			}
			setValue(fieldValue);
			onChange(event, value, id);
		}
	}

	useEffect(() => {
		if (_value === '') {
			setValue(value);
		}
	}, [value]);

	return (
		<FormControl
			error={!!errors && errors.length > 0}
			sx={{ mb: 2 }}
			size="small"
			variant="outlined"
			fullWidth={fullWidth}
			className={className}
		>
			{type === "phone" ? (
				<MuiTelInput
					defaultCountry="FR"
					size="small"
					id={`field-${fieldId}`}
					value={value}
					name={fieldId}
					onChange={(value) => {
						onChange(null, value, fieldId);
					}}
					disabled={disabled}
				/>
			) : type === "select" ? (
				<>
					<InputLabel id={`${fieldId}-label`}>{label}</InputLabel>
					<Select
						labelId={`${fieldId}-label`}
						id={fieldId}
						defaultValue={""}
						label={label}
						disabled={disabled}
					>
						{!!options && options.map((item, idx) => (<MenuItem key={`option-${fieldId}-${idx}`} value={item}>{item}</MenuItem>))}
					</Select>
				</>
			) : type === "file" ? (
				<>
					<InputLabel id={`${fieldId}-label`}>{label}</InputLabel>
					<DragDropUpload
						files={Array.isArray(value) ? value : []}
						buttonText={buttonText}
						onChange={(newFiles) => {
							onChange(null, newFiles, fieldId);
						}}
						disabled={disabled}
						multiple={multiple}
					/>
					<PhotoPreview file={value} preview={preview}/>
				</>
			) : (
				<>
					<InputLabel htmlFor="field-username">{label}</InputLabel>
					<OutlinedInput
						id={`field-${fieldId}`}
						aria-describedby={`field-${fieldId}`}
						type={type === "password" ? (showPassword ? "text" : "password") : type}
						endAdornment={
							type === "password" ? (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
										sx={{ fontSize: "1em" }}
									>
										<FontAwesomeIcon icon={showPassword ? solid("eye-slash") : solid("eye")} />
									</IconButton>
								</InputAdornment>
							) : (
								false
							)
						}
						startAdornment={!!prefix && <InputAdornment position="start">{prefix}</ InputAdornment>}
						label={label ?? fieldId}
						placeholder={placeholder}
						inputProps={{
							"aria-label": fieldId,
							onChange: _onChange,
							onBlur: _onBlur,
							value: _value,
							name: fieldId,
							disabled: disabled,
							maxLength,
						}}
						multiline={type === "multiline"}
						rows={type === "multiline" ? 3 : 1}
						sx={disabled ? {backgroundColor: "#f5f5f5"} : {}}
						disabled={disabled}
					/>
				</>
			)}
			{errors && errors.length > 0 && !hideErrors && <FormHelperText>{errors}</FormHelperText>}
		</FormControl>
	);
};
