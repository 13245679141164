import { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import Spacer from "./Spacer";

import "./ImageCompare.scss";
import { ImageCompareProvider, useImageCompare, useImageCompareDispatch } from "./ImageCompareContext";
import ImageViewer from "./ImageViewer";
import OpacityBar from "./OpacityBar";
import {
	calculateNewTranslate,
	getElementProperty,
	getRotatedDimensions,
	getScaleAndRotationFromTouch,
	rotatePointAroundOrigin,
} from "../../../utils/helpers";
import AttributeBar from "./AttributeBar";

const getScaleAndRotationFromMouseWheel = (event) => {
	let scaleStep = 0.1; // Scale increment/decrement step
	let rotationStep = 10; // Rotation increment/decrement step

	let scaleDelta = 0;
	let rotationDelta = 0;

	if (event.deltaY < 0) {
		// Mousewheel scrolling up
		scaleDelta = scaleStep;
		rotationDelta = rotationStep;
	} else {
		// Mousewheel scrolling down
		scaleDelta = -scaleStep;
		rotationDelta = -rotationStep;
	}

	return {
		scaleDelta: scaleDelta,
		rotationDelta: rotationDelta,
	};
};

const ComparationTool = ({ source }) => {
	const dispatch = useImageCompareDispatch();

	const container = useRef();
	const containerRect = useRef();
	const mouseAction = useRef();
	const mouseActionTime = useRef(false);
	const prevAction = useRef({
		time: 0
	});

	useEffect(() => {
		const updateContainerRect = () => {
			if (!!container.current) {
				containerRect.current = container.current.getBoundingClientRect();
			}
		};
		updateContainerRect();

		const cancelEditting = () => {
			const images = container.current.querySelectorAll(".image-compare__image");
			!!images &&
				images.forEach((item) => !!item.classList.contains("editting") && item.classList.remove("editting"));
			container.current.classList.remove("editting");
		};

		const onEsc = (event) => {
			const keyCode = event.keyCode || event.which;
			if (keyCode === 27) {
				cancelEditting();
			}
		};

		const onDoubleClick = (action) => {
			if (action.element.classList.contains("editting")) {
				action.element.classList.remove("editting");
				container.current.classList.remove("editting");
			} else {
				action.element.classList.add("editting");
				container.current.classList.add("editting");
			}
		};


		const moveElement = (event, move, target) => {
			const { deltaX, deltaY } = move;

			const isPinch = getScaleAndRotationFromTouch(event);

			if (isPinch && (isPinch.scale || isPinch.rotation) && target.transform) {
				const scale = isPinch.scale / target.isPinch.scale;
				const rotation = isPinch.rotation - target.isPinch.rotation;
				dispatch({
					type: "zoom",
					data: {
						key: target.element.classList.contains("image--original")
							? "original"
							: "reference",
						scale: scale * (target.transform.scale || 1),
						rotation: rotation + (target.transform.rotation || 0),
					},
				});
				return;
			}

			if ((event.scale || event.rotation) && target.transform) {
				let _rotate = event.rotation || 0;
				dispatch({
					type: "zoom",
					data: {
						key: target.element.classList.contains("image--original")
							? "original"
							: "reference",
						scale: event.scale * (target.transform.scale || 1),
						rotation: _rotate + (target.transform.rotation || 0),
					},
				});
				return;
			}
			if ((deltaX !== 0 || deltaY !== 0) && !target.isWheel) {
				let scale = target.transform.scale || 1;
				let rotation = target.transform.rotation || 0;
				let realDimension = getRotatedDimensions(target.imageRect, rotation);

				const currentTranslate = {
					x: target.transform.translateX,
					y: target.transform.translateY,
				};

				let _newTranstate = {
					x: currentTranslate.x + ((deltaX * scale) / realDimension.width) * 100,
					y: currentTranslate.y + ((deltaY * scale) / realDimension.height) * 100,
				};

				dispatch({
					type: "move",
					data: {
						key: target.element.classList.contains("image--original")
							? "original"
							: "reference",
						translate: _newTranstate,
					},
				});
				return;
			}
		}

		const onMouseOut = (event) => {
			if ( container.current && container.current.classList.contains('mouseover') ) {
				container.current.classList.remove('mouseover');
			}
		}
		const onMouseMove = (event) => {
			const spacer = container.current.querySelector(".spacer");
			
			if (!mouseAction.current) {
				// add some class to check hover action 
				if (container.current) {
					container.current.classList.add('mouseover')
				}
				return;
			}

			event.preventDefault();
			const clientX = event.clientX || event?.changedTouches[0]?.clientX;
			const clientY = event.clientY || event?.changedTouches[0]?.clientY;

			let deltaX = clientX - mouseAction.current.x;
			let deltaY = clientY - mouseAction.current.y;

			// Update spacer
			if (mouseAction.current.element === spacer) {
				let currentSpacer = mouseAction.current.rect.x - containerRect.current.x;

				let _newPosition = (currentSpacer + deltaX) / containerRect.current.width;
				_newPosition = _newPosition <= 0 ? 0 : _newPosition >= 100 ? 100 : _newPosition;
				dispatch({
					type: "update_spacer",
					data: {
						spacer: {
							left: parseFloat(_newPosition * 100),
						},
					},
				});
				return;
			}

			if (mouseAction.current.element.classList.contains("editting")) {
				moveElement(event, { deltaX, deltaY }, mouseAction.current );
			}
		};

		const onMouseDown = (event) => {
			const opacityBar = container.current.querySelector(".image-compare__opacity-bar");
			const attributeBar = container.current.querySelector(".image-compare__attributes");

			if (
				(!!opacityBar && opacityBar.contains(event.target)) ||
				(!!attributeBar && attributeBar.contains(event.target))
			) {
				return;
			}
			// Start to handle events
			event.preventDefault();

			const clientX = event.clientX || event?.changedTouches[0]?.clientX;
			const clientY = event.clientY || event?.changedTouches[0]?.clientY;

			const currentTime = new Date().getTime();
			const spacer = container.current.querySelector(".spacer");
			let target = event.target;

			if (spacer && target !== spacer) {
				const spacerLeft = spacer.getBoundingClientRect().left;
				const editting = container.current.querySelector(".editting");
				if (!!editting) {
					target = editting;
				} else if (clientX < spacerLeft - 6) {
					target = container.current.querySelector(".image--original");
				} else if (clientX > spacerLeft + 6) {
					target = container.current.querySelector(".image--reference");
				}
			}

			let isDoubleClick = !!prevAction.current && (prevAction.current.target === target) && prevAction.current.time !== 0 && Math.abs(prevAction.current.time - currentTime) <= 500;

			if ( !isDoubleClick ) {
				prevAction.current.time = currentTime;
				prevAction.current.target = target;
			}
			
			let type = target.className.match(/image--([^\s]+)/i);
			if (type) {
				dispatch({
					type: "start_edit",
					data: {
						editting: type[1],
					},
				});
			}

			mouseAction.current = {
				x: clientX,
				y: clientY,
				type: event.button,
				element: target,
				rect: target.getBoundingClientRect(),
				isDoubleClick,
				isPinch: getScaleAndRotationFromTouch(event),
			};
			if (target.classList.contains("image-compare__image")) {
				mouseAction.current.transform = getElementProperty(target.querySelector("img"));
				mouseAction.current.imageRect = target.querySelector("img").getBoundingClientRect();
			}
		};

		const onMouseUp = (event) => {
			if (!!mouseAction.current && mouseAction.current.isDoubleClick) {
				onDoubleClick(mouseAction.current);
			}

			// End action
			mouseAction.current = null;
		};

		const handleWheelStart = (event) => {
			var deltas = getScaleAndRotationFromMouseWheel(event);
			let target = event.target;
			const spacer = container.current.querySelector(".spacer");
			const editting = container.current.querySelector(".editting");
			if ( !editting ) return;

			if (!!editting) {
				target = editting;
			} else if (spacer) {
				const spacerLeft = spacer.getBoundingClientRect().left;
				if (event.clientX < spacerLeft - 6) {
					target = container.current.querySelector(".image--original");
				} else if (event.clientX > spacerLeft + 6) {
					target = container.current.querySelector(".image--reference");
				}
			}


			mouseAction.current = {
				x: event.clientX,
				y: event.clientY,
				element: target,
				rect: target.getBoundingClientRect(),
				isWheel: true,
				deltas: {
					scale: 1,
					rotate: 0,
				},
			};
			if (target.classList.contains("image-compare__image")) {
				mouseAction.current.transform = getElementProperty(target.querySelector("img"));
				mouseAction.current.imageRect = target.querySelector("img").getBoundingClientRect();
			}
		};

		const handleWheelMove = (event) => {
			if ( !mouseAction.current ) {
				return;
			}

			var deltas = getScaleAndRotationFromMouseWheel(event);
			var scaleDelta = deltas.scaleDelta;
			// var rotationDelta = deltas.rotationDelta;
			mouseAction.current.deltas.scale += scaleDelta / 10;
			// Do mouse scale
			event.scale = mouseAction.current.deltas.scale;
			onMouseMove(event);
		};

		const handleWheelEnd = (event) => {
			mouseAction.current = null;
		};

		const onMouseWheel = (event) => {
			event.preventDefault();

			if (!window.isWheeling) {
				handleWheelStart(event);
				window.isWheeling = true;
			}

			handleWheelMove(event);

			clearTimeout(window.wheelTimeout);
			window.wheelTimeout = setTimeout(function () {
				handleWheelEnd(event);
				window.isWheeling = false;
			}, 250); // Adjust the timeout duration as needed
		};

		const element = container.current;

		// Binding Events
		element.addEventListener("mousemove", onMouseMove);
		element.addEventListener("mouseout", onMouseOut);
		element.addEventListener("mousedown", onMouseDown);
		window.document.addEventListener("mouseup", onMouseUp);

		element.addEventListener("touchstart", onMouseDown);
		element.addEventListener("touchmove", onMouseMove);
		window.document.addEventListener("touchend", onMouseUp);

		window.document.addEventListener("gestureend", onMouseUp);
		element.addEventListener("gesturestart", onMouseDown);
		element.addEventListener("gesturechange", onMouseMove);

		window.document.addEventListener("keyup", onEsc);

		element.addEventListener("wheel", onMouseWheel);

		window.addEventListener("resize", updateContainerRect);

		return () => {
			element.removeEventListener("mousemove", onMouseMove);
			element.removeEventListener("mousedown", onMouseDown);
			window.document.removeEventListener("mouseup", onMouseUp);

			element.removeEventListener("touchstart", onMouseDown);
			element.removeEventListener("touchmove", onMouseMove);
			window.document.removeEventListener("touchend", onMouseUp);

			window.document.removeEventListener("gestureend", onMouseMove);
			element.removeEventListener("gesturestart", onMouseDown);
			element.removeEventListener("gesturechange", onMouseMove);

			window.document.removeEventListener("keyup", onEsc);

			element.removeEventListener("wheel", onMouseWheel);

			window.removeEventListener("resize", updateContainerRect);
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch({
			type: "reset",
		});
	}, [source.original.src, dispatch]);

	return (
		<Box ref={container} className="image-compare__viewer">
			{Object.values(source).map((item) => (
				<ImageViewer key={`image-comparation-${item.key}`} source={item} />
			))}
			<Spacer />
			<OpacityBar />
			<AttributeBar editting={!!mouseAction.current && mouseAction.current.editting} />
		</Box>
	);
};

const ImageCompare = ({ data, open, onClose }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClose = () => {
		onClose();
	};

	const config = {
		reference: {
			key: "reference",
			src: data.verifiedImage,
		},
		original: {
			key: "original",
			src: data.referenceImage,
			opacity: true,
			clipPath: true,
		},
	};

	return (
		<ComparationTool source={config} />
	);
};

export default ImageCompare;
