import { Box } from "@mui/material";
import { TokenList } from "../../components/Token";

import "./Wallet.scss";
import { ImportTokens } from "../../components/Wallet";
import { t } from "i18next";
import { PlatformLayout as Layout } from "../../layout";
import AddToken from "../../components/Token/AddToken/AddToken";
import { connect, useSelector } from "react-redux";
import TopupBalance from "../../components/Token/TopupBalance/TopupBalance";
import SendMessage from "../../components/Token/SendMessage/SendMessage";
import { SendMessageDispatchContext, SendMessageProvider } from "../../components/Token/SendMessage/SendMessageContext";

const PartnerActions = connect((state) => {
	const auth = state?.auth;
	const wallet = state?.wallet;
	
	return {
		isLoggedIn:
			!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime() ? auth.accessToken : false,
		partnerCode: wallet.partnerCode
	};
})((props) => {
	const { isLoggedIn = false, partnerCode } = props;
	const isPartner = !!partnerCode;

	if (!isLoggedIn || !isPartner) return null;
	return (
		<>
			<AddToken partnerCode={partnerCode} />
			<ImportTokens />
			<SendMessage />
		</>
	);
});

const Wallet = (props) => {
	
	return (
		<SendMessageProvider>
			<Layout
				className="site wallet"
				pageTitle={t("pages:wallet.title")}
				actions={
					<Box className="flex flex--justify-end">
						<PartnerActions />
					</Box>
				}
			>
				<Box className="wallet__tokens">
					<TokenList />
				</Box>
			</Layout>
		</SendMessageProvider>
	);
};

export default Wallet;
