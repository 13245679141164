import { useEffect, useState } from "react";
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Skeleton, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { revealPrivateKey } from "../../store/actions/cryptoAction";
import { requirePassword, updateKeyringController } from "../../store/slices/cryptoSlice";


const BootstrapTooltip = styled(({ visible, className, ...props }) => (
	visible ?
  	<Tooltip {...props} arrow classes={{ popper: className }} />
		: props.children
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const OperatorKey = ({ keyringController, address, revealPrivateKey, requirePassword, cleanPassword }) => {
	const [showPassword, toggleShowPassword] = useState(false);
	const privateKeyPlaceholder = "**********************************************";
	const [privateKey, setPrivateKey] = useState(privateKeyPlaceholder);

	const handleRevealPrivateKey = () => {
		if (!showPassword) {
			setPrivateKey(privateKeyPlaceholder);	
			cleanPassword();
			revealPrivateKey();
		}
		toggleShowPassword(!showPassword)
	}
	
	useEffect(() => {
		const exportAccount = async () => {
			try {
				let privateKey = await keyringController.exportAccount(address);
				if ( !!privateKey ) {
					setPrivateKey(privateKey);	
					navigator.clipboard.writeText(privateKey);
				}
			} catch(error) {
				console.log(error)
				return false;
			}
		}
		if( requirePassword === false && !!keyringController) {
			exportAccount();
		}

		if(!requirePassword && !keyringController){
			toggleShowPassword(false)
		}

	}, [requirePassword, address, keyringController])


	if (!address ) {
		return (
			<Box>

				<Skeleton variant="text" width="12ch" />
				<Skeleton variant="rectangular" width="100%" height="40px" sx={{ transform: "scale(1)"}} />
			</Box>
		)
	}

	const visible = showPassword && !requirePassword && privateKey;
	return (
		<Box>
			<Typography sx={{ mb: 2, display: "inline-block"}} variant="subtitle" component="label">
				Private Key
				<Typography variant="caption" component="span">&nbsp;({address})</Typography>
				:
			</Typography>
			<FormControl sx={{ width: '100%' }} variant="filled">
				<FilledInput
					id="address-private-key"
					type={visible ? 'text' : 'password'}
					disabled={true}
					value={privateKey}
					hiddenLabel={true}
					endAdornment={
						<InputAdornment position="end">
							<BootstrapTooltip 
								title="Copied"
								visible={visible}
                open={!!privateKey}
                disableFocusListener
                disableHoverListener
                disableTouchListener
								placement="left-start"
							>
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleRevealPrivateKey}
									edge="end"
									sx={{ mr: '8px' }}
								>
									<FontAwesomeIcon style={{ fontSize: '12px' }} icon={solid('copy')} />
								</IconButton>
							</BootstrapTooltip>
						</InputAdornment>
					}
				/>
			</FormControl>
		</Box>
	)
}

export default connect(state => {
	return {
		keyringController: state?.crypto?.keyringController,
		address: state?.wallet?.address,
		requirePassword: state?.crypto?.requirePassword
	}
}, dispatch => {
	return {
		revealPrivateKey: () => {
			dispatch(revealPrivateKey());
		},
		toggleRequirePassword: () => {
			dispatch(requirePassword());
		},
		cleanPassword: () => {
			dispatch(updateKeyringController());
		}
	}
})(OperatorKey);