import { Pages } from "@mui/icons-material";
import React, { useState } from "react";

const { Pagination, Select, MenuItem } = require("@mui/material");

const TokensPagination = (props) => {
	const { itemsPerPage = 20, count, page, onChangeItemsPerPage, onChange, total } = props;
	const handleChange = (value) => {
		typeof onChangeItemsPerPage === 'function' && onChangeItemsPerPage(value);
	}
	
	return (
		<div className="tokens__pagination">
			<label htmlFor="">
				Show 
				<Select
					labelId="paging-select-number"
					id="paging-select-number"
					value={itemsPerPage}
					onChange={(event) => handleChange(event.target.value)}
				>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={20}>20</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select> 
				of {total} total entries
			</label>
			{count > 1 && <Pagination
				className="tokens__pagination__pages"
				count={count}
				page={page}
				onChange={onChange}
				total={total}
			/>}
		</div>
	)
}

export default TokensPagination;