import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { FormField } from "../Form/FormField";
import { t } from "i18next";
import { useState } from "react";
import "./ContactForm.scss";

const ContactForm = (props) => {
	const initData = {
		first_name: {
			id: "first_name",
			label: t("common:first_name"),
			errors: [],
			valid: [],
			value: "",
		},
		last_name: {
			id: "last_name",
			label: t("common:last_name"),
			errors: [],
			valid: [],
			value: "",
		},
		email: {
			id: "email",
			label: t("common:email"),
			errors: [],
			valid: [],
			value: "",
		},
		reason: {
			id: "reason",
			label: t("pages:help.reason_field_label"),
			errors: [],
			valid: [],
			value: "",
			type: "select",
			options: t("pages:help.contact.reasons", { returnObjects: true })
		},
		message: {
			id: "message",
			label: t("common:message"),
			type: "multiline",
			errors: [],
			valid: [],
			value: "",
			fullWidth: true,
		},
	};
	const [data, setFormData] = useState(initData);

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		let updateData = data[fieldId];
		if (!updateData) return;

		updateData.value = fieldValue;

		setFormData({
			...data,
			[fieldId]: updateData,
		});
	};

	return (
		<Box component="form" className="flex contact-form ">
			<Box className="w--50">
				{Object.values(data).map((field) => (
					<FormField
						fieldId={field["id"]}
						key={`contact-form-field-${field["id"]}`}
						value={field["value"]}
						onChange={handleChange}
						label={field.label}
						errors={field.errors}
						className={`contact-form__field contact-form__field-${field["id"]} ${
							!field.fullWidth ? "w--50" : ""
						}`}
						fullWidth={field.fullWidth || false}
						type={field.type || "default"}
						options={field.options || false}
					/>
				))}

				<Button variant="contained" color="secondary">
					{t("common:send")}
				</Button>
			</Box>
		</Box>
	);
};

export default ContactForm;
