import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import * as zip from "@zip.js/zip.js";
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	NativeSelect,
	TextField,
	Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { processFile } from "../../store/actions/tokenActions";
import { clear } from "../../store/slices/tokenSlice";
import { t } from "i18next";
import MessageBox from "../Animation/MessageBox";
import { csvToArray, readWorkbook } from "../../utils/helpers";
import { ZIP_MIME_TYPES, UPLOAD_DELAY } from "../../configs/constant";
import "./ImportTokens.scss";

const ALERT_DELAY = UPLOAD_DELAY + 5000;

const TOKEN_COLUMNS_MAPPING = {
	uuid: {
		id: "uuid",
		label: t("common:uuid"),
		find: /uuid/i,
	},
	markReference: {
		id: "markReference",
		label: t("pages:token.mark_reference"),
		find: /code|ref/i,
	},
	name: {
		id: "name",
		label: t("common:name"),
		find: /name/i,
	},
	description: {
		id: "description",
		label: t("pages:token.description"),
		find: /desc/i,
	},
	value: {
		id: "value",
		label: t("pages:token.value"),
		find: /price|value/i,
	}, // price
	currency: {
		id: "currency",
		label: t("pages:token.currency"),
		find: /currency/i,
	}, // USD, EUR
	lengthInMeter: {
		id: "lengthInMeter",
		label: t("pages:token.length"),
		find: /length/i,
	},
	widthInMeter: {
		id: "widthInMeter",
		label: t("pages:token.width"),
		find: /width/i,
	},
	depthInMeter: {
		id: "depthInMeter",
		label: t("pages:token.depth"),
		// find match depth or height
		find: /depth|height|hight/i,
	},
	weightInKilo: {
		id: "weightInKilo",
		label: t("pages:token.weight"),
		// find match start with weight
		find: /weight/i,
	},
};

const ImportTokens = (props) => {
	const inputComponent = useRef(null);
	const { isLoading, isDone, fileInfo, tokens, parseTokensInCsv, clear } = props;
	const { file, status, message } = fileInfo || {};
	const fileInputText = !!file?.name ? t("pages:wallet.file_" + status, { file }) : "";
	const fileError = t(message) !== message ? t(message) : message;
	const [open, toggleDialog] = useState(false);
	const [currentFile, updateCurrentFile] = useState({});
	const [currentMappingHeaders, updateCurrentMappingHeaders] = useState({});
	const [columnKeysMappingError, setColumnMappingError] = useState(false);

	useEffect(() => {
		if (isDone) {
			inputComponent.current.value = null;
		}
	}, [isDone]);

	const updateSettings = ({ lines, headers, name, file }) => {
		updateCurrentFile({
			lines,
			headers,
			name,
			file,
		});

		// check if there is a mapping in local storage
		let mapping = localStorage.getItem("unkb-token-column-mapping");
		if ( mapping ) {			
			updateCurrentMappingHeaders(JSON.parse(mapping));
		}	else {
			mapping = {};
			Object.values(TOKEN_COLUMNS_MAPPING).forEach((item) => {
				if ( item.find ) { 
					const found = headers.filter(i => i.match(item.find));
					if ( found.length ) {
						mapping[item.id] = found[0];
					}
				}
			});
			updateCurrentMappingHeaders(mapping);
		}
	}

	const uploadFile = async (event) => {
		const file = event.target.files[0];
		if (file) {
			if ("text/csv" === file.type) {
				const reader = new FileReader();
				reader.onload = function (e) {
					const csvText = e.target.result;
					const { lines, headers } = csvToArray(csvText);
					updateSettings({
						lines,
						headers,
						name: file.filename,
						file: file,
					});
				};

				reader.readAsText(file);
			} else if (ZIP_MIME_TYPES.includes(file.type)) {
				const zipContents = await new zip.ZipReader(new zip.BlobReader(file)).getEntries();
				if (zipContents && zipContents.length) {
					for (let i = 0; i < zipContents.length; i++) {
						const content = zipContents[i];
						if ("tokens.csv" !== content.filename) {
							continue;
						}
						let csvFile = await content.getData(new zip.TextWriter());
						const { lines, headers } = csvToArray(csvFile);
						updateSettings({
							lines,
							headers,
							name: file.filename,
							file: file,
						});
					}
				}
			} else {
				const workbook = await readWorkbook(file);
				let fileContent = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
				const { lines, headers } = csvToArray(fileContent);
				updateSettings({
					lines,
					headers,
					name: file.filename,
					file: file,
				});
			}
		}
	};

	const handleClose = () => {
		toggleDialog(false);
		clear();
	};
	
	const sumbitImport = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const prefix = "column-mapping-";
		const mapping = {};
		let isEmpty = true;
		for( let i in currentMappingHeaders ) {
			const key = currentMappingHeaders[i];
			const value = formData.get(`${prefix}${i}`);
			mapping[key] = value;
			isEmpty = isEmpty && !value;
		}
		if (isEmpty) {
			setColumnMappingError(t("Please select column mapping"));
			return;
		}

		// store mapping into local storage
		localStorage.setItem("unkb-token-column-mapping", JSON.stringify(mapping));

		// If valid mapping ready
		parseTokensInCsv(currentFile.file, mapping);
	};

	const handleCancelImportFile = () => {
		updateCurrentFile({});
		inputComponent.current.value = null;
	};

	return (
		<>
			<Button variant="outlined" color="secondary" onClick={() => toggleDialog(true)}>
				<span
					className="icon icon--import-tokens"
					style={{
						backgroundColor: "var(--unikbase-red)",
						marginRight: "8px",
					}}
				></span>
				{t("pages:wallet.import")}
				{/* <FontAwesomeIcon style={{ marginLeft: "1em" }} icon={solid("file-arrow-up")} /> */}
			</Button>
			<Dialog className="dialog dialog__import-tokens" open={open} onClose={handleClose}>
				<DialogTitle>
					{t("common:import")}
					<IconButton aria-label="close" onClick={handleClose}>
						<FontAwesomeIcon icon={regular("circle-xmark")} />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box className="wallet__token-upload" sx={{ mt: 2 }}>
						<input
							ref={inputComponent}
							style={{ display: "none" }}
							id="upload-tokens"
							name="upload-tokens"
							type="file"
							accept=".csv, .xls, .xlsx, .zip"
							onChange={uploadFile}
							disabled={isLoading}
						/>

						<Box className="w--100 flex flex--nowrap flex--align-center flex--justify-center">
							<TextField
								sx={{ mr: 1, flexGrow: 1 }}
								className="flex"
								variant="outlined"
								disabled
								size="small"
								value={fileInputText}
							/>

							<label htmlFor="upload-tokens">
								<Button variant="outlined" color="secondary" component="span" disabled={isLoading}>
									{t("pages:wallet.import_file")}
								</Button>
							</label>
						</Box>
						<Typography sx={{ mt: 1, mb: 1, fontSize: '0.825em' }} variant="body2" component="p">{t("pages:wallet.import_message")}</Typography>
						{!!currentFile.headers && (
							<Box
								sx={{
									mt: 2,
									p: 1,
								}}
							>
								<Typography variant="body">
									{"Importing from:"} {currentFile.file.name}
								</Typography>
								<Box
									component="form"
									className="columns-key-mapping"
									onSubmit={sumbitImport}
									sx={{
										mt: 1,
									}}
								>
									<FormHelperText>{columnKeysMappingError}</FormHelperText>
									{Object.values(TOKEN_COLUMNS_MAPPING).map((item) => (
										<Box
											key={`column-key-${item.id}`}
											className="flex flex--horizontal flex--justify-between flex--align-center import__mapping-column"
											sx={{ mb: 1 }}
										>
											<label>{item.label}</label>
											<FormControl>
												<NativeSelect
													inputProps={{
														name: `column-mapping-${item.id}`,
														id: `column-mapping-${item.id}`,
													}}
													value={currentMappingHeaders[item.id] || ""}
													onChange={(e) => {
														const { value } = e.target;
														const mapping = { ...currentMappingHeaders };
														mapping[item.id] = value;
														updateCurrentMappingHeaders(mapping);
													}}
												>
													<option value="">_______</option>
													{currentFile.headers.map((i, idx) => (
														<option key={`column-mapping-${item.id}-${idx}`} value={i}>
															{i}
														</option>
													))}
												</NativeSelect>
											</FormControl>
										</Box>
									))}
									<Box className="actions flex flex--justify-end" sx={{ mt: 3 }}>
										<Button onClick={() => handleCancelImportFile()} size="small">
											{t("common:cancel")}
										</Button>
										<Button type="submit" variant="contained" color="secondary" size="small">
											{t("Start")}
										</Button>
									</Box>
								</Box>
							</Box>
						)}
						<Error error={status === "error" ? fileInputText + " - " + fileError : null} />
						<ProcessedTokens tokens={tokens} />
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

const CloseIcon = ({ close }) => (
	<IconButton
		aria-label="close"
		color="inherit"
		size="small"
		onClick={() => {
			close();
		}}
	>
		<FontAwesomeIcon icon={solid("xmark")} />
	</IconButton>
);

const Error = ({ error }) => {
	const [open, setOpen] = useState(true);

	const close = () => {
		setOpen(false);
	};

	return !!error ? (
		<Collapse in={open}>
			<Alert severity="error" sx={{ my: 2 }} action={<CloseIcon close={close} />}>
				<AlertTitle>{t("common:error")}</AlertTitle>
				<span>{error}</span>
			</Alert>
		</Collapse>
	) : null;
};

const AlertMessage = ({ tokenInfo }) => {
	const { token, status, message } = tokenInfo;
	const severity = status === "loading" ? "info" : status;
	const title = status[0].toUpperCase() + status.substr(1);
	const fileDetails = t("pages:wallet.token_info", { token });

	const current = new Date().getTime();
	return (
		<MessageBox key={`message-${current}`} type={severity} timeout={5000}>
			<AlertTitle>{title}</AlertTitle>
			<span>{fileDetails}</span>
			<br />
			<span>{message}</span>
		</MessageBox>
	);
};

const ProcessedTokens = ({ tokens }) => {
	return [...Object.keys(tokens)].map((key) => <AlertMessage key={key} tokenInfo={tokens[key]} />);
};

export default connect(
	(state) => {
		const { fileInfo, tokens } = state.token || {};
		const auth = state.auth;
		return {
			accessToken: auth?.accessToken,
			isLoading: fileInfo?.status === "loading",
			isDone: fileInfo?.status === "success" || fileInfo?.status === "error",
			fileInfo,
			tokens,
		};
	},
	(dispatch) => {
		return {
			parseTokensInCsv: (file, keymap) => {
				dispatch(processFile({ file, keymap }));
			},
			clear: () => {
				dispatch(clear());
			},
		};
	}
)(ImportTokens);
