import { useEffect } from "react";
import { connect } from "react-redux";
import { useGetUserWallet } from '../../hooks/userHooks';
import { initWallet, setOperatorUser } from '../../store/slices/walletSlice';
import { standPost } from "./Standalone";

const WalletLoader = ({ address, storeWallet, setOperatorUser, unlockKeyrings }: any) => {
  const { isFetching, data } = useGetUserWallet();

  useEffect(() => {
    if (data && data.wallet) {
      const walletData = JSON.parse(data.wallet);

      const isOwnerOfWallet = walletData?.address === address;
      if (!isFetching && !!walletData && (walletData?.address === address || walletData?.isOperatorUser)) {
        // if (isOwnerOfWallet) {
          storeWallet({ wallet: walletData });
        // }
        setOperatorUser({
          isOperatorUser: walletData?.isOperatorUser,
          isOperator: walletData?.isOperator,
          partnerCode: data?.partnerCode,
          creationDate: walletData?.creationDate
        })
        unlockKeyrings && unlockKeyrings(isOwnerOfWallet);
      }
    }

  }, [data?.wallet]);

  useEffect(() => {
    standPost({ action: 'loaded' })
  }, []);

  return null
}

export default connect(
  (state: any) => {

    return {
      store: state,
    };
  },
  (dispatch: Function) => {
    return {
      storeWallet: (data: any) => {
        dispatch(initWallet(data));
      },
      setOperatorUser: (args: any) => {
        dispatch(setOperatorUser(args));
      },
    };
  }
)(WalletLoader);