import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Hidden,
	IconButton,
	InputBase,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { useGetTokenListQuery, useGetTokenTransactionsQuery } from "../../../service/api/meveoApi";
import "./TokenList.scss";
import { useEffect, useMemo, useState } from "react";
import { tokenStatus, newTokenStatus, documentPrivacyTypes, TIME_IN_DAYS } from "../../../configs/constant";
import { DateRange } from "react-date-range";

import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import GridView from "./GridView";
import ListView from "./ListView";
import { setUserWalletView } from "../../../store/slices/generalSettingsSlice";
import { t } from "i18next";
import EmptyView from "./EmptyView";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { list_sorting, merge, parseJwt, useTraceUpdate } from "../../../utils/helpers";
import { useRef } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FilterMenu } from "../../Filter";
import TokensPagination from "./TokensPagination";
import CircularSpinner from "../../CircularSpinner";
import LoadingModal from "../../LoadingModal";

export const getTokenStatus = (status, currentUserEmail, client, ownership, token = false, hideOwned = true) => {
	let isNewAccepted = false;
	if ( !!token && !!token.approvedDate ) {
		let dateDiff = new Date().getTime() - token.approvedDate;
		if ( dateDiff < 7 * TIME_IN_DAYS ) {
			isNewAccepted = true;
		} 
	}

	const tokenType =
		status === tokenStatus.IN_TRANSFER && client?.email === currentUserEmail
			? newTokenStatus.NEW
				: status === tokenStatus.IN_TRANSFER && client?.email !== currentUserEmail
				? newTokenStatus.TRANSFERRING
					: status === tokenStatus.REFUSED
					? newTokenStatus.REFUSED
						: ownership === newTokenStatus.OWNED.toLowerCase()
						? ( isNewAccepted ? 'Accepted' : (hideOwned ? '' : newTokenStatus.OWNED) ) : (hideOwned ? '' : newTokenStatus.OWNED);

	return tokenType || status;
};

const TokenFilterByName = ({ callback }) => {
	const [value, updateValue] = useState("");
	const [open, toggleOpen] = useState(false);

	const onChangeHandle = (event) => {
		const _newValue = event.target.value;
		clearTimeout(window.onSearchingTimeout);
		window.onSearchingTimeout = setTimeout(() => {
			typeof callback === "function" && callback(_newValue);
		}, 500);
		updateValue(_newValue);
	};

	return (
		<FormControl
			className={`flex flex--grow filter filter__by-name open`}
		>
			<IconButton color="secondary" type="button" aria-label="search">
				<i className="icon icon--search-2"></i>
			</IconButton>
			<InputBase
				sx={{ ml: 1, flex: 1, p: "4px 8px" }}
				placeholder={t("pages:token_list.token_search")}
				inputProps={{ "aria-label": t("pages:token_list.token_search") }}
				className="w--100"
				onChange={onChangeHandle}
				id="search-token-by-name-field"
				value={value}
			/>
		</FormControl>
	);
};

const ManagerUsersSelect = ({ users, onChange }) => {

	return (
		<FormControl sx={{ 
				mt: 2, 
				maxWidth: '100%',
				flexDirection: "row"
			}}>
			<Autocomplete
				disablePortal
				size="small"
				id="operator_status_user"
				options={users.map(item => ({
					label: item.email,
					...item
				}))}
				renderInput={(params) => <TextField placeholder="Search owner or add email address" {...params} />}
				isOptionEqualToValue={(option, value) => option.email === value.email}
				onChange={(event, _newValue) => {
					typeof onChange === 'function' && onChange(_newValue)	
				}}
			/>
		</FormControl>
	)
}

const TokenFilter = ({ open, users, filter, callback, isOperator = false }) => {
	
	const handleChange = useCallback((group, key, value) => {
		// This is abit complex will change later
		let updateFilter = { [group]: {} };
		if (typeof key === 'string' ) {
			updateFilter[group] = {
				[key]: value
			};
		} else {
			updateFilter[group] = key;
		}
		
		typeof callback === "function" && callback(updateFilter, group);
	}, [callback]);

	const onManagedUserChange = useCallback((_newValue) => {
		handleChange('status', 'operator_status', { 
			user: _newValue?.email || 'all'
		});
	}, [handleChange]);


	let data = useMemo(() => {
		let updateData =	[
				{
					type: "checkbox",
					label: t("pages:token_list.token_status"),
					key: "digitalTwin_status",
					items: [
						{
							label: t("pages:token_list.statuses.active"),
							key: "active",
							checked: false,
						},
						{
							label: t("pages:token_list.statuses.transferring"),
							key: "transferring",
							checked: false,
						},
						{
							label: t("pages:token_list.statuses.declined"),
							key: "refused",
							checked: false,
						},
					],
			},
			{
				type: "checkbox",
				label: t("pages:token_list.token_properties"),
				key: "digitalTwin_props",
				items: [
					{
						label: t("pages:token_list.props.offer"),
						key: "offer_code",
						checked: false,
					},
				],
			},
				// {
				// 	label: "Token Status",
				// 	key: "token_status",
				// 	items: [
				// 		{
				// 			label: "Private",
				// 			key: "private",
				// 			checked: false,
				// 		},
				// 		{
				// 			label: "Shared",
				// 			key: "shared",
				// 			checked: false,
				// 		},
				// 		{
				// 			label: "Public",
				// 			key: "public",
				// 			checked: false,
				// 		},
				// 	],
				// },
			];
		
		if ( !!isOperator ) {
			updateData.push({
				type: "checkbox",
				label: t("pages:token_list.column_labels.operator_status"),
				key: "operator_status",
				items: [
					{
						label: t("pages:token_list.statuses.owned"),
						key: "owned",
						checked: false,
					},
					{
						label: t("pages:token_list.statuses.managed"),
						key: "managed",
						checked: false,
					}
				],
				toggle: {
					key: "managed",
					element: {
						component: ManagerUsersSelect,
						props: {
							users: users,
							onChange: onManagedUserChange
						}
					}
				}
			})
		}

		// Add date range filter
		updateData.push({
			type: "date",
			key: "dateRange",
			label: t("pages:wallet.last_update"),
		})
		return updateData;
	}, [isOperator, users, onManagedUserChange])

	return (
		<FilterMenu 
			label={t("common:filter")}
			data={data} 
			filter={filter}
			buttonClasses="filter filter__by-status"
			onChange={(key, newItems) => {
				if ( key === 'dateRange' ) {
					if ( newItems && newItems.length > 0 ) {
						let datePicker = newItems[0];
						if ( datePicker && datePicker.items && datePicker.items[0] ) {
							let range = datePicker.items[0];
							if ( range &&  range.startDate && range.endDate ) {
								handleChange('dateRange', range);
							}
						} else {
							handleChange('dateRange', null);
						}
					} 
				} else {
					let value = newItems.reduce((data, item) => {
						data[item.key] = item.checked;
						return data;
					}, {});
					
					let group = 'status';
					if (key === 'digitalTwin_props') {
						group = 'props';
					}
					handleChange(group, key, value)
				}
			}} 
		/>
	);
};

const useIntervals = (callback, deps) => {
	const [ticks, setTicks] = useState(0);
	const prevTime = useRef(Date.now() / 1000);
	const tickTimer = useRef();

	useEffect(() => {
		tickTimer.current = setInterval(() => {
			setTicks(prev => prev + 1);
		}, 1000)
		return () => {
			clearInterval(tickTimer.current);
		}
	}, [])

	useEffect(() => {
		const timestamp = Date.now() / 1000;
		if (timestamp - prevTime.current > 3) {
			prevTime.current = timestamp;
			callback && callback();
		}
	}, [ticks, ...deps]);

	return tickTimer.current;
}

const TokenList = (props) => {
	const { isDone, switchView, username: currentUsername, currentUserEmail, currentAddress, settings, isOperatorUser } = props;
	const params = useParams();
	const page = parseInt(params['page'] || 1);
	const [queryOffset, setQueryOffset] = useState(0);
	const [fetched, setFetched] = useState(false);
	const allTokens = useRef([]);

	const queryPageSize = 20;
	const { data, isLoading, isFetching, refetch } = useGetTokenListQuery({ limit: queryPageSize, offset: queryOffset * queryPageSize });
	// Get all approveTransfer data
	const { data: historiesData, isFetching: historiesLoading, refetch: reloadHistories } = useGetTokenTransactionsQuery({
			filters: {
				type: {
					approveTransfer: true
				}
			}
		},
		{
			skip: true
		}
	)

	const navigate = useNavigate();
	const viewStyle = settings?.wallet_view || "list";

	const [exporting, setExporting] = useState(false);
	const [openFilterByStatus, toggleFilterByStatus] = useState(false);
	const [currentPageIdx, setPage] = useState(page);
	const [tokens, updateTokens] = useState([]);
	const [order, updateOrder] = useState(["lastUpdate", 1]);
	const [filter, updateFilter] = useState({});
	const [itemsPerPage, setItemsPerPage] = useState(queryPageSize);
	const isMobile = window.innerWidth < 768;
	const tickTimer = useIntervals(() => {
		updateTokens(allTokens.current);
	}, [allTokens.current]);

	const switchViewLayoutHandle = (type) => {
		switchView(currentAddress, type);
	};

	const switchPageHandler = (event, value) => {
		navigate("/wallet/page/" + value);
		setPage(value);
	};

	const sortHandle = (order, dir) => {
		updateOrder([order, dir === "asc" ? -1 : 1]);
	};

	const filterOptions = useMemo(() => ({
		digitalTwin_status: t("pages:token_list.token_status"),
		operator_status: t("pages:token_list.column_labels.operator_status"),
		user: t("common:email"),
		dateRange: t("pages:token_list.column_labels.date_created"),
	}), [settings]);

	useEffect(() => {
		if (!!data && data?.status === "success" && !!data?.result) {
			if (data.result.length > 0) {
				allTokens.current = [...allTokens.current, ...data.result];
				if (queryOffset === 0) {
					updateTokens(allTokens.current);
				}
				setQueryOffset(queryOffset + 1);
			} else {
				clearInterval(tickTimer);
				updateTokens(allTokens.current);
				setFetched(true)
			}
		}
	}, [data]);

	//
	useEffect(() => {
		!!isDone && refetch();
	}, [isDone, refetch]);

	// Process Data before display
	let users = useMemo(() => {
		let lists = JSON.parse(JSON.stringify(tokens.filter(i => !!i?.token?.ownerEmail)));
	
		let listEmail = lists.map(item => item.token.ownerEmail);
		return lists.map(item => ({ 
			email: item.token.ownerEmail,
			username: item.token.ownerUsername,
			uuid: item.token.owner
		})).filter((value, idx, arr) => listEmail.indexOf(value.email) === idx);
	}, [tokens])

	let visibleTokens = [];
	let filteredTokens = [];
	let pages = 1;
	let totalItems = tokens.length;
	// Prepare tokens list
	
	if (tokens && tokens.length > 0) {
		visibleTokens = JSON.parse(JSON.stringify(tokens));
		visibleTokens.map(i => {
			i.displayStatus = getTokenStatus(i.token.status, currentUserEmail, i.client, i.token.ownership, i.token);
			return i;
		})
		
		// Fillter
		if (filter && filter.name) {
			visibleTokens = visibleTokens.filter(
				(i) => {
					const search = filter.name.toLowerCase();
					return i.token.name?.toLowerCase().indexOf(search) >= 0 ||
					i.token.uuid.toLowerCase().indexOf(search) >= 0 ||
					i.token.description.toLowerCase().indexOf(search) >= 0
				}
			);
		}
		if (filter && filter.status) {
			// Filter digitalTwin status
			if (
				filter.status.digitalTwin_status &&
				Object.values(filter.status.digitalTwin_status).reduce((a, b) => a || b)
			) {
				visibleTokens = visibleTokens.filter((i) => {
					let condition = false;

					!!filter.status.digitalTwin_status?.refused &&
						(condition = condition || i.token.status === tokenStatus.REFUSED);
					!!filter.status.digitalTwin_status?.transferring &&
						(condition = condition || i.token.status === tokenStatus.IN_TRANSFER);
					!!filter.status.digitalTwin_status?.active &&
						(condition = condition || i.token.status === tokenStatus.ACTIVE);
					return condition;
				});
			}
			// Filter token status
			if (filter.status.token_status && Object.values(filter.status.token_status).reduce((a, b) => a || b)) {
				visibleTokens = visibleTokens.filter((i) => {
					let condition = false;
					!!filter.status.token_status?.shared &&
						(condition = condition || i.token.tokenPrivacy === documentPrivacyTypes.SHARED_TOKEN);
					!!filter.status.token_status?.public &&
						(condition = condition || i.token.tokenPrivacy === documentPrivacyTypes.PUBLIC);
					!!filter.status.token_status?.private &&
						(condition = condition || i.token.tokenPrivacy === documentPrivacyTypes.PRIVATE);
					return condition;
				});
			}

			if (filter.status.operator_status && (filter.status.operator_status.managed || filter.status.operator_status.owned) ) {

				visibleTokens = visibleTokens.filter((i) => {
					let condition = false;
					const ownership = i.token.ownership?.toUpperCase()
					!!filter.status.operator_status?.owned &&
						(condition = condition || ownership === newTokenStatus.OWNED);
						
					if ( !!filter.status.operator_status?.managed ) {
						condition = condition || ownership === newTokenStatus.MANAGED

						if ( !!filter.status.operator_status.user && filter.status.operator_status.user !== 'all' ) {
							condition = condition && i?.token?.ownerEmail === filter.status.operator_status.user;
						}
					}
					
					return condition;
				});
			}
		}

		// Filter token props
		if (filter.props) {
			if (filter.props.digitalTwin_props && Object.values(filter.props.digitalTwin_props).reduce((a, b) => a || b)) {
				visibleTokens = visibleTokens.filter((i) => i?.token?.commercialOfferCode);
			}
		}

		if (!!filter && filter.dateRange ) {
			const today = new Date();
			today.setHours(23,59,59,999);

			let start = filter.dateRange?.startDate ? (new Date(filter.dateRange.startDate)).setHours(0,0,0,0) : null;
			let end = filter.dateRange?.endDate ? (new Date(filter.dateRange.endDate)).setHours(23,59,59,999) : null;
			
			if ( !end || end > today.getTime()) {
				end = today.getTime();
			}
			
			if ( start === end ) {
				visibleTokens = visibleTokens.filter(i => start <= i.token.lastUpdate)
			} else {
				visibleTokens = visibleTokens.filter(i => start <= i.token.lastUpdate && end >= i.token.lastUpdate)
			}
		}
		// Sort
		visibleTokens.sort(list_sorting(order[0] === 'status' ? 'displayStatus' : `token.${order[0]}`, order[1]));
		let start = (currentPageIdx - 1) * itemsPerPage;

		// Paging
		pages = visibleTokens.length > 0 ? Math.ceil(visibleTokens.length / itemsPerPage) : 0;

		filteredTokens = [...visibleTokens];

		totalItems = visibleTokens.length;
		visibleTokens = visibleTokens.slice(start, start + itemsPerPage);

		// Finally add approve data 
		if ( !!historiesData && historiesData.status === 'success' && !!historiesData.result && Array.isArray(historiesData.result) ) {
			visibleTokens.map(item => {
				if( item.token.owner !== item.token.creator ) {
					let transactions = historiesData.result?.filter(transaction => item.token.uuid === transaction.tokenId)
					if( transactions.length > 0) {
						item.token.approvedDate = transactions[transactions.length-1]?.transactionDate
					}
				}
				return item;
			})
		}
	}

	const removeFilter = (key, value) => {
		if (key === 'dateRange') {
			updateFilter({ ...filter, dateRange: null });
		} else if (key === 'user') {
			updateFilter({
				...filter,
				status: {
					...filter.status,
					operator_status: {
						...filter.status.operator_status,
						user: ''
					}
				}
			});
		} else {
			let newFilter = { ...filter };
			newFilter.status[key][value] = false;
			updateFilter(newFilter);
		}
	}

	const csvData = useMemo(() => {
		const data = []
		const headers = [
			t('pages:token_list.column_labels.name'),
			t('pages:token_list.column_labels.uuid'),
			t('pages:token_list.column_labels.tag_ref'),
			t('pages:token_list.column_labels.offer'),
			isOperatorUser
				? t('pages:token_list.column_labels.owner')
				: t('pages:token_list.column_labels.operator'),
			t('pages:token.creation_date'),
			t('pages:wallet.last_update'),
			t('pages:token_list.column_labels.status'),
			t('pages:token_list.column_labels.operator_status'),
		];

		data.push(headers);
		filteredTokens.map((item) => {
			const {
				token,
				client,
				token: {
					name,
					uuid,
					tokenMarkReference,
					commercialOfferCode,
					ownerEmail,
					creationDate,
					lastUpdate,
					status,
					ownership
				},
			} = item;
			const _ownership = ownership === newTokenStatus.OWNED.toLowerCase() && currentUserEmail === client.email ? newTokenStatus.OWNED : newTokenStatus.MANAGED;
			const tokenStatus = getTokenStatus(status, currentUserEmail, client, _ownership, token);

			data.push([
				name,
				uuid,
				tokenMarkReference,
				commercialOfferCode === 'BASIC' ? 'FREE' : commercialOfferCode,
				ownerEmail,
				new Date(creationDate).toISOString(),
				new Date(lastUpdate).toISOString(),
				tokenStatus,
				_ownership
			])
		})

		return data;
	}, [filteredTokens, currentUserEmail, isOperatorUser]);

	const exportCSV = () => {
		setExporting(true);
	}

	useEffect(() => {
		if (fetched && exporting) {
			setTimeout(() => {
				document.getElementById('export-csv').click();
				setExporting(false);
			}, 300);
		}
	}, [fetched, exporting])

	return (
		<Box className={`tokens ${!visibleTokens || visibleTokens.length <= 0 ? "tokens--empty" : ""}`}>
			{!!visibleTokens && (
				<>
					<Box className="tokens__actions flex flex--horizontal flex--justify-end">
						<Box className="tokens__filters">
							<IconButton
								className={"export-csv"}
								variant="text"
								color="secondary"
								size="small"
								onClick={exportCSV}
							><span className="icon-csv-download"></span></IconButton>
							{exporting && <CSVLink data={csvData} filename={`${currentUsername}_passports_${Date.now()}.csv`} id="export-csv" target="_blank" />}
							<TokenFilter
								open={openFilterByStatus}
								isOperator={isOperatorUser}
								users={users}
								filter={filter}
								callback={(value, key = 'status') => {
									let newFilter = merge(JSON.parse(JSON.stringify(filter)), value);
									updateFilter(newFilter);
									setPage(1);
								}}
							/>
							<TokenFilterByName
								callback={(value) => {
									updateFilter({ ...filter, name: value });
									setPage(1);
								}}
							/>
						</Box>
						<Box className="tokens__view-switcher">
							<Box className="tokens__view-switcher-inner">
								<Button
									className={viewStyle === "list" ? "active" : ""}
									size="small"
									onClick={() => switchViewLayoutHandle("list")}
									title={t("pages:wallet.list_view")}
								>
									<span className="icon icon--list-view"></span>
								</Button>
								<Button
									className={viewStyle === "grid" ? "active" : ""}
									size="small"
									onClick={() => switchViewLayoutHandle("grid")}
									title={t("pages:wallet.grid_view")}
								>
									<span className="icon icon--grid-view"></span>
								</Button>
							</Box>
						</Box>
					</Box>
					<Hidden smUp>
						<Box className="tokens--filter-selected-options">
							<Box className="tokens--filter-selected-options-chips">
								{filter &&
									Object.keys(filterOptions).map((key, idx) => {
										const value = key === 'dateRange' ? filter.dateRange
											: key === 'user' ? filter.status?.operator_status?.user
												: filter.status?.[key];

										if (!value) {
											return null;
										}

										const values = key === 'dateRange' ? [new Date(value.startDate).toLocaleDateString() + '-' + new Date(value.endDate).toLocaleDateString()]
											: key === 'user' ? [value]
												: Object.keys(value).map(e => (e !== 'user' && value[e] ? e : null)).filter(e => (!!e));

										return (
											values.map((value, idx) => (
												<Box>
													<Button
														key={`filter-${key}`}
														variant="contained"
														onClick={() => removeFilter(key, value)}
														sx={{ mr: 1, my: 1, backgroundColor: '#FFE8E1', boxShadow: 'none', textWrap: 'nowrap' }}
														endIcon={<span
															className="icon icon--close"
															style={{ marginRight: 0, width: 16, height: 16, backgroundColor: '#EA5123' }}
														/>}
													>
														<Typography variant="body2" color="secondary" textTransform="capitalize">{filterOptions[key]}: {value}</Typography>
													</Button>
												</Box>
											))
										)
									})
								}
							</Box>
						</Box>
					</Hidden>
					<Box className="tokens__list">
						{(isFetching || isLoading) && tokens?.length === 0 && (
							<Hidden smUp>
								<Box
									width={280}
									display="flex"
									flexDirection="column"
									alignItems="center"
									marginLeft="auto"
									marginRight="auto"
									mt={10}
								>
									<CircularSpinner variant="arc" size={88} />
									<Typography variant="body2" color="GrayText">
										{t("pages:token_list.loading_digital_passports")}
									</Typography>
								</Box>
							</Hidden>
						)}
						{!(isFetching || isLoading) && visibleTokens?.length === 0 && (
							<Box className="tokens__list__empty">
								<Box className="tokens__list__boxes"></Box>
								<Typography variant="body2" color="primary" mt={3}>
									{t("pages:token_list.no_token_found")}
								</Typography>
							</Box>
						)}
						{(!isMobile || !(isFetching || isLoading) || tokens?.length > 0) && (viewStyle === "grid" ? (
							<GridView fetching={(isFetching || historiesLoading || isLoading) && tokens?.length === 0} tokens={visibleTokens} />
						) : (
							<ListView
								fetching={(isFetching || historiesLoading || isLoading) && tokens?.length === 0}
								tokens={visibleTokens}
								sortHandle={sortHandle}
							/>
						))}
						{visibleTokens?.length > 0 && (
							<TokensPagination
								count={pages}
								page={currentPageIdx}
								itemsPerPage={itemsPerPage}
								onChange={switchPageHandler}
								onChangeItemsPerPage={(value) => setItemsPerPage(value)}
								total={totalItems}
							/>
						)}
						{exporting && !fetched && (
							<LoadingModal
								visible={isFetching}
								onClose={() => null}
							/>
						)}
					</Box>
				</>
			)}
		</Box>
	);
};

export default connect(
	(state) => {
		const { fileInfo } = state.token || {};
		const settings = state.generalSettings;
		const wallet = state.wallet;
		const auth = state?.auth;

		let username = wallet?.private?.username;

		if (wallet.isOperatorUser) {
			let jwtDecode = parseJwt(auth.accessToken)
			username = jwtDecode.name;
		}

		return {
			currentUserEmail: wallet?.private?.email?.address,
			isOperatorUser: wallet.isOperatorUser || wallet.isOperator,
			currentAddress: wallet?.address,
			settings: settings,
			username,
			isDone: fileInfo?.status === "success" || fileInfo?.status === "error",
		};
	},
	(dispatch) => {
		return {
			switchView: (userId, type) => {
				dispatch(
					setUserWalletView({
						userId: userId,
						type,
					})
				);
			},
		};
	}
)(TokenList);
