// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loading {
  position: relative;
  width: 50px;
  height: 50px;
}

#loading .circle-square {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

#loading .circle-square {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  animation: circle-rotate 1.5s infinite linear;
}

.red,
.green,
.yellow,
.blue {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin: 2px;
}

.red {
  background-color: #ea4335;
}

.blue {
  background-color: #4285f4;
}

.green {
  background-color: #34a853;
}

.yellow {
  background-color: #fbbc05;
}

/* CSS ANIMATION */
@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,6CAA6C;AAC/C;;AAEA;;;;EAIE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,kBAAkB;AAClB;EACE;IACE,gCAAgC;EAClC;EACA;IACE,+CAA+C;EACjD;AACF","sourcesContent":["#loading {\n  position: relative;\n  width: 50px;\n  height: 50px;\n}\n\n#loading .circle-square {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 40px;\n  height: 40px;\n  transform: translate(-50%, -50%);\n}\n\n#loading .circle-square {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  animation: circle-rotate 1.5s infinite linear;\n}\n\n.red,\n.green,\n.yellow,\n.blue {\n  position: relative;\n  width: 16px;\n  height: 16px;\n  border-radius: 8px;\n  margin: 2px;\n}\n\n.red {\n  background-color: #ea4335;\n}\n\n.blue {\n  background-color: #4285f4;\n}\n\n.green {\n  background-color: #34a853;\n}\n\n.yellow {\n  background-color: #fbbc05;\n}\n\n/* CSS ANIMATION */\n@keyframes circle-rotate {\n  from {\n    transform: translate(-50%, -50%);\n  }\n  to {\n    transform: translate(-50%, -50%) rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
