// http://163.172.169.24:5000/search?q=delaunay&p=2
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'; // React-specific entry point to allow generating React hooks
import api from '../../configs/api';
import config from '../../configs/config';

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.UPLOAD_SERVER_ADDRESS,
    credentials: "same-origin", 
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.accessToken;
			if (token && !headers.get('authorization') ) {
				headers.set("authorization", `Bearer ${token}`);
				return headers;
			}
		},
  }),
  endpoints: (builder) => ({
    uploadDocuments: builder.mutation({
      query: ({ data, delay = false, token, pushCloud = false }) => {
        let url = `${api.upload.tokenDocument}${delay ? '?delay=true' : ''}`;
				let headers = {}
				!!token && (headers.authorization = `Bearer ${token}`);

				let settings = {
          url: url, 
          method: pushCloud ? 'PUT' : 'POST'
        }
				if ( pushCloud ) {
					headers['Content-Type'] = 'application/json';
					settings['body'] = JSON.stringify(data);
				} else {
					settings['body'] = data;
					settings['formData'] = true;
				}

				settings['headers'] = headers;
        return settings;
      }
    }),
  })
})

export const { useUploadDocumentsMutation } = uploadApi;
