import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Menu, MenuItem, Tab, Tabs, Typography } from "@mui/material";

import {
	centimeterToMeter,
	documentPathTypes,
	documentTypes,
	gramToKilogram,
	inchToMeter,
	newTokenStatus as NEW_STATUSES,
	poundToKilogram,
	tokenStatus as STATUSES,
	TRANSACTION_TYPES,
	unitTypes,
} from "../../configs/constant";
import BigNumber from "bignumber.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import TokenGallery from "./TokenGallery";
import TokenDocuments from "./TokenDocuments";
import TokenDetailSkeleton from "./TokenDetailSkeleton";
import TokenHistoryFilter from "./TokenHistoryFilter";

import "./TokenDetail.scss";
import { t } from "i18next";
import { ImageProxy as Image } from "../Gallery";
import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TokenTransactions from "./TokenTransactions";
import TokenSpecifications from "./TokenSpecifications";
import ReceiveToken from "./ReceiveToken/ReceiveToken";
import TokenMarkproofVerifications from "./TokenMarkproofVerifications";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getTokenBlockchainExplorerURL, getTokenIdBignumber } from "../../utils/helpers";
import BreadcrumbNavi from "../BreadcrumbNavi";
import GenerateTokenCertificate from "./GenerateTokenCertificate/GenerateTokenCertificate";
import TransferToken from "./TransferToken/TransferToken";
import TokenPrivacy from "./ShareTokenPrivacy/TokenPrivacy";
import ShareToken from "./ShareToken/ShareToken";
import TokenDetailEdit from "./EditToken";
import { TokenEditProvider } from "./EditToken/EditTokenContext";
import DocumentFilter from './DocumentFilter'
import { usePlatformContext } from "../../layout/Platform/PlatformContext";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			className="token__attributes-tabpanel"
			role="tabpanel"
			hidden={value !== index}
			id={`attribute-tabpanel-${index}`}
			aria-labelledby={`attribute-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};

const TokenTransferMessage = connect(state => {
	const wallet = state?.wallet;

	return {
		currentUserEmail: wallet?.private?.email?.address,
	}
})((props) => {
	const { token, currentUserEmail, onToggleTransfer } = props;

	const isRefused = token?.token?.status === STATUSES.REFUSED;
	const isTransferred =
		!!currentUserEmail && token.client && !!token.client.email && currentUserEmail !== token.client.email;

	return (
		<Box className="token__message--transfer w--100" sx={{ mb: 2 }}>
			{(isTransferred || isRefused) && (
				<Box className={isRefused ? 'transfer-status__refused' : ''} onClick={isRefused ? onToggleTransfer : null}>
					<Alert
						severity="warning"
						icon={<FontAwesomeIcon icon={solid('arrow-up-long')} />}
					>
						<AlertTitle>{t("pages:token.transferring")}</AlertTitle>
						{isRefused
							? t('pages:token.declined_get_it_back')
							: t("pages:token.transferring_message", { email: token.client.email })
						}
					</Alert>
				</Box>
			)}
		</Box>
	)
});

const TokenDetail = (props) => {
	const { isFetching, refetchFunc, token, currentUserEmail, mode, isLoggedin, unit, updateTokenModalHandle } = props;
	const [editing, setEditing] = useState(false);
	const [standalone, setStandalone] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [historyCount, setHistoryCount] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuKey, setMenuKey] = useState(null);
	const [documentFilters, setDocumentFilters] = useState({});
	const [historyFilters, setHistoryFilters] = useState({});
	const [historyEventTypes, setHistoryEventTypes] = useState([]);
	const [historyAuthors, setHistoryAuthors] = useState([]);
	const [transferOpen, setTransferOpen] = useState(false);
	const platformContext = usePlatformContext();
	const editingRef = useRef(false);
	const params = {};
	for (let [key, value] of searchParams.entries()) {
		params[key] = value;
	}
	const useQuickActions = false;
	const tabs = useMemo(() => ["specifications", "documents", "history"], []);
	const documentButtons = useMemo(() => [
		{
			key: 'document-sort',
			label: t("pages:token.sort"),
			icon: <span className="icon icon--sort"></span>,
			onClick: (item) => setSortDocs(item.key),
			options: [
				{
					key: 'newest',
					label: t("pages:token:document_details.newest"),
				},
				{
					key: 'oldest',
					label: t("pages:token:document_details.oldest"),
				},
				{
					key: 'name',
					label: t("pages:token:document_details.name"),
				},
				{
					key: 'type',
					label: t("pages:token:document_details.type"),
				},
			]
		},
		{
			key: 'document-filter',
			label: t("pages:token.filter"),
			icon: <span className="icon icon--filter"></span>,
			onClick: () => alert('coming soon'),
			options: [
				...Object.keys(documentTypes()).map((key) => ({
					key,
					label: documentTypes()[key].name,
				})),
				{
					key: 'all',
					label: t("pages:token:document_details.all"),
				},
			],
			menu: (item) => <DocumentFilter
				anchorEl={anchorEl}
				open={item.key === menuKey && Boolean(anchorEl)}
				onClose={onCloseMoreMenu}
				onChange={(filters) => applyDocumentFilter(filters)}
				key={`render-document-menu-${item.key}`}
			/>
		}
	]);
	const historyButtons = useMemo(() => [
		{
			key: 'history-sort',
			label: t("pages:token.sort"),
			icon: <span className="icon icon--sort"></span>,
			onClick: (item) => setSortHistory(item.key),
			options: [
				{
					key: 'newest',
					label: t("pages:token:document_details.newest"),
				},
				{
					key: 'oldest',
					label: t("pages:token:document_details.oldest"),
				},
				{
					key: 'type',
					label: t("pages:token:document_details.type_of_activity"),
				},
				{
					key: 'status',
					label: t("pages:token:document_details.status"),
				},
				{
					key: 'author',
					label: t("pages:token:document_details.updated_by"),
				},
			]
		},
		{
			key: 'history-filter',
			label: t("pages:token.filter"),
			icon: <span className="icon icon--filter"></span>,
			onClick: () => alert('coming soon'),
			menu: (item) => <TokenHistoryFilter
				anchorEl={anchorEl}
				open={item.key === menuKey && Boolean(anchorEl)}
				onClose={onCloseMoreMenu}
				onChange={(filters) => setHistoryFilters(filters)}
				key={`render-document-menu-${item.key}`}
				typeOfEvents={historyEventTypes}
				authors={historyAuthors}
			/>
		}
	]);

	const maxLengthDesc = 160;
	const [viewAllDesc, setViewAllDesc] = useState(false);
	const [detailTab, showDetail] = useState(0);

	const [sortDocs, setSortDocs] = useState('type');
	const [sortHistory, setSortHistory] = useState('');

	const tokenType =
		token?.token.status === STATUSES.IN_TRANSFER && token?.client?.email === currentUserEmail
			? NEW_STATUSES.NEW
			: token?.token.status === STATUSES.IN_TRANSFER && token?.client?.email !== currentUserEmail
				? NEW_STATUSES.TRANSFERRING
				: NEW_STATUSES.OWNED;

	const isNew = tokenType === NEW_STATUSES.NEW;
	const isEditable = !!isLoggedin && token?.token.status !== STATUSES.IN_TRANSFER;

	const coverImage = !!token && !!token.documents ? token.documents.find((document) => document.path === documentPathTypes.COVER_IMAGE) : '';
	const { ownerUsername, ownerEmail, ownerWallet } = token?.token || {};

	const applyDocumentFilter = (filters) => {
		setDocumentFilters(filters)
	}

	const parseHistoryFilterData = (data) => {
		const typeOfEvents = [];
		const authors = [];

		data.forEach((item) => {
			if (item.type) {
				typeOfEvents.push(TRANSACTION_TYPES[item.type])
			}
			if (item.authorUsername) {
				authors.push(item.authorUsername)
			}
		})

		setHistoryEventTypes([...new Set(typeOfEvents)].sort((a, b) => t(TRANSACTION_TYPES[a.key].label).localeCompare(t(TRANSACTION_TYPES[b.key].label))))
		setHistoryAuthors([...new Set(authors)].sort((a, b) => `${a}`.localeCompare(`${b}`)))
	}

	const onToggleMore = (event) => {
		setAnchorEl(event.currentTarget);
		setMenuKey(event.currentTarget?.getAttribute('data-key'));
	};

	const toggleTransfer = () => {
		setTransferOpen(true);
		setTimeout(() => {
			setTransferOpen(false);
		}, 500);
	}

	const onCloseMoreMenu = () => {
		setAnchorEl(null);
		setMenuKey(null);
	};

	const renderDocumentButton = (item) => {
		return (
			<>
				<Button
					key={`render-document-button-${item.label}`}
					data-key={item.key}
					variant="text"
					className="document-button"
					onClick={onToggleMore}
				>
					{item.icon}
					<Typography textTransform="capitalize" component="span" variant="body2" mr={1}>
						{item.label}
					</Typography>
					<FontAwesomeIcon icon={solid('chevron-down')} />
				</Button>
				{item.menu
					? item.menu(item) :
					<Menu
						anchorEl={anchorEl}
						open={item.key === menuKey && Boolean(anchorEl)}
						onClose={onCloseMoreMenu}
						key={`render-document-menu-${item.key}`}
					>
						{item.options.map((option) => (
							<MenuItem key={option.label}
								onClick={() => {
									onCloseMoreMenu()
									item.onClick(option)
								}}>
								<Box display="flex" alignItems="center" justifyContent="center">
									<Typography ml={1} >{option.label}</Typography>
								</Box>
							</MenuItem>
						))}
					</Menu>
				}
			</>
		)
	}

	useEffect(() => {
		if (params.activeTab && tabs.indexOf(params.activeTab) >= 0) {
			showDetail(tabs.indexOf(params.activeTab));
		}
	}, [params.activeTab, tabs]);

	useEffect(() => {
		if (document.getElementById('standaloneWebApp')) {
			setStandalone(true);
			window.addEventListener("message", messageHandler);
			document.addEventListener("message", messageHandler);
		}
	}, []);

	const messageHandler = message => {
		try {
			const data = JSON.parse(message.data);
			if (data.action === 'token.EditToken') {
				editingRef.current = true;
				setEditing(true);
			} else if (data.action === 'token.TransferToken'
				|| data.action === 'token.GenerateCertificate'
				|| data.action === 'token.PrivacySettings'
				|| data.action === 'token.ShareToken') {
				const handled = platformContext?.navigationIntercept && platformContext.navigationIntercept()
				if (editingRef.current && handled) {
					// do nothing as it is handled
				} else {
					editingRef.current = false;
					setEditing(false);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	if (isFetching) return <TokenDetailSkeleton />;
	if (editing) return (
		<TokenEditProvider>
			<TokenDetailEdit
				token={token}
				isFetching={isFetching}
				isLoading={false}
				refetchFunc={refetchFunc}
				onCancel={() => {
					editingRef.current = false;
					setEditing(false)
				}}
				mode={mode || "default"}
			/>
		</TokenEditProvider>
	)
	return (
		<>
			<ReceiveToken token={token} />
			<TokenTransferMessage token={token} onToggleTransfer={toggleTransfer} />
			<Box className="token__information flex flex--horizontal flex--wrap">
				<Box className="token__heading flex flex--horizontal">
					<BreadcrumbNavi paths={[
						{ label: t("pages:wallet.title"), to: "/wallet" },
						{ label: token.token.name },
					]} />

					{!!isEditable && mode === 'default' && (
						<Box className="token__actions flex flex--horizontal">
							<Button
								color="secondary"
								variant="text"
								onClick={() => setEditing(true)}
								aria-label='edit'
								title='edit token'
							>
								<span className="icon icon__action icon--edit">_</span>
								<Typography component="span" variant="body2">
									{t("common:edit")}
								</Typography>
							</Button>

							{!standalone && (
								<>
									<TransferToken token={token} refetchFunc={refetchFunc} open={transferOpen} />
									<TokenPrivacy token={token} />
									<GenerateTokenCertificate token={token} />
									<ShareToken token={token} />
								</>
							)}
						</Box>
					)}
				</Box>

				<Box className="token__gallery">
					<TokenGallery
						coverImage={coverImage}
						documents={token?.documents.filter((item) => item.path === documentPathTypes.GALLERY)}
					/>
				</Box>

				<Box className="token__desc">
					<Card className="description" variant="outlined" sx={{ border: "none" }}>
						<CardHeader
							title={token.token.name}
						/>
						<CardContent sx={{ p: 0 }}>
							{token.token.description?.substring(0, viewAllDesc ? token.token.description.length : maxLengthDesc)}
							{token.token.description?.length > maxLengthDesc && (
								<>
									{!viewAllDesc && (<span>...</span>)}
									<Box mt={0} pt={`0 !important`}>
										<Typography
											component="a"
											variant="body2"
											onClick={() => setViewAllDesc(!viewAllDesc)}
										>
											{viewAllDesc ? t('pages:token.show_less') : `${t('pages:token.show_all')}`}
										</Typography>
									</Box>
								</>
							)}
							{(mode === 'default' || !!token.token.blockChainID) &&
								<>
									<Typography variant="h6" component="h6" mt={2}>
										{t("pages:token.blockchain_id")}
									</Typography>
									<a
										className="blockchainId"
										href={getTokenBlockchainExplorerURL(token.token.uuid)}
										target="_blank"
										rel="noreferrer"
									>
										{`${getTokenIdBignumber(token.token.uuid)}`}
									</a>
								</>
							}

							{(ownerUsername || ownerEmail || ownerWallet) && mode !== 'default' &&
								<Box className="description__ownership">
									<Typography variant="h6" component="h6" mt={2}>
										{t("pages:token.owner")}
									</Typography>
									{!!ownerUsername && (
										<Typography variant="body2" component="h6">
											{t("common:username")}:
											<Typography component="span" fontWeight="500" ml={1}>{ownerUsername}</Typography>
										</Typography>
									)}
									{!!ownerEmail && (
										<Typography variant="body2" component="h6">
											{t("common:email")}:
											<Typography component="span" fontWeight="500" ml={1}>{ownerEmail}</Typography>
										</Typography>
									)}
									{!!ownerWallet && (
										<Typography variant="body2" component="h6">
											{t("pages:token.wallet_address")}:
											<Typography component="span" fontWeight="500" ml={1}>{ownerWallet}</Typography>
										</Typography>
									)}
								</Box>}
						</CardContent>
					</Card>
				</Box>

				<Box className="token__content">
					<Box sx={{ width: "100%" }}>
						<Typography variant="h6" component="h3">
							{t("pages:token.specifications")}
						</Typography>
						<TokenSpecifications token={token} unit={unit} />

						<Box className="documents-heading" display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h6" component="h3">
								{t("pages:token.documents")}
							</Typography>
							<Box className="document-buttons">
								{documentButtons.map((item, idx) =>
									<React.Fragment key={`document-button-${idx}`}>
										{renderDocumentButton(item)}
									</React.Fragment>
								)}
							</Box>
						</Box>
						<TokenDocuments token={token} mode={mode} sort={sortDocs} filter={documentFilters} />

						<Box className="history-heading" display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h6" component="h3">
								{t("pages:token.history")}
							</Typography>
							<Box className="history-buttons">
								{historyButtons.map((item, idx) =>
									<React.Fragment key={`history-button-${idx}`}>
										{renderDocumentButton(item)}
									</React.Fragment>
								)}
							</Box>
						</Box>
						<Typography variant="body2" component="span" color="GrayText">
							{t("pages:token.document_details.history_of_activity_found", { count: historyCount })}
						</Typography>
						<TokenTransactions
							token={token}
							sort={sortHistory}
							filter={historyFilters}
							coverImage={coverImage}
							onDataChange={(data) => !!data && setHistoryCount(data.length)}
							onFilterData={parseHistoryFilterData}
						/>
					</Box>
				</Box>

				{/* <Box className="cover-image">
					{coverImage && <Image url={coverImage.fileUrl} alt={token.token.name} width={800} />}
				</Box> */}
			</Box>
			{standalone && (
				<Box className={`token__mobile-actions token__actions ${useQuickActions ? '' : 'hidden'}`}>
					<ShareToken token={token} variant="outlined" />
					<TransferToken token={token} refetchFunc={refetchFunc} variant="outlined" open={transferOpen} />
					<GenerateTokenCertificate token={token} variant="contained" />
					<Box display="none" width={0}><TokenPrivacy token={token} /></Box>
				</Box>
			)}
		</>
	);
};

export default connect((state) => {
	const wallet = state.wallet;
	const auth = state.auth;

	return {
		isLoggedin:
			!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime() ? auth.accessToken : false,
		unit: wallet?.public?.unit || unitTypes.METRIC.key,
		currentUserEmail: wallet?.private?.email?.address
	};
})(TokenDetail);
