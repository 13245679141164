import { connect } from "react-redux";
import RequireUser from "../components/Authentication";
import RequireOperator from "../components/Authentication/RequireOperator";
import {
    PageNotFound,
    Profile,
    FAQ,
    Login,
    Wallet,
    Token,
    Others
} from "../pages";
import * as Pages from "../pages";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "../pages/TermsConditions";
import {t} from 'i18next';
import CertificateGenerator from "../pages/Standalone/CertificateGenerator";
import Standalone from "../pages/Standalone/Standalone";
import ShareTokenSelector from "../pages/Standalone/ShareTokenSelector";
import PrivacySettings from "../pages/Standalone/PrivacySettings";
import EditTokenWebApp from "../pages/Standalone/EditTokenWebApp";
import CreateTokenWebApp from "../pages/Standalone/CreateTokenWebApp";
import TransactionsWebApp from "../pages/Standalone/TransactionsWebApp"
import TokenDetailsWebApp from "../pages/Standalone/TokenDetailsWebApp";

export const ProfileMenuLabel = connect(state => {
	return {
		isOperatorUser: state?.wallet?.isOperatorUser
	}
})(({ isOperatorUser }) => (<>{isOperatorUser ? t("menu:operator") : t("menu:profile")}</>))

export function usePlatformMenus() {
	return {
		menu: {
			type: "group",
			label: t("menu:main-menu"),
			key: "main-menu",
			items: [
				{
					path: '/wallet',
					label: t('menu:wallet'),
					icon: 'wallet'
				},
				{
					path: '/token/transactions',
					label: t('menu:history'),
					icon: 'histories'
				},
				{
					path: '/mark-proof/verification',
					label: t('menu:markProof'),
					icon: 'fingerprint',
					operator: true
				},
			]
		},
		others: {
			label: t("menu:other-menus"),
			type: "group",
			key: "other-menus",
			items: [
				{
					path: '/terms-conditions',
					label: t('menu:terms-conditions'),
					icon: 'terms-conditions'
				},
				{
					path: '/privacy-policy',
					label: t('menu:privacy-policy'),
					icon: 'privacy-policies'
				},
				{
					path: '/faq',
					label: t('menu:faq'),
					icon: 'faqs'
				}
			]
		}
	}
}

export const menus = {
    profile: {
        path: '/profile',
        label: t('menu:profile'),
        icon: 'profile'
    },
    wallet: {
        path: '/',
        label: t('menu:wallet'),
        icon: 'wallet'
    },
    search: {
        path: '/token/search',
        label: t('menu:search')
    },
    faq: {
        path: '/faq',
        label: t('menu:faqs')
    }
}

export const routes = [
  {
    path: "/",
    element: <RequireUser><Pages.Wallet /></RequireUser>,
  },
  {
    path: "/wallet",
    element: <RequireUser><Pages.Wallet /></RequireUser>,
  },
  {
    path: "/wallet/page/:page",
    element: <RequireUser><Pages.Wallet /></RequireUser>,
  },
  {
    path: "/profile",
    element: <RequireUser><Pages.Profile /></RequireUser>
  },
  {
    path: "/verification/:type",
    element: <RequireUser><Pages.Verification /></RequireUser>
  },
  {
    path: "/token-login",
    element: <Login />,
  },
  {
    path: "/tpk-signup",
    element: <Login form={'tpk-signup'} />,
  },
  {
    path: "/pestel-signup",
    element: <Login form={'pestel-signup'} />,
  },
  {
    path: "/onboarding-link-generate",
    element: <Pages.OnboardingLinkGenerator />,
  },
  {
    path: "/operator-login",
    element: <Login operator={true} />,
  },
  {
    path: "token/credentials/presentation",
    element: <Pages.TokenVerifiablePresentation />
	},
	{
    path: "/token/:mode/:id",
    element: <Pages.Token />
	},
  {
    path: "/token/search/auction/*",
    element: <Pages.Token /> 
  },
  {
    path: "/token/search/detail/*",
    element: <Pages.TokenSearch /> 
  },
  {
    path: "/token/search",
    element: <Pages.TokenSearch /> 
  },
	{
		path: "/token/transactions",
		element: <RequireUser><Pages.Transactions /></RequireUser>
	},
  {
    path: "/token/:id",
    element: <RequireUser><Pages.Token /></RequireUser>
  },
	{
		path: "/mark-proof/verification",
		element: <RequireOperator><Pages.ListVerification /></RequireOperator>
	},
	{
		path: "/mark-proof/verification/:token/:markproofPrefix?/:uuid?",
		element: <RequireOperator><Pages.MarkProofVerification /></RequireOperator>
	},
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy /> 
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions /> 
  },
  {
    path: "/faq",
    element: <FAQ />
  },
	{
		path: "/support",
		element: <Pages.Support />
	},
	{
		path: "/page",
		element: <Others />,
		children: [
			{
				path: "privacy-policy",
			},
			{
				path: "terms-conditions",
			},
			{
				path: "faq",
			},
		]
	},
  {
    path: "/standalone",
    element: <Standalone />,
    children: [
      {
        path: "certificate",
        element: <CertificateGenerator />
      },
      {
        path: "share-token",
        element: <ShareTokenSelector />
      },
      {
        path: "privacy-settings",
        element: <PrivacySettings />
      },
      {
        path: "token-details",
        element: <TokenDetailsWebApp />
      },
      {
        path: "edit-token",
        element: <EditTokenWebApp />
      },
      {
        path: "create-token",
        element: <CreateTokenWebApp />
      },
      {
        path: "transactions",
        element: <TransactionsWebApp />
      },
    ]
  },
  {
    path: "*",
    element: <PageNotFound />
  }
]