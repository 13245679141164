import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./DragDropUpload.scss";
import { t } from "i18next";

const DragDropUpload = (props) => {
	const { text, buttonText, onChange, disabled = false, multiple = true } = props;
	const [dragActive, setDragActive] = useState();
	// ref
	const inputRef = useRef(null);

	// triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };
	const onButtonClick = () => {
		if ( !!disabled ) return;
    inputRef.current.click();
  };

	const handleFile = (fileList) => {
		if ( !!disabled ) return;
		if ( !!fileList && fileList.length > 0 ) {
			let newFiles = [];
			for (let i = 0; i < fileList.length; i++) {
				newFiles.push(fileList[i]);
			}
			if (multiple === false) newFiles = [newFiles[newFiles.length - 1]]
			typeof onChange === 'function' && onChange(newFiles);
		}
	};

	// handle drag events
	const handleDrag = function (e) {
		if ( !!disabled ) return;
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e) {
		if ( !!disabled ) return;
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleFile(e.dataTransfer.files);
		}
	};

	useEffect(() => {
		inputRef.current.value = '';
	}, []);

	return (
		<div className={`upload--drag-drop ${!!disabled?'upload--disabled':''}`} onDragEnter={handleDrag}>
			<input 
				onChange={handleChange} 
				ref={inputRef} type="file" id="input-file-upload" 
				accept="image/webp, image/png, image/jpeg, image/gif, application/pdf, text/plain, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/zip, application/octet-stream, model/obj, model/mtl"
				multiple={multiple} 
			/>
			<label id="label-file-upload" htmlFor="input-file-upload">
				<Box className="align--center" p={2} px={3}>
					<Box py={2}>
						<IconButton disabled={disabled} onClick={onButtonClick} variant="contained" size="small" className="upload-button">
							<span className="icon icon--upload"></span>
						</IconButton>
					</Box>
					<p dangerouslySetInnerHTML={{__html: text || t("common:upload_dragdrop_text")}}></p>
					<Typography variant="caption" color="GrayText">{t("pages:token.document_details.note_3d_gallery")}</Typography>
				</Box>
			</label>
			{dragActive && (
				<div
					className="drag__file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</div>
	);
};

export default DragDropUpload;
