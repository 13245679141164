import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { connect, useDispatch } from "react-redux";
import { KeyringController } from '@metamask/eth-keyring-controller';
import { refreshToken } from '../../store/actions/authActions';
import { logout, manualLogin } from '../../store/slices/authSlice';
import { resetWallet } from '../../store/slices/walletSlice';
import ErrorBoundary from './ErrorBoundary';
import './Standalone.scss';
import { decryptSeedPhrase } from '../../service/crypto';
import { updateKeyringController } from '../../store/slices/cryptoSlice';
import { parseJwt } from '../../utils/helpers';
import WalletLoader from './WalletLoader';

export const standPost = (data: any) => {
  window.postMessage(JSON.stringify(data), '*');
}

interface IAuth {
  access_token: string,
  refreshToken: string,
  expires_in: number,
}

const Standalone = (props: any) => {
  const { initializeToken, refreshToken, isLoggedIn, store, address: cachedAddress, updateKeyringController } = props;
  const [params] = useSearchParams();

  const address = useMemo(() => {
    const accessToken = params.get('access_token')
    if (accessToken) {
      const jwt = parseJwt(accessToken);
      return jwt?.wallet_id;
    } else {
      return '';
    }
  }, [params]);
  const isSameUser = address === cachedAddress;

  const [password, setPassword] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [walletLoaded, setWalletLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    document.querySelector('body')?.setAttribute('id', 'standaloneWebApp');
    // disable webview scale
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'viewport');
    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    document.head.appendChild(meta);
  }, []);

  useEffect(() => {
    const auth = store.auth;
    const access_token = params.get('access_token') || auth?.accessToken;
    const refresh_token = params.get('refresh_token') || auth?.refreshToken;

    if (!refresh_token) {
      standPost({ action: 'error', data: 'Invalid token' })
      return;
    }

    initializeToken({
      access_token,
      refresh_token,
      expires_in: 36000,
    });

    refreshToken(); // this is to make sure token is new and not overwriting token of app
    setInitialized(true);

    return () => {
      dispatch(logout)
      dispatch(resetWallet)
    }
  }, []);

  const handlePostMessage = (message: any) => {
    try {
      if (message.data) {
        const data = JSON.parse(message.data);
        if (data.action === 'authCredentials') {
          setPassword(data.password);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onAuthenticate = (authenticated: boolean) => {
    standPost({ action: 'authenticated', data: authenticated })
  }

  useEffect(() => {
    if (isSameUser) {
      onAuthenticate(false)
    }
  }, [isSameUser, address, cachedAddress])

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    document.addEventListener("message", handlePostMessage);
  }, []);

  const restoreKeyrings = async (unlocked = true) => {
    if (!unlocked) {
      setWalletLoaded(true);
      onAuthenticate(false)
      return;
    }
    const walletState = store.wallet;
    const seedPhrase = walletState.private?.seedPhrase;

    if (!seedPhrase || !password || !address) return;

    setWalletLoaded(true);
    try {
      const mnemonic = decryptSeedPhrase(seedPhrase, password, address);

      if (mnemonic) {
        const keyringController = new KeyringController({});
        await keyringController.createNewVaultAndRestore(password, mnemonic);
        updateKeyringController(keyringController);
        onAuthenticate(true)

        return true;
      }
    } catch (error) {
      console.warn(error)
      onAuthenticate(false)
    }

    standPost({ action: 'error', data: 'Keyrings cannot be unlocked' })
    return false;
  };

  const handleError = (error: any, errorInfo: any) => {
    standPost({ action: 'error', data: errorInfo })
  }

  return (
    <ErrorBoundary onError={handleError}>
      {isLoggedIn && initialized && <WalletLoader address={address} unlockKeyrings={restoreKeyrings} />}
      {initialized && (isSameUser || walletLoaded) && <Outlet />}
    </ErrorBoundary>
  )
}

export default connect(
  (state: any) => {
    let auth = state.auth;
    let wallet = state.wallet;

    let address = wallet?.address;
    if (auth?.accessToken) {
      const jwt = parseJwt(auth.accessToken)
      address = jwt.wallet_id;
    }

    return {
      store: state,
      isLoggedIn:
        !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
          ? auth.accessToken
          : false,
      address,
    };
  },
  (dispatch: Function) => {
    return {
      initializeToken: (authentication: IAuth) => {
        dispatch(manualLogin({
          data: authentication,
          password: false,
        }))
      },
      refreshToken: () => {
        dispatch(refreshToken()).then((res: any) => {
          if (res.error) {
            standPost({ action: 'error', data: res.payload })
          }
        });
      },
      updateKeyringController: (keyringController: any) => {
        dispatch(
          updateKeyringController({
            controller: keyringController,
          })
        );
      },
    };
  }
)(Standalone);
