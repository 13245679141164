import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import * as CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import { BASE64_PREFIX, centimeterToMeter, gramToKilogram, inchToMeter, poundToKilogram, unitTypes } from "../configs/constant";
import Web3, { utils } from "web3";
import BigNumber from "bignumber.js";
import config from "../configs/config";
import { t } from "i18next"
import { sha512 } from 'js-sha512';
import store from "../store/rootReducer";

export const hexToNumber = (input) => {
	const hex = CryptoJS.enc.Hex.parse(input);
	return hex.toString();
};

export const base64 = (input) => {
	// PROCESS
	const encodedWord = Utf8.parse(input); // encodedWord Array object
	const encoded = Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
	return encoded;
};

export const queryParamsURLEncodedString = (params) => {
	return Object.keys(params)
		.map((k) =>
			Array.isArray(params[k])
				? params[k].map((p) => encodeURIComponent(k) + "=" + encodeURIComponent(p)).join("&")
				: params[k] instanceof Object
				? Object.keys(params[k])
						.map((pk) => `${k}[${pk}]=${encodeURIComponent(params[k][pk])}`)
						.join("&")
				: encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
		)
		.join("&");
};

export const hideEmail = (email) => {
	if (!email) return email;
	const length = email.length;
	const head = 3;
	const tail = 4;

	return [email.substr(0, head), "*".repeat(length - head - tail), email.substr(length - tail, length - 1)].join("");
};

export const formatDate = (date, time = false) => {
	const d = new Date(date);
	let month = "" + (d.getMonth() + 1);
	let day = "" + d.getDate();
	let year = d.getFullYear();
	let hour = d.getHours();
	let minute = d.getMinutes();
	let second = d.getSeconds();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	let dateString = [year, month, day].join("-");
	if (time) {
		if (hour < 10) hour = "0" + hour;
		if (minute < 10) minute = "0" + minute;
		if (second < 10) second = "0" + second;

		dateString += " " + [hour, minute, second].join(":");
	}

	return dateString;
};

const timeAgoString = (number, type) => {
	return t("common:time_ago", { time: `${number} ${t(`common:${type}`)}` });
}

export const timeSince = (date, time = false) => {
	let dateString = formatDate(date, time);

	var seconds = Math.floor((new Date() - date) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return dateString;
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return dateString;
	}
	interval = seconds / 86400;
	if (interval > 1) {
		if (interval <= 7) {
			return timeAgoString(Math.floor(interval), "days");
		}
		return dateString;
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return timeAgoString(Math.floor(interval), "hours");
	}
	interval = seconds / 60;
	if (interval > 1) {
		return timeAgoString(Math.floor(interval), "minutes");
	}
	return timeAgoString(Math.floor(interval), "seconds");
};

// Polyfill for _.merge
const isObject = (item) => {
	return item && typeof item === "object" && !Array.isArray(item);
};

export const merge = (target, ...sources) => {
	if (!sources.length) {
		return target;
	}
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				merge(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return merge(target, ...sources);
};

export const keccak_256 = (input) => {
	return CryptoJS.SHA3(input, { outputLength: 256 }).toString(CryptoJS.enc.Hex);
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const fileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const normalize = (text) => {
	if (text && text.startsWith('"') && text.endsWith('"')) {
		return text.slice(1, -1);
	}
	return text;
};

export const splitLines = (csvFile) => {
	return csvFile.replaceAll("\r", "").split("\n");
};

export const csvToArray = (strData, hasHeaders = true, strDelimiter = ",") => {
	// Properly escape the delimiter, if existent.
	// If no delimiter is given, use a comma
	strDelimiter = (strDelimiter || ",").replace(/([[^$.|?*+(){}])/g, "\\$1");

	//What are the quotation characters? "'
	var quotes = "\"'";

	// Create a regular expression to parse the CSV values.
	// match[1] = Contains the delimiter if the RegExp is not at the begin
	// match[2] = quote, if any
	// match[3] = string inside quotes, if match[2] exists
	// match[4] = non-quoted strings
	var objPattern = new RegExp(
		// Delimiter or marker of new row
		"(?:(" +
			strDelimiter +
			")|[\\n\\r]|^)" +
			// Quoted fields
			"(?:([" +
			quotes +
			"])((?:[^" +
			quotes +
			"]+|(?!\\2).|\\2\\2)*)\\2" +
			// Standard fields
			"|([^" +
			quotes +
			strDelimiter +
			"\\n\\r]*))",
		"gi"
	);

	// Create a matrix (2d array) to hold data, which will be returned.
	var arrData = [];

	// Execute the RegExp until no match is found
	var arrMatches;
	while ((arrMatches = objPattern.exec(strData))) {
		// If the first group of the RegExp does is empty, no delimiter is
		// matched. This only occurs at the beginning of a new row
		if (!arrMatches[1]) {
			// Add an empty row to our data array.
			arrData.push([]);
		}

		var quote = arrMatches[2];
		let strMatchedValue = arrMatches[4];
		if (quote) {
			// We found a quoted value. When we capture
			// this value, unescape any double quotes.
			strMatchedValue = arrMatches[3].replace(new RegExp(quote + quote, "g"), quote);
		}
		// Add the found value to the array
		arrData[arrData.length - 1].push(strMatchedValue);
	}
	// Return the parsed data.
	if (hasHeaders) {
		let headers = arrData[0];
		let lines = arrData.slice(1).map((item) => {
			let newItem = {};
			for (let i = 0; i < headers.length; i++) {
				newItem[headers[i]] = item[i];
			}
			return newItem;
		});
		return { lines: lines, headers: arrData[0] };
	}
	return { lines: arrData, headers: [] };
};

export const readWorkbook = async (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			resolve(XLSX.read(reader.result));
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer(file);
	});
};

export const delayedFetch = async (url, init) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetch(url, init));
		}, init.delay);
	});
};

export const readText = async (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
		reader.readAsText(file);
	});
};


export const dataURIToBlob = (dataURI) => {
	const splitDataURI = dataURI.split(',')
	const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
	const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

	const ia = new Uint8Array(byteString.length)
	for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i)

	return new Blob([ia], {
		type: mimeString
	});
}

export async function getImageAsBlob(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const blob = await response.blob();
	const file = new File([blob], "tpk_image.png", { type: blob.type });

  return file;
}

export const urlToDataUrl = (url, callback) => {
	var xhr = new XMLHttpRequest();
	// get image data url
	xhr.onload = function() {
			var reader = new FileReader();
			reader.onloadend = function() {
					callback(reader.result);
			}
			reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
	xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
	// pass cors enabled flag
	xhr.withCredentials = true;
	xhr.responseType = 'blob';
	xhr.send();
}

export const blobToBase64 = (blob) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const dataUrl = reader.result;
			const prefixLocation = dataUrl.indexOf(BASE64_PREFIX);
			if (prefixLocation === -1) {
				reject("File content was not converted to base64 string.");
			}
			resolve(dataUrl.slice(prefixLocation + BASE64_PREFIX.length));
		};
		reader.readAsDataURL(blob);
	});
};

export const isEmail = (email) => {
	const re = new RegExp(
		'^(([^<>\\(\\)[\\]\\.,;:\\s@\\"]+(\\.[^<>\\(\\)[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>\\(\\)[\\]\\.,;:\\s@\\"]{2,})$'
	);
	// const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	return re.test(email);
};

export const deep_value = (obj, path) => {
	let path_item = path.split(".");
	let len = path_item.length;
	for (let i = 0; i < len; i++) {
		obj = obj[path_item[i]];
	}
	return obj;
};

export const list_sorting = (property, sortOrder) => {
	return function (a, b) {
		let result;
		let aVal = deep_value(a, property);
		let bVal = deep_value(b, property);
		
		if (typeof aVal === "number") {
			result = aVal < bVal ? 1 : -1;
		} else {
			result = (aVal || "").localeCompare(bVal || "");
		}
		return result * sortOrder;
	};
};

export const getElementProperty = (el) => {
	const rect = el.getBoundingClientRect();
	const st = window.getComputedStyle(el, null);
	const translate = st.getPropertyValue('translate').split(' ');
	return {
    translateX: parseFloat(translate[0]),
    translateY: parseFloat(translate[1]),
    scale: parseFloat(st.getPropertyValue('scale')),
    rotation: parseFloat(st.getPropertyValue('rotate'))
	}
}

export const calculateNewTranslate = (delta, translate, scale = 1, rotation = 0) => {

  const translateX = translate.x; // Percentage value
  const translateY = translate.y; // Percentage value

  // Convert rotation to radians
  let rotationRad = rotation * Math.PI / 180;

  // Calculate the new translateX and translateY
  let newTranslateX = translateX + (delta.x / scale) * Math.cos(rotationRad) - (delta.y) * Math.sin(rotationRad);
  let newTranslateY = translateY + (delta.x / scale) * Math.sin(rotationRad) + (delta.y) * Math.cos(rotationRad);

  return {
    x: newTranslateX,
    y: newTranslateY
  };
}

export const rotatePointAroundOrigin = (x, y, cx, cy, angle) => {
  var radians = angle * (Math.PI / 180);
  var cosTheta = Math.cos(radians);
  var sinTheta = Math.sin(radians);

  var translatedX = x - cx;
  var translatedY = y - cy;

  var rotatedX = translatedX * cosTheta - translatedY * sinTheta;
  var rotatedY = translatedX * sinTheta + translatedY * cosTheta;

  var newX = rotatedX + cx;
  var newY = rotatedY + cy;

  return {
    x: newX,
    y: newY
  };
}

export const getRotatedDimensions = (boundingRect, rotation) =>  {

  var width = boundingRect.width;
  var height = boundingRect.height;

  if (rotation !== 0) {
    var rotationRad = rotation * Math.PI / 180;

    var cosTheta = Math.cos(rotationRad);
    var sinTheta = Math.sin(rotationRad);

    var newWidth = Math.abs(width * cosTheta) + Math.abs(height * sinTheta);
    var newHeight = Math.abs(width * sinTheta) + Math.abs(height * cosTheta);

    width = newWidth;
    height = newHeight;
  }

  return {
    width: width,
    height: height
  };
}

export const getScaleAndRotationFromTouch = (event) => {
  if (!event.touches || event.touches.length !== 2) {
    return null; // Invalid touch event with incorrect number of fingers
  }

  var touch1 = event.touches[0];
  var touch2 = event.touches[1];

  // Calculate the distance between the two touches
  var deltaX = touch2.clientX - touch1.clientX;
  var deltaY = touch2.clientY - touch1.clientY;
  var distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

  // Calculate the angle of rotation between the two touches
  var angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

  return {
    scale: distance,
    rotation: angle
  };
}

export const getTokenIdBignumber = (id) => {
	return utils.toBigInt(utils.sha3(id));
}

/**
 * Checks if the given string is an address
 *
 * @method isAddress
 * @param {String} address the given HEX adress
 * @return {Boolean}
*/
export const isAddress = function (address) {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        // check if it has the basic requirements of an address
        return false;
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
        // If it's all small caps or all all caps, return true
        return true;
    } else {
        // Otherwise check each case
        return isChecksumAddress(address);
    }
};

/**
 * Checks if the given string is a checksummed address
 *
 * @method isChecksumAddress
 * @param {String} address the given HEX adress
 * @return {Boolean}
*/
export const isChecksumAddress = function (address) {
    // Check each case
    address = address.replace('0x','');
    var addressHash = utils.sha3(address.toLowerCase());
    for (var i = 0; i < 40; i++ ) {
        // the nth letter should be uppercase if the nth digit of casemap is 1
        if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
            return false;
        }
    }
    return true;
};

export const shuffleArray = (arr) => {
	for (let i = arr.length - 1; i > 0; i--) {
		// Generate a random index from 0 to i (inclusive)
		const j = Math.floor(Math.random() * (i + 1));

		// Swap arr[i] and arr[j]
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
	return arr;
}	

export const getRandomIndices = (max, count) => {
  const indices = new Set();
  while (indices.size < count) {
    const randomIndex = Math.floor(Math.random() * (max + 1));
    indices.add(randomIndex);
  }
  return [...indices];
};

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// Convert from meter/kilogram into user selected unit
export const transferToUnitDisplay = (unit, value, isLength = true, addLabel = true) => {
	const currentUnit =  unitTypes[unit];
	value = value || 0.0;
	const label = ` ${isLength ? currentUnit.lengthUnit : currentUnit.weightUnit}`;

	const update =  currentUnit.key === unitTypes.METRIC.key
		? new BigNumber(value).dividedBy(isLength ? centimeterToMeter : gramToKilogram)
		: new BigNumber(value).dividedBy(isLength ? inchToMeter : poundToKilogram);

	const publicInfo = store.getState()?.wallet?.public;
	const locale = publicInfo?.language || 'en';

	let numStr = Number(update.toFormat(2)).toLocaleString(locale);
	if (addLabel) {
		return numStr + label;
	}
	return numStr;
};

export const transferUnitFromDisplayToSavedData = (unit, value, isLength = true) => {
	const currentUnit =  unitTypes[unit];
	value = value || 0.0;
	const update =  currentUnit.key === unitTypes.METRIC.key
		? new BigNumber(value).multipliedBy(isLength ? centimeterToMeter : gramToKilogram)
		: new BigNumber(value).multipliedBy(isLength ? inchToMeter : poundToKilogram);

	return update.toFormat(6);
}

export const areFilesEqual = (file1, file2) => {
	return file1.name === file2.name &&
	file1.size === file2.size &&
	(file1.type === file2.type
		|| file1.mimetype === file2.type
		|| file1.type === file2.mimetype);
}

export function getTokenBlockchainExplorerURL(tokenUUID) {
	const tokenBlockChainId = getTokenIdBignumber(tokenUUID);
	const safeSlash = config.BLOCK_EXPLORE_URL.endsWith('/') ? '' : '/';

	return `${config.BLOCK_EXPLORE_URL.replace('/tx', '')}${safeSlash}token/${config.NFT_CONTRACT_ADDRESS}/instance/${tokenBlockChainId}`;
}

export function getTransactionExploreURL(txnHash) {
	const safeSlash = config.BLOCK_EXPLORE_URL.endsWith('/') ? '' : '/';

	return `${config.BLOCK_EXPLORE_URL}${safeSlash}tx/${txnHash?.includes('0x') ? '' : '0x'}${txnHash}`;
}

export function computeFileHash(file) {
	return new Promise((resolve, reject) => {
		let hash = sha512.create();

		const reader = new FileReader();
		reader.onload = () => {
			const arrayBuffer = reader.result;

			let count = 0;
			while (count < arrayBuffer.byteLength) {
				const chunk = arrayBuffer.slice(count, count + 4096);
				hash.update(chunk);
				count += 4096;
			}
			resolve(hash.hex());
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer(file);
	});
}

export function validatePassword(input) {
	const value = `${input}`;

	const valid = [
		value.length >= 8,
		/[!@#$%^&*_.-]/.test(value),
		/[A-Z]/.test(value),
		/[\d]/.test(value),
	]

	return valid;
}