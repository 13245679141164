import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { 
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Card,
	CardHeader,
	IconButton,
	CardContent,
	useMediaQuery, 
	useTheme, 
	Button,
	CardActions,
	Typography
} from "@mui/material";
import { connect } from "react-redux";
import ProfileSettingItem from "./ProfileSettingItem";
import EditButton from "../../components/Button/EditButton";
import { LoadingButton } from "@mui/lab";
import { t } from "i18next";
import { toast } from "react-toastify";
import { resetWalletStatus } from "../../store/slices/walletSlice";

type ProfileSettingGroupHeaderType = {
	 element: any;
	 button: any;
	 title: string;
	 dialog: any;
	 toggleDialog: Function
}
const ProfileSettingGroupHeader = (props: ProfileSettingGroupHeaderType) => {
	const { element, button, title, dialog, toggleDialog } = props;

	return (
		<CardHeader
			action={
				!!element ? (element) :
					dialog && (
						<Button onClick={() => toggleDialog(true)} {...(!!button ? button.attrs : { variant: 'text' })}>
							{!!button && button.label ? button.label : <EditButton />}
						</Button>
					)
			}
			title={title}
		/>
	)
}
type SettingGroup = {
	title: string;
	data: any;
	element: JSX.Element;
	dialog?: any;
	button?: any;
	onSave?: Function;
	walletStatus: string;
	resetUpdatingStatus?: Function;
}

const ProfileSettingGroup = (props: SettingGroup) => {
	const { first, data, element, dialog, onSave, walletStatus, resetUpdatingStatus } = props;
	const [disabled, setDisabled] = useState(true);
	const [open, toggleDialog] = useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [updating, setUpdatingData] = useState(data);

	const handleClose = () => {
		toggleDialog(false);
	};

	const changeHandle = (_updateItem: any, index: number) => {
		updating[index] = _updateItem;
		setUpdatingData(updating);
		setDisabled(false);
	}
	
	const onSaveHandle = () => {
		if ( !!disabled ) return;
		typeof onSave === 'function' && onSave(updating);
		setDisabled(true);
	}

	const resetData = useCallback(() => {
		setUpdatingData(data);
	}, [data]);

	useEffect(() => {
		return () => {
			toggleDialog(false);
			resetData();
		}
	}, [])

	useEffect(() => {
		setUpdatingData(data);
	}, [data])

	useEffect(() => {
		if (walletStatus === 'success' && first) {
			toast.success(t('common:saved_changes_successfully'));
			setTimeout(() => resetUpdatingStatus && resetUpdatingStatus(), 500);
		}
	}, [walletStatus])

	useEffect(() => {
		if (walletStatus === 'success' && open && (dialog?.element || dialog?.modal)) {
			setTimeout(() => {
				toggleDialog(false);
				resetData();
			}, 2000);
		}
	}, [open, dialog, walletStatus])

	if (!!element) return (
		<Card className="profile__settings-group">
			<CardContent>
				{element}
			</CardContent>
		</Card>
	);

	return (
		<>
			<Card className="profile__settings-group">
				<CardContent>
					{!!data && !open && 
						<Box 
							className="settings"
							sx={{ mb: 1 }} 
						>
							{updating.map((item: any, idx: number) => (
								<ProfileSettingItem 
									key={`profile-item-${idx}`} 
									item={item} 
									onChange={(_updateItem: any) => changeHandle(_updateItem, idx)}

								/>
							))}
						</Box>
					}
					{!!dialog && open && !!dialog.element && 
						<Box sx={{ pr: 2, pl: 2 }}>
							{dialog.element}
						</Box>
					}
					{!!dialog && open && !!dialog.modal && (
						<Dialog className="profile-settings-dialog" open={open} onClose={handleClose}>
							<DialogTitle>
								<Box display="flex" flexDirection="row" justifyContent="space-between">
									<Typography variant="h5" fontWeight="500">{dialog.modal.title}</Typography>
									<IconButton className="confirm-dialog__close" aria-label="close" onClick={handleClose}>
										<span className="icon icon--close"></span>
									</IconButton>
								</Box>
							</DialogTitle>
							<DialogContent>
								{dialog.modal.body}
							</DialogContent>
						</Dialog>
					)}
				</CardContent>
				<CardActions>
					{!!dialog && !open &&  (
						<Box>
							{dialog.title && <Typography variant="h3" className="setting__dialog-title">{dialog.title}</Typography>}
							{dialog.button && (
								<Button
									variant="outlined"
									size="large"
									color="secondary"
									onClick={() => toggleDialog(true)}
								>
									{dialog.button}
								</Button>
							)}
						</Box>
					)}
					{!dialog && (
						<LoadingButton 
							color="secondary" 
							size="large" 
							disabled={disabled}
							variant="contained"
							onClick={onSaveHandle}
						>
							{t("common:save_changes")}
						</LoadingButton>
					)}
				</CardActions>
			</Card>
		</>
	);
};

export default connect((state: any) => {
	let data = state?.wallet;

	return {
		walletStatus: data.isUpdating,
	};
},
	(dispatch) => {
		return {
			resetUpdatingStatus: () => {
				dispatch(resetWalletStatus());
			},
		};
	}
)(ProfileSettingGroup);