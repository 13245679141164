import { Box, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Skeleton, Chip, Typography} from "@mui/material";
import ImageCompare from "./ImageCompare/ImageCompare";
import { useState } from "react";
import { useGetMarkproofsVerificationsQuery } from "../../service/api/meveoApi";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/helpers";
import { MARKPROOF_VERIFICATION_STATUS, MARKPROOF_VERIFICATION_STATUS_LABEL } from "../../configs/constant";

import "./ListMarkProofVerification.scss";
import EmptyView from "../Token/TokenList/EmptyView";
import { t } from "i18next";

const sampleData = require('./sample-data.json');

const Placeholder = ({ columns }) => {
	return (
		<>
			<Table className="markproof__verifications">
				<ListHeader columns={columns} />	
				<TableBody>
					{[1,2,3].map(i => (
					<TableRow key={`markproof__verification-${i}`}>
						<TableCell><Skeleton variant="text" width="100%" height="30px" /></TableCell>
						<TableCell><Skeleton variant="text" width="100%" height="30px" /> </TableCell>
						<TableCell><Skeleton variant="text" width="100%" height="30px" /> </TableCell>
						<TableCell><Skeleton variant="text" width="100%" height="30px" /> </TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</>
	)
}
const ListHeader = ({ columns, sortHandle }) => {
	const [sortDirection, toggleSortDirection] = useState({});
	const [sortProperty, setSortProperty] = useState("");

	const createSortHandler = (property) => (event) => {
		let dir = !!sortDirection[property] && sortDirection[property] === "asc" ? "desc" : "asc";
		setSortProperty(property);
		toggleSortDirection({
			...sortDirection,
			[property]: dir,
		});
		!!sortHandle && typeof sortHandle === "function" && sortHandle(property, dir);
	};

	return (
		<TableHead>
			<TableRow>
				{columns.map((item, idx) => (
					<TableCell key={`markproof-verification-header-${idx}`} sx={{ p: 0 }}>
						{!!item.order && (
							<TableSortLabel
								active={sortProperty === item.order}
								onClick={createSortHandler(item.order)}
								direction={
									!!sortDirection[item.order] && sortDirection[item.order] === "asc" ? "desc" : "asc"
								}
							>
								{item.label}
							</TableSortLabel>
						)}
						{!item.order && item.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const ListMarkProofVerification = (props) => {
	const navigate = useNavigate();
	const { isLoggedIn } = props;
	const [open, toggleDialog] = useState(false);
	const [editing, updateEditing] = useState(false);
	const { data, isFetching, isLoading, isError } = useGetMarkproofsVerificationsQuery({}, {
		skip: !isLoggedIn
	})

	const columns = [
		{
			label: `${t("common:digital_item")} UUID`,
			order: "name",
		},
		{
			label: `${t("pages:mark_proof.digital_fingerprint")} ${t("pages:mark_proof.prefix")}`,
			order: "markproofPrefix",
		},
		{
			label: `${t("common:date")}`,
			order: "creationDate",
		},
		{
			label: `${t("common:status")}`,
			order: "tokenPrivacy",
		}
	];

	const sortHandle = () => {

	}

	const posts_per_page = 20;
	let visibleData = !!data && data.status === 'success' && data.result ? data.result : [];

	const pages = Math.ceil(visibleData.length / 20)
	const [currentPageIdx, setPage] = useState(1);

	// Paging
	let start = (currentPageIdx - 1) * posts_per_page;
	visibleData = visibleData.slice(start, start + posts_per_page);

	visibleData = visibleData.filter(item => item.status ===  MARKPROOF_VERIFICATION_STATUS.ONGOING )
	
	return (
		<Box className="markproofs">
			<Typography sx={{ mb: 2 }} variant="body2" component="p">{t("pages:mark_proof.page_description")}</Typography>
			{!isFetching && visibleData.length <= 0 && <Box className="markproofs--empty flex flex--justify-center" sx={{ pt: 2, pb: 2 }}><span className="icon icon--empty-wallet"></span></Box> }

			{!!isFetching &&  <Placeholder columns={columns} />}
			{!isFetching && visibleData.length > 0 && <Table className="markproof__verifications">
				<ListHeader columns={columns} sortHandle={sortHandle} />	
				<TableBody>
					{visibleData.map((item, key) => (
						<TableRow onClick={() => {
							navigate(`/mark-proof/verification/${item.tokenId}/${item.markproofPrefix}/${item.uuid}`);
						}} className="markproof__verification" key={`markproof__verification-${key}`}>
							<TableCell>{item.tokenId}</TableCell>
							<TableCell>{item.markproofPrefix}</TableCell>
							<TableCell>{formatDate(item.creationDate, true)}</TableCell>
							<TableCell>

								<Chip
									className={`markproof__verification-status--${item.status.toLowerCase()}`}
									title={item.tokenId || ""}
									size="small"
									color="secondary"
									label={item.status}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>}

			{pages > 1 && (
				<Pagination
					sx={{ 
						mt: 2,
						display: 'flex',
						justifyContent: 'flex-end'
					}}
					count={pages}
					page={currentPageIdx}
					onChange={(event, value) => setPage(value)}
				/>
			)}
		</Box>
	)
}

export default connect(state => {
	let auth = state.auth;
	return {
		isLoggedIn: (!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()),
	};
})(ListMarkProofVerification);